import React from "react";
import htmlParser from "react-html-parser";
import { Container, Col } from "react-bootstrap";
import { connect } from "react-redux";
import uuid from "react-uuid";
import "./TreeInfoCard.css";
import ResponsiveAccordion from "../ResponsiveAccordion/ResponsiveAccordion";
import { treeInfoExists } from "../../utilities/treeInfoCard";

class GeneralInfo extends React.Component {
  renderMemo = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.generalInfo &&
        this.props.treeDetail.generalInfo.memo
      ) {
        return (
          <>
            <span
              className="tree-info-card-text"
              dangerouslySetInnerHTML={{
                __html: htmlParser(this.props.treeDetail.generalInfo.memo),
              }}
            />
            <br />
          </>
        );
      } else {
        throw new Error(`generalInfo missing`);
      }
    } catch (err) {
      console.log(`components.TreeInfoCard.GeneralInfo.renderMemo: ${err}`);
      return null;
    }
    // return htmlParser(this.props.treeDetail.generalInfo.memo);
  };

  renderNativeRange = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.generalInfo &&
        this.props.treeDetail.generalInfo.native_range
      ) {
        return (
          <span className="tree-info-card-text">
            Native range:{" "}
            <span>{this.props.treeDetail.generalInfo.native_range}</span>
          </span>
        );
      } else {
        throw new Error("native_range empty");
      }
    } catch (err) {
      console.log(
        `componetns.TreeInfoCard.GeneralInfo.renderNativeRange: ${err}`
      );
      return null;
    }
  };

  renderPotentialInvasiveness = () => {
    try {
      const pi = this.props.treeDetail.generalInfo.potentialInvasiveness;
      if (pi && (pi.pi_invasive || pi.ca_invasive)) {
        const { pi_invasive, ca_invasive, invasive_text } = pi;
        let response = null;
        if (pi_invasive && ca_invasive) {
          response = "Invasive in Hawaii and California";
        } else if (pi_invasive) {
          response = "Invasive in Hawaii";
        } else if (ca_invasive) {
          response = "Invasive in California";
        }
        return (
          <>
            {invasive_text ? (
              <span className="tree-info-card-text">
                Potential invasiveness:{" "}
                {invasive_text ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: htmlParser(invasive_text),
                    }}
                  />
                ) : (
                  response
                )}
              </span>
            ) : null}
          </>
        );
      } else {
        throw new Error("potentialInvasiveness empty");
      }
    } catch (err) {
      console.log(
        `componetns.TreeInfoCard.GeneralInfo.renderPotentialInvasiveness: ${err}`
      );
      return null;
    }
  };
  renderHorticulturalUse = () => {
    try {
      const la = this.props.treeDetail.generalInfo.landscapeApplication;
      const lu = this.props.treeDetail.generalInfo.landscapeUse;
      const combinedProps = la.concat(lu);
      if (combinedProps.length > 0) {
        return (
          <span className="tree-info-card-text">
            Horticultural use:{" "}
            {combinedProps.map((use, idx) => {
              return (
                <span key={uuid()}>
                  {use}
                  {idx < combinedProps.length - 1 ? " or " : null}
                </span>
              );
            })}
          </span>
        );
      } else {
        throw new Error(`horticultural values missing`);
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.GeneralInfo.renderHorticulturalUses: ${err}`
      );
      return null;
    }
  };

  renderEthnobotanicalInfo = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.generalInfo &&
        this.props.treeDetail.generalInfo.ethnobotanical_info
      ) {
        return (
          <>
            <span
              className="tree-info-card-text"
              dangerouslySetInnerHTML={{
                __html:
                  "Ethnobotanical Info / Tree Use: " +
                  htmlParser(
                    this.props.treeDetail.generalInfo.ethnobotanical_info
                  ) +
                  " <a href='https://ufei.calpoly.edu/references/'>Citations Page</a>",
              }}
            />
            <br />
          </>
        );
      } else {
        throw new Error(`ethnobotanical_info missing`);
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.GeneralInfo.renderEthnobotanicalInfo: ${err}`
      );
      return null;
    }
  };

  renderSchoolyardUseNotes = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.generalInfo &&
        this.props.treeDetail.generalInfo.schoolyardUseNotes &&
        this.props.treeDetail.siteConditions.schoolyard
      ) {
        return (
          <>
            <span
              className="tree-info-card-text"
              dangerouslySetInnerHTML={{
                __html:
                  "Schoolyard Use: " +
                  htmlParser(
                    this.props.treeDetail.generalInfo.schoolyardUseNotes
                  ),
              }}
            />
            <br />
          </>
        );
      } else {
        throw new Error(`schoolyard_use_notes missing`);
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.GeneralInfo.renderSchoolyardUseNotes: ${err}`
      );
      return null;
    }
  };

  checkRenderInfo = () => {
    try {
      return treeInfoExists(
        this.renderMemo(),
        this.renderNativeRange(),
        this.renderPotentialInvasiveness(),
        this.renderHorticulturalUse(),
        this.renderEthnobotanicalInfo(),
        this.renderSchoolyardUseNotes()
      );
    } catch (err) {
      console.log(
        `components.TreeInfoCard.GeneralInfo.checkRenderInfo: ${err}`
      );
      return false;
    }
  };

  renderContainer = () => {
    try {
      return (
        <Container className="tree-info-card--general-info">
          {this.renderMemo()}
          {this.renderNativeRange()}
          {this.renderPotentialInvasiveness()}
          {this.renderHorticulturalUse()}
          {this.renderEthnobotanicalInfo()}
          {this.renderSchoolyardUseNotes()}
        </Container>
      );
    } catch (err) {
      console.log(
        `components.TreeInfoCard.TreeCharacteristics.renderContainer: ${err}`
      );
    }
  };

  render() {
    return this.checkRenderInfo() ? (
      <Col lg={6} xs={12} className="mb-4">
        <ResponsiveAccordion title="GENERAL INFO">
          {this.renderContainer()}
        </ResponsiveAccordion>
      </Col>
    ) : null;
  }
}

const mapStateToProps = state => {
  return { treeDetail: state.treeDetail.data };
};
export default connect(mapStateToProps, {})(GeneralInfo);
