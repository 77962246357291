import React from "react";
import { useSelector } from "react-redux";

export default function RoleGate({ allowed_roles, children }) {
  const user = useSelector(state => state.user);
  if (user && allowed_roles.indexOf(user.role_id) >= 0) {
    return <span>{children}</span>;
  } else {
    return "";
  }
}
