import React from "react";
import { ImCheckboxChecked } from "react-icons/im";

import "./FormField.css";

function SingleCheckBox(props) {
  return (
    <div
      className="form-check form-check-inline"
      key={props.label}
      style={{ paddingBottom: "0.5rem", display: "flex", alignItems: "center" }}
    >
      <div style={{ display: "flex", position: "relative" }}>
        <input
          className="form-check-input"
          id={props.label}
          type="checkbox"
          onChange={props.onChange}
          defaultChecked={props.defaultChecked}
        />
        <span className="overlay">
          <ImCheckboxChecked className="icon" />
        </span>
      </div>
      <label htmlFor={props.label} className="form-check-label">
        {props.label}
      </label>
    </div>
  );
}

export default SingleCheckBox;
