import React from "react";
import "./QuickLinks.css";
import { Container, Row, Col } from "react-bootstrap";
import { FaSearch, FaList, FaDollarSign } from "react-icons/fa";
import { Link } from "react-router-dom";
import RegionSelector from "../RegionSelector/RegionSelector";
import { PAGE_TOP_DESKTOP } from "../../utilities/constants";

export function QuickLinkIcon({ title, Icon }) {
  return (
    <div className="quicklinks-link">
      <div
        href="#"
        className="btn-circle-md bg-primary text-secondary quicklinks-link-icon d-inline-block"
      >
        {Icon ? <Icon className="btn-icon" /> : ""}
      </div>
      <p className="quicklinks-link-text m-auto">
        <strong>{title}</strong>
        <br />
      </p>
    </div>
  );
}

function QuickLinks() {
  return (
    <div id="quicklinks" className="quicklinks-bg">
      <Container className="quicklinks-container">
        <div style={{ marginLeft: "5rem", marginRight: "4rem" }}>
          <Row className="justify-content-center">
            <Col className="mb-2">
              <RegionSelector where={"QuickLinks"} />
            </Col>
            <Col className="mb-2">
              <Link to={"/"} onClick={() => window.scrollTo(PAGE_TOP_DESKTOP)}>
                <QuickLinkIcon title="SEARCH CHARACTERISTICS" Icon={FaSearch} />
              </Link>
            </Col>
            <Col className="mb-2">
              <Link
                to={"/search-lists"}
                onClick={() => window.scrollTo(PAGE_TOP_DESKTOP)}
              >
                <QuickLinkIcon title="SEARCH TREE LISTS" Icon={FaList} />
              </Link>
            </Col>
            {
              <Col className="mb-2">
                <Link
                  to={"/appraisals"}
                  onClick={() => window.scrollTo(PAGE_TOP_DESKTOP)}
                >
                  <QuickLinkIcon title="APPRAISALS" Icon={FaDollarSign} />
                </Link>
              </Col>
            }
          </Row>
        </div>
      </Container>{" "}
    </div>
  );
}

export default QuickLinks;
