import React from "react";
import { Form } from "react-bootstrap";
import "./FormField.css";
var updateTimeout = null;

class FormFieldPlainText extends React.Component {
  constructor(props) {
    super(props);

    if (props.parentComponent.state) {
      let value = props.parentComponent.state[props.field];
      if (value === undefined || value === null) {
        console.log(
          `Components.FormField.FormFieldText- Cannot find field ${this.props.field} on the parent component.`
        );
      }
    } else {
      console.log(
        `Components.FormField.FormFieldText- Please pass in the parent component that has the state.`
      );
    }

    this.state = {
      value: null,
      saving: false,
      prevValue: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let newValue = props.parentComponent.state[props.field];
    if (newValue !== state.prevValue) {
      return { value: newValue, prevValue: state.value || newValue };
    } else {
      return {};
    }
  }

  change = e => {
    try {
      clearTimeout(updateTimeout);

      let newValue = e.target.value;
      this.setState({ value: newValue, saving: true });

      updateTimeout = setTimeout(c => {
        this.props.parentComponent.setState({
          [this.props.field]: this.state.value,
          changed: true,
        });
        this.setState({ saving: false });
      }, 10);
    } catch (err) {
      console.log(`Components.FormField.FormFieldText.change()-  ${err}`);
      return null;
    }
  };

  immediateSubmit = e => {
    let newValue = e.target.value;
    this.setState({ value: newValue });
    this.props.parentComponent.setState({
      [this.props.field]: this.state.value,
      changed: true,
    });
    this.setState({ saving: false });
  };

  render() {
    let defaultValue = this.state.value;

    let numberValidation = true;
    let maxValidation = true;

    if (this.props.validate) {
      if (this.props.validate.kind === "number") {
        numberValidation = defaultValue == Number.parseInt(defaultValue);
      }

      if (this.props.validate.max && numberValidation) {
        maxValidation = defaultValue <= this.props.validate.max;
      }
    }

    return (
      <Form.Group className="form-field-text">
        <Form.Label>{this.props.title}</Form.Label>
        <input
          className="form-field-plain-text"
          style={{ color: this.state.saving ? "grey" : "black" }}
          placeholder={this.props.placeholder}
          value={this.props.value || defaultValue || ""}
          onChange={this.change}
          onBlur={this.immediateSubmit}
          type={this.props.kind || "text"}
        />
        {!numberValidation ? "Should be an integer." : ""}
        {!maxValidation ? `Max value is ${this.props.validate?.max}` : ""}
      </Form.Group>
    );
  }
}

export default FormFieldPlainText;
