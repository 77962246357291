import React, { useState } from "react";
import { Button, Form, Nav } from "react-bootstrap";
import api from "../../apis/selectree_api";
import TextInput from "../FormField/TextInput.js";
import { toast } from "react-toastify";

function ForgotPassword(props) {
  const { handleForm } = props;

  const [email, updateEmail] = useState("");

  async function submitForm(e) {
    e.preventDefault();
    try {
      let res = await api.post(`/users/reset-password`, {
        email: email,
        url: window.location.origin,
      });

      e.stopPropagation();
      if (res) {
        toast.success(
          "If an account with that email exists the email was sent. Please check your inbox"
        );
      } else {
        toast.error("There was an issue sending the email.");
      }
    } catch (err) {
      console.log(
        `Error in client.components.login.ForgotPassword.submitForm: ${err}`
      );
    }
  }

  return (
    <div>
      <h2>Forgotten Password</h2>
      <p>
        Please enter your email. You will recieve a link to reset your password.
      </p>

      <Form onSubmit={submitForm}>
        <TextInput
          label={"Your Email"}
          required
          name="forgotten_password_email"
          value={email}
          onChange={(name, v) => updateEmail(v)}
          id="forgotten-password-email-form"
        />
        <Button type="submit">Send Email</Button>

        <Nav style={{ marginTop: "0.5rem" }} className="nav-margin">
          <Nav.Link
            className="nav-length"
            style={{ padding: "0.0rem" }}
            onClick={() => handleForm("toggleSide", "login")}
          >
            Back to Login
          </Nav.Link>
          <Nav.Link
            className="nav-length"
            style={{ padding: "0.0rem" }}
            onClick={() => handleForm("toggleSide", "signup")}
          >
            Need to create an account?
          </Nav.Link>
          <Nav.Link
            style={{ padding: "0.0rem" }}
            className="nav-length"
            onClick={() => handleForm("toggleSide", "verify")}
          >
            Need to verify an account?
          </Nav.Link>
        </Nav>
      </Form>
    </div>
  );
}

export default ForgotPassword;
