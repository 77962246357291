import React from "react";
import { connect } from "react-redux";
import { Container, Col } from "react-bootstrap";
import "./TreeInfoCard.css";
import ResponsiveAccordion from "../ResponsiveAccordion/ResponsiveAccordion";
import { treeInfoExists } from "../../utilities/treeInfoCard";
import CopyButton from "../CopyButton/CopyButton";

class CiteTree extends React.Component {
  renderCiteTree = () => {
    try {
      if (this.props.treeDetail && this.props.treeDetail.scientificName) {
        const scientificName = this.props.treeDetail.scientificName.replace(
          /(<([^>]+)>)/gi,
          ""
        );
        const date = new Date();
        const year = date.getFullYear();
        const formatDate = new Intl.DateTimeFormat("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }).format(date);
        const citation = `"SelecTree. UFEI. "${scientificName} Tree Record." 1995-${year}. Cal
        Poly State University, San Luis Obispo. Accessed on ${formatDate}."`;
        return (
          <span className="tree-info-card-text-cite">
            {citation}
            <br />
            {`< https://selectree.calpoly.edu/tree-detail/${this.props.treeDetail.treeId} >`}
            <CopyButton copy_text={citation} size={20} />
          </span>
        );
      } else {
        return new Error(`scientificName missing`);
      }
    } catch (err) {
      console.log(`components.TreeInfoCard.CiteTree.renderCiteTree: ${err}`);
      return null;
    }
  };

  checkRenderInfo = () => {
    try {
      return treeInfoExists(this.renderCiteTree());
    } catch (err) {
      console.log(`components.TreeInfoCard.CiteTree.checkRenderInfo: ${err}`);
      return false;
    }
  };

  renderContainer = () => {
    try {
      return (
        <Container className="tree-info-card--cite-tree">
          {this.renderCiteTree()}
        </Container>
      );
    } catch (err) {
      console.log(`components.TreeInfoCard.CiteTree.renderContainer: ${err}`);
    }
  };

  render() {
    return this.checkRenderInfo() ? (
      <Col lg={6} xs={12} className="mb-4">
        <ResponsiveAccordion title="CITE THIS TREE">
          {this.renderContainer()}
        </ResponsiveAccordion>
      </Col>
    ) : null;
  }
}

const mapStateToProps = state => {
  return { treeDetail: state.treeDetail.data };
};
export default connect(mapStateToProps, {})(CiteTree);
