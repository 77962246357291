export default (state = false, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload));
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("loggedIn", true);

      return action.payload;
    case "LOGOUT":
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("loggedIn");

      return false;
    default:
      if (!state) {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user) user.token = localStorage.getItem("token");
        return user;
      } else {
        return state;
      }
  }
};
