import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

import { AiFillInfoCircle } from "react-icons/ai";

export default function ListTypeToggles(props) {
  const {
    publicProps,
    commercialProps,
    viewableFromLinkProps,
    savedSearchProps,
    display,
  } = props;

  const tooltip = (
    <Tooltip id="tooltip">
      <p>
        If you turn on saved search, it will generate a list by the filters
        associated with your saved search.
      </p>
      <p>
        If you turn off saved search, it will generate a list where you can
        manually add or remove species from this list.
      </p>
    </Tooltip>
  );

  return (
    <>
      {publicProps ? (
        <Form.Check
          id={`public-checkbox-${props.id}`}
          label="Public"
          type="switch"
          {...publicProps}
        />
      ) : (
        ""
      )}
      {viewableFromLinkProps ? (
        <Form.Check
          id={`viewable-from-link-checkbox-${props.id}`}
          label="Viewable from link"
          type="switch"
          {...viewableFromLinkProps}
        />
      ) : (
        ""
      )}
      {/* Commented Out for production push
      {commercialProps ? (
        <Form.Check
          id={`inventory-checkbox-${props.id}`}
          label="For Sale"
          type="switch"
          {...commercialProps}
        />
      ) : (
        ""
      )}*/}
      {savedSearchProps ? (
        <>
          <Form.Check
            id={`saved-search-checkbox-${props.id}`}
            style={{ marginRight: ".2em" }}
            label="Saved Search"
            type="switch"
            {...savedSearchProps}
          />
          <OverlayTrigger placement="bottom" overlay={tooltip}>
            <AiFillInfoCircle
              size={15}
              style={{ cursor: "pointer", marginTop: "-0.2em" }}
            />
          </OverlayTrigger>
        </>
      ) : (
        ""
      )}
    </>
  );
}
