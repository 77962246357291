import React from "react";
import { connect } from "react-redux";
import { Container, Spinner } from "react-bootstrap";
import "./TreeDetailGallery.css";
import uuid from "react-uuid";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

class TreeDetailGallery extends React.Component {
  constructor(props) {
    super();
    this.state = {
      imagesReady: false,
      preloadedImages: 0,

      //State for the modal
      modalOpen: false,
      modalNumber: null,
      startTouchX: null,
      startTouchY: null,
      touchChange: 0,
    };
  }

  componentDidMount = () => {
    try {
      this.preloadImages();
      this.setKeyHandlers();
    } catch (err) {
      console.log(`components.TreeDetailGallery.componentDidMount: ${err}`);
    }
  };

  setKeyHandlers = () => {
    document.addEventListener("keydown", this.moveKey);
  };

  moveKey = e => {
    if (this.state.modalOpen) {
      if (e.key === "ArrowLeft") {
        this.imageUpdate(-1);
      }

      if (e.key === "ArrowRight") {
        this.imageUpdate(1);
      }

      if (e.key === "Escape") {
        this.setState({ modalOpen: false });
      }
    }
  };

  preloadImages = () => {
    try {
      this.setState({ imagesReady: false, preloadedImages: 0 });
      if (
        this.props.treeDetail &&
        this.props.treeDetail.images &&
        this.props.treeDetail.images.length
      ) {
        for (let e of this.props.treeDetail.images) {
          if (e.link !== "null") {
            let img = new Image();
            img.src = `${e.link}`;
            img.onload = () => {
              this.setState(prevState => ({
                preloadedImages: prevState.preloadedImages + 1,
              }));
              if (
                this.state.preloadedImages ===
                this.props.treeDetail.images.length
              ) {
                this.setState({ imagesReady: true });
              }
            };
            img.onerror = evt => {
              console.log(
                `image not loaded: ${evt.currentTarget.getAttribute("src")}`
              );
              this.props.treeDetail.images =
                this.props.treeDetail.images.filter(
                  i => i.photo_link_id !== e.photo_link_id
                );

              if (
                this.state.preloadedImages ===
                this.props.treeDetail.images.length
              ) {
                this.setState({ imagesReady: true });
              }
            };
          }
        }
      } else {
        console.log(`no treeDetails.images`);
      }
    } catch (err) {
      console.log(`components.TreeDetailGallery.preloadImages: ${err}`);
      return;
    }
  };

  //Opens or closes the modal. When opening, a "number" attribute is attached to the image, to store in the state.
  toggleModal = async e => {
    try {
      let num = e.target.getAttribute("data-number");
      let newState = { modalOpen: !this.state.modalOpen };
      if (num >= 0) {
        newState.modalNumber = Number.parseInt(num);
      }
      this.setState({ ...newState });
    } catch (err) {
      console.log(`components.TreeDetailGallery.toggleModal: ${err}`);
      return null;
    }
  };

  //This is the callback for the changing the modal image through an event listener.
  modalChange = e => {
    try {
      e.stopPropagation();
      let direction = Number.parseInt(
        e.target.getAttribute("data-direction") || "1"
      );
      this.imageUpdate(direction);
    } catch (err) {
      console.log(`components.TreeDetailGallery.modalChange: ${err}`);
      return null;
    }
  };

  //Takes in 'direction' as one of [-1, 0, 1] and updates the state to show the right image.
  imageUpdate = async direction => {
    try {
      let newModalNumber = this.state.modalNumber + direction;
      let totalNumber = this.props.treeDetail.images.length - 1;
      //Loop around if the newModalNumber is out of bounds.
      if (newModalNumber < 0) {
        newModalNumber = totalNumber;
      }
      if (newModalNumber > totalNumber) {
        newModalNumber = 0;
      }

      this.setState({ modalNumber: newModalNumber });
    } catch (err) {
      console.log(`components.TreeDetailGallery.imageUpdate: ${err}`);
      return null;
    }
  };

  /* 
    The next 3 functions all deal with mobile swiping.
    On start, the coordinates of the touch are stored in state.
    During the move, it tracks the difference, and if it hits the threshold, it changes the picture.
    On the end, it moves everything back to normal and resets the state.
    */
  startSwipeImage = e => {
    try {
      e.stopPropagation();
      var x = e.touches[0].clientX;
      var y = e.touches[0].clientY;
      this.setState({ startTouchX: x, startTouchY: y });
    } catch (err) {
      console.log(`components.TreeDetailGallery.startSwipeImage: ${err}`);
      return null;
    }
  };

  moveSwipeImage = async e => {
    try {
      //Calculate the difference in moving
      var x = e.touches[0].clientX;
      let dX = this.state.startTouchX - x;

      //Set the "left" value on the mainElement to simulate a drag
      let imageElement = e.target;
      let mainElement = imageElement.parentNode;
      mainElement.style.left = `${-dX}px`;

      //This value reference pixels, i.e. how far to swipe before it actually changes.
      if (Math.abs(dX) > 200) {
        //See which way the user is swiping (negative vs. positive means right vs. left)
        let direction = dX > 1 ? 1 : -1;
        //Move the element back to the middle before switching out the image.
        mainElement.style.left = `auto`;
        //This check is necessary- that it hasn't already changed. otherwise, it will keep changing (flipping through) as you hold the touch over to the side
        if (this.state.touchChange === 0) {
          //Kind of pedantic, but set the touchChange in state before calling the update.
          await this.setState({ touchChange: direction });
          this.imageUpdate(this.state.touchChange);
        }
      }
    } catch (err) {
      console.log(`components.TreeDetailGallery.moveSwipeImage: ${err}`);
      return null;
    }
  };

  endSwipeImage = e => {
    try {
      let imageElement = e.target;
      let mainElement = imageElement.parentNode;

      //Only set the touchChange back to 0 after releasing the touch.
      this.setState({ touchChange: 0, startTouchX: null, startTouchY: null });

      mainElement.style.left = `auto`;
    } catch (err) {
      console.log(`components.TreeDetailGallery.endSwipImage: ${err}`);
      return null;
    }
  };

  renderLoading = () => {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  };

  renderNoImages = () => {
    try {
      return (
        <div className="tree-detail-gallery-no-results">
          <h4>
            No images found for{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: this.props.treeDetail.scientificName,
              }}
            ></span>
          </h4>
        </div>
      );
    } catch (err) {
      console.log(`components.TreeDetailGallery.renderNoResults: ${err}`);
      return null;
    }
  };

  renderGalleryImage = (image, num) => {
    try {
      if (image.link !== null) {
        return (
          <img
            className="tree-detail-gallery-image"
            data-number={num}
            onClick={this.toggleModal}
            key={uuid()}
            // style={{
            //   backgroundImage: `url('${image.link.replace(/'/g, "\\'")}')`,
            // }}
            src={image.link}
            onError={this.errorLoadingImage}
          ></img>
        );
      } else {
        return <span></span>;
      }
    } catch (err) {
      console.log(`components.TreeDetailGallery.renderGalleryImage: ${err}`);
      return null;
    }
  };

  renderTreeDetailGallery = () => {
    try {
      return this.state.imagesReady ? (
        <>
          {this.props.treeDetail.images.map((image, num) => {
            return this.renderGalleryImage(image, num);
          })}
        </>
      ) : (
        <Container xs={{ span: 4, offset: 4 }} className="text-center">
          {!this.props.treeDetail.images.length
            ? this.renderNoImages()
            : this.renderLoading()}
        </Container>
      );
    } catch (err) {
      console.log(
        `components.TreeDetailGallery.renderTreeDetailGallery: ${err}`
      );
      return null;
    }
  };
  errorLoadingImage = e => {
    let img = e.target;
    img.onerror = null;
    img.src = "/images/broken-link.png";
  };

  renderTreeDetailModal = () => {
    try {
      if (this.state.modalOpen) {
        let treeImage = this.props.treeDetail.images[this.state.modalNumber];
        return (
          <div
            id="modal-background"
            onMouseDown={e => {
              if (e.target === e.currentTarget) {
                this.toggleModal();
              }
            }}
          >
            {/* Make sure to close the modal on a background click ^^ */}
            <div className="modal-main">
              {/* The main "close" button. */}
              <span
                className="modal-close btn btn-primary"
                onClick={this.toggleModal}
              >
                X
              </span>

              <span
                className="modal-button"
                onClick={this.modalChange}
                data-direction={-1}
              >
                <FaChevronLeft />
              </span>

              {/* Make sure the image exists & has loaded before trying to show it. */}
              {this.props.treeDetail.images[this.state.modalNumber] ? (
                <>
                  <img
                    onTouchStart={this.startSwipeImage}
                    onTouchMove={this.moveSwipeImage}
                    onTouchEnd={this.endSwipeImage}
                    src={treeImage.link}
                    alt=""
                    onError={this.errorLoadingImage}
                  />
                </>
              ) : (
                ""
              )}

              <span
                className="modal-button"
                onClick={this.modalChange}
                data-direction={1}
              >
                <FaChevronRight />
              </span>
              {/* <p><span dangerouslySetInnerHTML={{ __html: this.props.treeDetail.scientificName }}></span> {treeImage.attribute_name} by {treeImage.credit}</p> */}
            </div>
          </div>
        );
      }
    } catch (err) {
      console.log(`components.TreeDetailGallery.renderTreeDetailModal: ${err}`);
      return null;
    }
  };

  renderPhotoCredits = credit => {
    try {
      return <div dangerouslySetInnerHTML={{ __html: credit }} />;
    } catch (err) {
      console.log(`components.TreeDetailGallery.renderPhotoCredits: ${err}`);
      return null;
    }
  };

  render() {
    let photoCredits = [];
    this.props.treeDetail.images.forEach(image => {
      if (photoCredits.indexOf(image.credit) === -1) {
        photoCredits.push(image.credit);
      }
    });
    let photoLocations = this.props.treeDetail.generalInfo.photolocations;
    photoCredits = photoCredits.join(", ");

    let photoName = photoCredits.replace(/ and /g, ", ");
    let photographerName = photoName.split(", ");
    let authorName = photographerName
      .filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      })
      .toString()
      .replace(/,/g, ", ");

    return (
      <div className="tree-detail-gallery">
        {this.renderTreeDetailModal()}
        <div className="tree-detail-gallery-flex">
          {this.renderTreeDetailGallery()}
        </div>

        {(photoCredits || photoLocations) &&
        this.props.treeDetail.images.length > 0 ? (
          <>
            {photoLocations ? " Photo locations: " + photoLocations : ""}
            {authorName ? this.renderPhotoCredits(" by " + authorName) : ""}
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return { treeDetail: state.treeDetail.data };
};

export default connect(mapStateToProps, {})(TreeDetailGallery);
