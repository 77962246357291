//Docs for editor at: https://jpuri.github.io/react-draft-wysiwyg/#/

import { Form } from "react-bootstrap";
import "./FormField.css";

import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { Component } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import TextInput from "./TextInput";
import SingleCheckBox from "./SingleCheckBox";

let toolbar = {
  options: ["inline", "link"],
  inline: {
    options: ["bold", "italic", "underline"],
  },
};

class EditorConvertToHTML extends Component {
  constructor(props) {
    super(props);
    const html = props.parentComponent.state[props.field];
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      this.state = {
        editorState,
        changed: false,
        plain_text_toggle: false,
        plain_text: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        setInParentState: false,
      };
    }
  }

  UNSAFE_componentWillReceiveProps = props => {
    const html = props.parentComponent.state[props.field];
    if (
      html !==
      draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    ) {
      const contentBlock = htmlToDraft(
        html
          .replaceAll("&lt;", "<")
          .replaceAll("&gt;", ">")
          .replaceAll("&quot;", '"')
      );
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const stateWithContent = EditorState.createWithContent(contentState);

      this.setState({
        editorState: stateWithContent,
        plain_text: draftToHtml(
          convertToRaw(stateWithContent.getCurrentContent())
        ),
      });
    }
  };

  linkOccurences = string => {
    return string.split("<a href").length - 1;
  };

  setEditorFromPlain = html => {
    const contentBlock = htmlToDraft(
      html
        .replaceAll("&lt;", "<")
        .replaceAll("&gt;", ">")
        .replaceAll("&quot;", '"')
    );
    if (!contentBlock) return;

    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);

    this.setState({ ...this.state, editorState: editorState });
    let new_value = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    this.props.parentComponent.setState({
      [this.props.field]: new_value
        .replaceAll("<p>", "")
        .replaceAll("</p>", "")
        .replaceAll("<", "&lt;")
        .replaceAll(">", "&gt;")
        .replaceAll('"', "&quot;"),
      changed: true,
    });

    this.setState({
      changed: false,
      setInParentState: true,
    });
  };

  onEditorStateChange = async editorState => {
    let incomingHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    let stateHTML = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );

    this.setState({
      ...this.state,
      editorState,
      changed: true,
      setInParentState: false,
    });

    if (this.linkOccurences(incomingHTML) !== this.linkOccurences(stateHTML)) {
      this.saveText();
    }
  };

  saveText = () => {
    let field = this.props.field;
    let newValue = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    console.log(newValue);

    this.props.parentComponent.setState({
      [field]: newValue
        .replaceAll("<p>", "")
        .replaceAll("</p>", "")
        .replaceAll("<", "&lt;")
        .replaceAll(">", "&gt;")
        .replaceAll('"', "&quot;"),
      changed: true,
    });
    this.setState({
      changed: false,
      setInParentState: true,
    });
  };

  togglePlain() {
    this.setEditorFromPlain(this.state.plain_text);
    this.setState({
      ...this.state,
      plain_text_toggle: !this.state.plain_text_toggle,
    });
  }

  render() {
    let { editorState } = this.state;

    return (
      <Form.Group className="form-field-text">
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <Form.Label>{this.props.title}</Form.Label>

          <SingleCheckBox
            label="Display in Plain Text"
            onChange={() => this.togglePlain()}
          />
        </div>
        {!this.state.plain_text_toggle ? (
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            stripPastedStyles={true}
            handlePastedText={() => false}
            onEditorStateChange={this.onEditorStateChange}
            onBlur={this.saveText}
            toolbar={toolbar}
          />
        ) : (
          <TextInput
            value={this.state.plain_text}
            onChange={(name, v) =>
              this.setState({ ...this.state, plain_text: v })
            }
            onBlur={() => this.setEditorFromPlain(this.state.plain_text)}
            as="textarea"
            type="text"
            rows={10}
          />
        )}
      </Form.Group>
    );
  }
}

export default EditorConvertToHTML;
