import React, { useState } from "react";
import selectStyle from "../../customUI/react-select-options";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { FaSearch } from "react-icons/fa";

const createOption = label => ({
  label,
  value: label,
});

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FaSearch />
    </components.DropdownIndicator>
  );
};
// TODO (Logan) Clean this component up by extracting out the style override
// into list search since it is earch specfici
export default function MultiTextInput(props) {
  const { onEnter, defaultValue, ...inputProps } = props;

  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState(
    defaultValue && Array.isArray(defaultValue) ? defaultValue : []
  );

  const components = {
    DropdownIndicator: DropdownIndicator,
    IndicatorSeparator: null,
  };

  const handleKeyDown = event => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue(prev => {
          const new_val = [...prev, createOption(inputValue)];
          if (onEnter) onEnter(new_val);
          return new_val;
        });

        setInputValue("");
        event.preventDefault();

        break;
    }
  };

  return (
    <CreatableSelect
      {...inputProps}
      //defaultValue={defaultValue && Array.isArray(defaultValue)? defaultValue: []}
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      options={props.options}
      onChange={newValue => {
        if (onEnter) onEnter(newValue);
        setValue(newValue);
      }}
      menuIsOpen={inputValue && inputValue.length >= 2 ? true : false}
      onInputChange={newValue => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      placeholder="Type search terms..."
      value={value}
      styles={{
        ...selectStyle,
        control: (base, state) => ({
          ...base,
          "&:hover": { borderColor: "#457537" }, // border style on hover
          border: state.isFocused ? "1px solid #457537" : "1px solid lighgrey",
          borderRadius: "1.875rem",
          padding: "0 0.5rem",
          boxShadow: state.isFocused ? "1px #457537" : 0,
        }),
      }}
    />
  );
}
