import React from "react";
import { connect } from "react-redux";
import { Container, Col } from "react-bootstrap";
import uuid from "react-uuid";
import "./TreeInfoCard.css";
import ResponsiveAccordion from "../ResponsiveAccordion/ResponsiveAccordion";
import { treeInfoExists } from "../../utilities/treeInfoCard";
import { convertXArea } from "../../utilities/conversions";
class ExternalResources extends React.Component {
  renderExternalResourceList = () => {
    try {
      const externalResourceList = this.props.treeDetail.externalResourceList;
      if (externalResourceList) {
        const content = externalResourceList.map(resource => {
          return resource.host != "N/A" &&
            resource.host != null &&
            resource.href != "N/A" &&
            resource.href != null ? (
            <span className="tree-info-card-text">
              <a href={resource.host + resource.href} target="_blank">
                {resource.name}
              </a>
            </span>
          ) : null;
        });
        return <React.Fragment>{content}</React.Fragment>;
      } else {
        return null;
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.ExternalResources.renderExternalResourceList ${err}`
      );
      return null;
    }
  };

  checkRenderInfo = () => {
    try {
      if (
        Array.isArray(this.props.treeDetail.externalResourceList) &&
        this.props.treeDetail.externalResourceList.length > 0
      ) {
        let allNulls = true;
        for (let link of this.props.treeDetail.externalResourceList) {
          if (
            link.host != "N/A" &&
            link.host != null &&
            link.href != "N/A" &&
            link.href != null
          ) {
            allNulls = false;
            break;
          }
        }
        return !allNulls;
      } else {
        return false;
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.checkRenderInfo: ${err}`
      );
      return false;
    }
  };

  renderContainer = () => {
    try {
      return (
        <Container className="tree-info-card--considerations">
          {this.renderExternalResourceList()}
        </Container>
      );
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.renderContainer: ${err}`
      );
    }
  };

  render() {
    return this.checkRenderInfo() ? (
      <Col lg={6} xs={12} className="mb-4">
        <ResponsiveAccordion title="External Resources">
          {this.renderContainer()}
        </ResponsiveAccordion>
      </Col>
    ) : null;
  }
}

const mapStateToProps = state => {
  return { treeDetail: state.treeDetail.data };
};
export default connect(mapStateToProps, {})(ExternalResources);
