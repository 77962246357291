import React from "react";
import ResponsiveAccordion from "../ResponsiveAccordion/ResponsiveAccordion";
import { Row, Col } from "react-bootstrap";

import NameInfo from "../TreeFormCard/NameInfo";
import GeneralInfo from "../TreeFormCard/GeneralInfo";
import Photos from "../TreeFormCard/EditPhotos/Photos";
import SiteConditions from "../TreeFormCard/SiteConditions";
import TreeCharacteristics from "../TreeFormCard/TreeChar";
import Considerations from "../TreeFormCard/Considerations";

class TreeForm extends React.Component {
  constructor(props) {
    super();

    this.state = {};
  }

  render() {
    return (
      <div>
        <Row>
          <Col sm={12} className="mb-4">
            <ResponsiveAccordion title="Name Information">
              <NameInfo
                parentComponent={this.props.parentComponent}
                parentState={this.props.parentComponent.state}
              />
            </ResponsiveAccordion>
          </Col>

          <Col sm={12} className="mb-4">
            <ResponsiveAccordion title="General Information">
              <GeneralInfo
                parentComponent={this.props.parentComponent}
                parentState={this.props.parentComponent.state}
              />
            </ResponsiveAccordion>
          </Col>

          <Col sm={12} className="mb-4">
            <ResponsiveAccordion title="Photos">
              <Photos
                parentComponent={this.props.parentComponent}
                parentState={this.props.parentComponent.state}
              />
            </ResponsiveAccordion>
          </Col>

          <Col sm={12} className="mb-4">
            <ResponsiveAccordion title="Tree Characteristics">
              <TreeCharacteristics
                parentComponent={this.props.parentComponent}
                parentState={this.props.parentComponent.state}
              />
            </ResponsiveAccordion>
          </Col>

          <Col sm={12} className="mb-4">
            <ResponsiveAccordion title="Site Conditions">
              <SiteConditions
                parentComponent={this.props.parentComponent}
                parentState={this.props.parentComponent.state}
              />
            </ResponsiveAccordion>
          </Col>

          <Col sm={12} className="mb-4">
            <ResponsiveAccordion title="Considerations">
              <Considerations
                parentComponent={this.props.parentComponent}
                parentState={this.props.parentComponent.state}
              />
            </ResponsiveAccordion>
          </Col>
        </Row>
      </div>
    );
  }
}

export default TreeForm;
