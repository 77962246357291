import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import "./SelecTree.css";
// components
import FeaturedTree from "../FeaturedTree/FeaturedTree";
import FeaturedQuote from "../FeaturedQuote/FeaturedQuote";
import SearchByNameSection from "../SearchByNameSection/SearchByNameSection";
import SearchByChar from "../SearchByChar/SearchByChar";
import QuickLinks from "../QuickLinks/QuickLinks";
import { useDispatch } from "react-redux";
import { getUserListsThunk } from "../../actions/list";
import { getSearchChar } from "../../actions/searchChar";

function SelecTree() {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(getUserListsThunk());
      dispatch(getSearchChar());
    } catch (err) {
      //Manage Error
    }
  }, []);

  return (
    <>
      <div className="selectree d-flex flex-wrap">
        <hr className="d-lg-none selectree-mobile-hr" />

        <Container fluid>
          <SearchByChar />
        </Container>

        <Container>
          <hr />
        </Container>

        <Container fluid>
          <div className="d-lg-none">
            <QuickLinks />
          </div>
          <div className="selectree-top-part bg-light">
            <FeaturedTree />
            <FeaturedQuote />
          </div>
        </Container>
      </div>

      <div className="d-none d-lg-block">
        <Container>
          <hr />
        </Container>
        <SearchByNameSection />
      </div>
    </>
  );
}

export default SelecTree;
