import React from "react";
import TextInput from "../FormField/TextInput";

export default function ListDescription(props) {
  return (
    <TextInput
      label="Description"
      className="description-section"
      {...props}
      as="textarea"
      maxLength="1024"
      type="text"
      rows={3}
    />
  );
}
