import api from "../apis/selectree_api";
import { getAuthHeader } from "../utilities/header";
import qs from "query-string";
import { movebar } from "../components/LoadingBar/LoadingBar";

export const linkSearchByName = async searchString => {
  try {
    if (searchString) {
      const response = await api.get(
        `/tree/link-search-by-name?searchString=${searchString}`
      );
      return response;
    }
  } catch (err) {
    console.log(`actions.search.linkSearchByName: ${err}`);
  }
};

export const setSearchOptions = searchOptions => {
  try {
    if (searchOptions) {
      const { scroll_y, ...urlOptions } = searchOptions;
      window.location.hash = qs.stringify(urlOptions);
      return { type: "SET_SEARCH_OPTIONS", payload: searchOptions };
    }
  } catch (err) {
    console.log(`actions.search.setSearchOptions: ${err}`);
  }
};

export function getSearchResultsThunk(searchOptions) {
  const { filterData } = qs.parse(window.location.search);

  //filterData === undefined && searchOptions.searchTerm
  //Defaults to searching name want to make api more composable in future
  if (searchOptions.searchTerm) {
    return getSearchByNameThunk(searchOptions, searchOptions.searchTerm);
  } else {
    return getSearchCharThunk(searchOptions, filterData);
  }
}

export function getSearchCharThunk(searchOptions, filterData) {
  const { activePage, resultsPerPage, sort } = searchOptions;
  window.location.hash = qs.stringify({ activePage, resultsPerPage, sort });

  return async (dispatch, getState) => {
    try {
      movebar("search-loading-bar");

      const res = await api.get(
        `/tree/search-results?filterData=${filterData}&activePage=${activePage}&resultsPerPage=${resultsPerPage}&sort=${sort}`,
        getAuthHeader()
      );
      dispatch({
        type: "SET_SEARCH_RESULTS_AND_OPTIONS",
        payload: {
          searchResults: res.data,
          searchOptions: { ...searchOptions, searchTerm: "" },
        },
      });
    } catch (err) {
      console.log("actions.getSearchResultsThunk.searchResults");
    }
  };
}

export function getSearchByNameThunk(searchOptions, searchTerm) {
  let region = sessionStorage.ufeiRegion ? sessionStorage.ufeiRegion : "";
  const { activePage, resultsPerPage, sort } = searchOptions;
  window.location.hash = qs.stringify({
    activePage,
    resultsPerPage,
    sort,
    searchTerm,
  });

  searchOptions.searchTerm = searchTerm;

  return async (dispatch, getState) => {
    try {
      movebar("search-loading-bar");

      const res = await api.get(
        `/tree/search-by-name-multiresult?region=${region}&searchTerm=${searchTerm}&activePage=${activePage}&resultsPerPage=${resultsPerPage}&sort=${sort}`,
        getAuthHeader()
      );

      dispatch({
        type: "SET_SEARCH_RESULTS_AND_OPTIONS",
        payload: { searchResults: res.data, searchOptions },
      });
    } catch (err) {
      console.log("actions.getSearchResultsThunk.searchResults");
    }
  };
}

export function getSearchListThunk(searchOptions) {
  const params = qs.stringify(searchOptions);
  window.location.hash = params;

  return async (dispatch, getState) => {
    try {
      const res = await api.get(`/list/search-lists?${params}`);

      dispatch({
        type: "SET_LIST_SEARCH_RESULTS_AND_OPTIONS",
        payload: { searchResults: res.data, searchOptions },
      });
    } catch (err) {
      console.log("actions.getSearchResultsThunk.searchResults");
    }
  };
}
