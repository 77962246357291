import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
// react-select styling options
import reactSelectStyles from "../../customUI/react-select-options";
import "./FormField.css";

class HeaderField extends React.Component {
  constructor(props) {
    super(props);

    if (props.parentComponent.state) {
      let idx = props.parentComponent.state.col.indexOf(props.field);

      let value = props.parentComponent.state.col[idx];
      if (value === undefined || value === null) {
        console.log(
          `Components.HeaderField.HeaderField- Cannot find field ${this.props.field} on the parent component.`
        );
      }
    } else {
      console.log(
        `Components.HeaderField.HeaderField- Please pass in the parent component that has the state.`
      );
    }

    this.state = {};
  }

  clearOption = () => {
    this.props.parentComponent.setState({
      [this.props.field]: 0,
      changed: true,
    });
  };

  change = selected => {
    try {
      let newValue = selected.label;
      let field = this.props.field;
      let idx = this.props.parentComponent.state.col.indexOf(this.props.field);

      var newCol = this.props.parentComponent.state.col;
      newCol.splice(idx, 1, newValue);

      console.log("newCol", newCol);

      this.props.parentComponent.setState({
        [this.props.parentComponent.state.col]: newCol,
      });
      console.log("this.props.parentComponent", this.props.parentComponent);
    } catch (err) {
      console.log(`Components.HeaderField.HeaderField.change()-  ${err}`);
      return null;
    }
  };

  render() {
    var idx = this.props.parentComponent.state.col.indexOf(this.props.field);
    var defaultValue = this.props.parentComponent.state.col[idx];

    //console.log("idx",idx)
    //console.log("defaultValue",defaultValue)
    let hideClearButton =
      !defaultValue || defaultValue === 0 || defaultValue === "0";
    if (this.props.required) {
      hideClearButton = true;
    }

    if (this.props.options) {
      this.props.options.forEach(option => {
        if (defaultValue === option.label) {
          defaultValue = option;
        }
      });
    } else {
      console.log(
        `Components.HeaderField.HeaderField.render()- No options provided for ${this.props.field}`
      );
    }

    return (
      <Form.Group className="form-field-text">
        <Select
          defaultValue={defaultValue}
          options={this.props.options}
          styles={reactSelectStyles}
          onChange={this.change}
          value={defaultValue}
        />
      </Form.Group>
    );
  }
}

export default HeaderField;
