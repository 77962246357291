import React, { useState } from "react";
import "./Login.css";
import { Button, NavDropdown } from "react-bootstrap";
import api from "../../apis/selectree_api";
import { getAuthHeader } from "../../utilities/header";
import { FaUserCircle } from "react-icons/fa";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import ForgotPassword from "./ForgotPassword";
import { useHistory } from "react-router";
import AccountVerify from "./verifyForm";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import RoleGate from "../RoleGate/RoleGate";

function Login(props) {
  const { defaultModal } = props;
  const history = useHistory();

  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  const [modal, updateModal] = useState(defaultModal);
  const [state, updateState] = useState({
    modalOpen: false,
    toggleSide: true,
  });

  function handleForm(name, v) {
    updateState({ ...state, [name]: v });
  }

  async function addTree() {
    try {
      let response = await api.post(`/tree/add`, {}, getAuthHeader());
      history.push(`/tree-detail/${response.data}/edit`);
    } catch (err) {
      console.log(`Error in client.components.SelectreeNavbar.addTree: ${err}`);
    }
  }

  function ModalState(toggle_state) {
    switch (toggle_state) {
      case "signup":
        return <SignupForm form={state} handleForm={handleForm} />;
      case "password_recovery":
        return <ForgotPassword form={state} handleForm={handleForm} />;
      case "verify":
        return <AccountVerify form={state} handleForm={handleForm} />;
      default:
        return <LoginForm handleForm={handleForm} updateModal={updateModal} />;
    }
  }

  function UserDropdown() {
    const title = user ? (
      <FaUserCircle size="1.5em" />
    ) : (
      <Button className="btn btn-dark btn btn-primary">Login</Button>
    );

    const basicOptions = (
      <div>
        <NavDropdown.Item onClick={() => history.push("/account")}>
          MY ACCOUNT
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => history.push("/my-list-page")}>
          MY LISTS
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => dispatch({ type: "LOGOUT" })}>
          LOGOUT
        </NavDropdown.Item>
      </div>
    );

    const adminOptions = (
      <div>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={addTree}>+ ADD TREE</NavDropdown.Item>
        <NavDropdown.Item onClick={() => history.push("/download")}>
          DOWNLOAD DATA
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => history.push("/upload")}>
          UPLOAD DATA
        </NavDropdown.Item>
      </div>
    );

    return (
      <NavDropdown
        className="login-nav-menu"
        title={title}
        alignRight
        onClick={() => {
          if (!user) {
            updateModal(true);
          }
        }}
        drop={"end"}
        id={`login-menu${user ? "-user" : "-no_user"}`}
      >
        {user ? basicOptions : ""}
        <RoleGate allowed_roles={[2, 3]}>{adminOptions}</RoleGate>
      </NavDropdown>
    );
  }

  function Modal() {
    return modal ? (
      <div
        id="login-modal-background"
        onMouseDown={e => {
          if (e.target === e.currentTarget) {
            updateModal(!modal);
          }
        }}
      >
        <div className="login-modal-main">
          <div
            className="Icon"
            onClick={() => updateModal(false)}
            style={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              display: "flex",
            }}
          >
            <IoMdClose size="1.25rem" />
          </div>
          {ModalState(state.toggleSide)}
        </div>
      </div>
    ) : (
      ""
    );
  }

  return (
    <div>
      <UserDropdown />
      <Modal />
    </div>
  );
}

export default Login;
