import React, { useState } from "react";
import api from "../../apis/selectree_api";
import { getAuthHeader } from "../../utilities/header";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import {
  TREE_LIST,
  SAVED_SEARCH,
  INVENTORY,
  LIST_FOLLOWER,
} from "../../utilities/constants";
import "./TreeDetailListWidget.css";
import { useDispatch, useSelector } from "react-redux";
import { getUserListsThunk, removeTreeThunk } from "../../actions/list";
import { toast } from "react-toastify";

function ExistingLists(props) {
  const ACTION_ICON_SIZE = 25; // plus and minus icon size
  const [error, setError] = useState("");

  const toastAddSuccess = listName =>
    toast.success(`Successfully added to list: ${listName}`);
  const toastRemoveSuccess = listName =>
    toast.success(`Successfully removed from list: ${listName}`);

  const lists = useSelector(state => state.user_lists);
  const dispatch = useDispatch();

  const { treeId, setWidgetView } = props;

  const handleError = err => {
    if (err?.response?.data?.message) {
      setError(err.response.data.message);
    } else {
      setError("Unknown error.");
    }
  };

  const addToList = async (listId, treeIds, listName) => {
    try {
      await api.patch(
        "/list/add/trees",
        { listId: listId, treeIds: treeIds },
        getAuthHeader()
      );

      dispatch(getUserListsThunk());
      toastAddSuccess(listName);
    } catch (err) {
      handleError(err);
    }
  };

  const removeFromList = async (list, treeId, typeToDelete, listName) => {
    dispatch(removeTreeThunk(list.list_id, treeId)).then(res =>
      res.status < 300
        ? toast.success(`Successfully removed from ${listName}`)
        : toast.error(`Failed to remove from ${listName}`)
    );
  };

  return (
    <div className="my-lists">
      <div
        className="new-list-container"
        style={{ cursor: "pointer" }}
        onClick={() => setWidgetView("new")}
      >
        <p>Add To New List </p>
        <FaPlusCircle size={ACTION_ICON_SIZE} />
      </div>
      <div className="modal-scroll-div">
        {lists
          .filter(
            list => list.type !== SAVED_SEARCH && list.role !== LIST_FOLLOWER
          )
          .map((list, index) => (
            <div key={index} className="list-card-container">
              <h5 className="text-left">{list.name}</h5>
              <div className="list-card">
                <div className="left-container">
                  <img
                    src={
                      list.trees.length > 0
                        ? `${process.env.REACT_APP_BASE_IMAGE_URL}${list.trees[0].link}`
                        : "/images/default-tree.png"
                    }
                    alt="list thumbnail"
                    style={{ width: "4em", height: "4em" }}
                  />
                  <div className="details">
                    <p>{list.description}</p>
                  </div>
                </div>
                <div style={{ display: "flex", width: "fit-content" }}>
                  {list.trees.filter(t => t.tree_id === parseInt(treeId))
                    .length > 0 ? (
                    <FaMinusCircle
                      className="Icon"
                      size={ACTION_ICON_SIZE}
                      onClick={() =>
                        removeFromList(list, treeId, "tree_list", list.name)
                      }
                    >
                      Remove
                    </FaMinusCircle>
                  ) : (
                    <FaPlusCircle
                      className="Icon"
                      size={ACTION_ICON_SIZE}
                      onClick={() =>
                        addToList(list.list_id, [treeId], list.name)
                      }
                    >
                      Add
                    </FaPlusCircle>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ExistingLists;
