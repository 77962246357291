import { getAuthHeader } from "../utilities/header";
import api from "../apis/selectree_api";
import { SAVED_SEARCH, TREE_LIST } from "../utilities/constants";
const LOC_MSG = "actions.list";

export function getUserListsProfilePageThunk() {
  return async dispatch => {
    return await api.get("/list/user/lists/false", getAuthHeader()).then(
      response => {
        dispatch({ type: "SET_USER_LISTS", payload: response.data });
        return response;
      },
      error => error.response
    );
  };
}

export function addListCollaborator(list_id, email) {
  return async dispatch => {
    return await api
      .patch(`/list/addCollabToList`, { list_id, email }, getAuthHeader())
      .then(
        response => {
          dispatch({
            type: "UPDATE_LIST_DETAIL",
            payload: { list_id, collaborators: response.data },
          });
          dispatch({
            type: "UPDATE_ONE_USER_LIST",
            payload: { list_id, collaborators: response.data },
          });
          return response;
        },
        error => error.response
      );
  };
}

export function removeListCollaborator(list_id, user_id) {
  return async dispatch => {
    return api
      .delete(`/list/${list_id}/collab/${user_id}`, getAuthHeader())
      .then(
        response => {
          dispatch({
            type: "UPDATE_LIST_DETAIL",
            payload: { list_id, collaborators: response.data },
          });
          dispatch({
            type: "UPDATE_ONE_USER_LIST",
            payload: { list_id, collaborators: response.data },
          });
          return response;
        },
        error => error.response
      );
  };
}

export function getUserListsThunk() {
  return async dispatch => {
    return await api.get("/list/user/lists/true", getAuthHeader()).then(
      response => {
        dispatch({ type: "SET_USER_LISTS", payload: response.data });
        return response;
      },
      error => error.response
    );
  };
}

export function getListDetail(list_id) {
  return async dispatch => {
    return await api.get(`/list/${list_id}`, getAuthHeader()).then(
      response => {
        //Update List Detail
        dispatch({ type: "SET_LIST_DETAIL", payload: response.data });

        //Update user lists if list is in list detail
        dispatch({ type: "UPDATE_ONE_USER_LIST", payload: response.data });
        return response;
      },
      error => error.response
    );
  };
}

export function updateUserListsThunk(list_id) {
  return async dispatch => {
    return await api.get(`/list/current/${list_id}`, getAuthHeader()).then(
      response => {
        //Fetch Fresh Data for all user lists
        dispatch({ type: "UPDATE_USER_LISTS", payload: response.data });
        //If list_detail has a list in user lists then update with new data
        dispatch({ type: "SYNC_LIST_DETAIL", payload: [response.data] });
        return response;
      },
      error => error.response
    );
  };
}

export function addListThunk(list_id) {
  return async dispatch => {
    return await api.get(`/list/current/${list_id}`, getAuthHeader()).then(
      response => {
        dispatch({ type: "ADD_USER_LIST", payload: response.data });
        return response;
      },
      error => error.response
    );
  };
}

export function deleteListThunk({ list_id, type_to_delete }) {
  return dispatch => {
    return api
      .delete(`/list/remove/${list_id}/${type_to_delete}`, getAuthHeader())
      .then(
        response => {
          dispatch({ type: "DELETE_USER_LIST", payload: list_id });
          return response;
        },
        error => error.response
      );
  };
}

export function removeTreeThunk(list_id, tree_id) {
  return dispatch => {
    return api
      .patch(`/list/remove/${tree_id}`, { listId: list_id }, getAuthHeader())
      .then(
        response => {
          dispatch(updateUserListsThunk(list_id));
          return response;
        },
        error => error.response
      );
  };
}

export function addTreeThunk(tree_ids, list_id) {
  return async dispatch => {
    return await api
      .patch(
        `/list/add/trees`,
        { listId: list_id, treeIds: tree_ids },
        getAuthHeader()
      )
      .then(
        response => {
          dispatch(updateUserListsThunk(list_id));

          return response;
        },
        error => error.response
      );
  };
}

export function updateListThunk(list_id, attribute, value) {
  return dispatch => {
    const params = { listId: list_id, attribute, value };
    return api.patch("/list/attribute", params, getAuthHeader()).then(
      response => {
        dispatch(getUserListsThunk());
        return response;
      },
      error => error.response
    );
  };
}

export function followThunk(followStatus, list_id) {
  return dispatch => {
    return api
      .post(`/list/follow/${list_id}`, { followStatus }, getAuthHeader())
      .then(
        response => {
          dispatch(getUserListsThunk());
          return response;
        },
        error => error.response
      );
  };
}

export function listConvertThunk(tree_ids, list_id, type) {
  return async (dispatch, getState) => {
    try {
      if (type === SAVED_SEARCH)
        await api.patch(
          `/list/add/trees`,
          { listId: list_id, treeIds: tree_ids },
          getAuthHeader()
        );
      else
        await api.delete(`/list/remove/${list_id}/tree_list`, getAuthHeader());

      await api.patch(
        "/list/attribute",
        {
          listId: list_id,
          attribute: "type",
          value: type === SAVED_SEARCH ? TREE_LIST : SAVED_SEARCH,
        },
        getAuthHeader()
      );
      const res = await api.get("/list/user/lists", getAuthHeader());
      dispatch({ type: "SET_USER_LISTS", payload: res.data });
    } catch (err) {
      console.log(`${LOC_MSG}.listConvert: ${err}`);
    }
  };
}
