import React from "react";
import { connect } from "react-redux";
import { Container, Row } from "react-bootstrap";
import "./TreeInfo.css";
// components
import GeneralInfo from "../TreeInfoCard/GeneralInfo";
import SiteConditions from "../TreeInfoCard/SiteConditions";
import Considerations from "../TreeInfoCard/Considerations";
import TreeCharacteristics from "../TreeInfoCard/TreeCharacteristics";
import CiteTree from "../TreeInfoCard/CiteTree";
import ExternalResources from "../TreeInfoCard/ExternalResources";

class TreeInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    console.groupCollapsed(`Tree Id: ${this.props.treeDetail.treeId}`);
    return (
      <Container>
        <Row>
          <GeneralInfo />
          <TreeCharacteristics />
          <SiteConditions />
          <Considerations />
          <ExternalResources />
          <CiteTree />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { treeDetail: state.treeDetail.data };
};
export default connect(mapStateToProps, {})(TreeInfo);
