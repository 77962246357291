import React from "react";
import { ListGroup, Container, Row, Col, Button } from "react-bootstrap";
import { Thumbnail } from "../ListItems/Thumbnail";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import SearchByName from "../SearchByName/SearchByName";
import "../ListViewer/ListViewer.css";
import "./LoadingSkeleton.css";
import MenuInput from "../FormField/MenuInput";
import ActionDropdown from "../ActionDropdown/ActionDropdown";

function TreeListItemSkeleton() {
  return (
    <ListGroup.Item className={"d-flex left-align"}>
      <Thumbnail
        // src="images/default-tree.png"
        backgroundColor="rgba(0, 0, 0, 0.03)"
        width="5.5em"
        height="5.5em"
        fluid
        className="rounded tree_thumbnail"
      />
      <div
        className="d-block"
        style={{
          width: "60%",
        }}
      >
        <div
          style={{
            height: "30%",
            width: "60%",
            background: "rgba(0, 0, 0, 0.03)",
          }}
        ></div>

        <div className="empty-space-div"></div>

        <div
          style={{
            height: "30%",
            width: "40%",
            background: "rgba(0, 0, 0, 0.03)",
          }}
        ></div>
      </div>
      <div
        style={{
          marginLeft: "auto",
          display: "flex",
          marginRight: "0.7rem",
          color: "2D2D2D",
        }}
      >
        <FaTrashAlt
          className="Icon"
          style={{ alignSelf: "center" }}
          size={30}
        />
      </div>
    </ListGroup.Item>
  );
}

function ListViewerSkeleton() {
  return (
    <div>
      <span className="d-flex">
        <SearchByName placeholder="Search to add trees..." />
        <div style={{ width: "100%" }}>
          <MenuInput placeholder="Sort Trees..." />
        </div>
      </span>
      {/* <SearchByName placeholder="Search to Add Trees..." /> */}
      <ListGroup className="main-content">
        <div className="main-content-scroll-div">
          {[1, 2, 3, 4].map(n => (
            <TreeListItemSkeleton key={`list-skeleton-${n}`} />
          ))}
        </div>
      </ListGroup>
    </div>
  );
}

export function ListPaneSkeleton() {
  return (
    <Container className="mt-4 mb-4">
      <div style={{ height: "5rem" }}>
        <div
          style={{
            height: "35%",
            width: "75%",
            background: "rgba(0, 0, 0, 0.03)",
          }}
        />
        <div className="empty-space-div" />
        <div
          style={{
            width: "50%",
            height: "15%",
            background: "rgba(0, 0, 0, 0.03)",
          }}
        />
        <div className="empty-space-div" />
        <div
          style={{
            width: "50%",
            height: "15%",
            background: "rgba(0, 0, 0, 0.03)",
          }}
        />
        <div className="empty-space-div" />
        <div
          style={{
            width: "50%",
            height: "15%",
            background: "rgba(0, 0, 0, 0.03)",
          }}
        />
      </div>
      <br />
      <ListViewerSkeleton />
    </Container>
  );
}
