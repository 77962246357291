import React from "react";
import { Container } from "react-bootstrap";
import "./PacificIslandsBanner.css";
// subcomponents
import SearchByName from "../SearchByName/SearchByName";

class PacificIslandsBanner extends React.Component {
  render() {
    return (
      <Container
        className="pacific-island-selectree-banner-container selectree-banner-container"
        fluid
      >
        {/* styling is for nav bar doesnt cut off words */}
        <Container style={{ marginTop: "20px" }}>
          <h1 className="selectree-banner-container-h1">
            <span className="selectree-banner-title">
              Pacific Islands
              <br />
              SelecTree
            </span>
          </h1>
          <h3 className="selectree-banner-container-h3">
            A Tree Selection Guide
          </h3>

          <div className="d-none d-lg-block">
            <SearchByName />
          </div>
        </Container>
      </Container>
    );
  }
}

export default PacificIslandsBanner;
