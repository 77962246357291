import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ResultOps.css";
// components
import ResultCounter from "../ResultCounter/ResultCounter";
import ResultSort from "../ResultSort/ResultSort";
import SavedSearchPicker from "../SavedSearchPicker/SavedSearchPicker";
import ResultsPerPage from "../ResultsPerPage/ResultsPerPage";
import LoadingBar from "../LoadingBar/LoadingBar";
import CreateListModal from "../ListModals/CreateListModal";
import { useSelector } from "react-redux";

function ResultOps() {
  const user = useSelector(state => state.user);

  function UserOps() {
    if (!user)
      return (
        <Col lg={{ span: 6 }} className="d-inline equal-height-columns-2">
          <Link className="btn btn-light border-dark" to="/#search-by-char">
            Refine Filter
          </Link>
        </Col>
      );
    return (
      <>
        <Col lg={{ span: 4 }} className="d-inline equal-height-columns-2">
          <SavedSearchPicker style={{ width: "100%" }} />
        </Col>
        <Col lg={{ span: 2 }} className="d-inline equal-height-columns-2">
          <CreateListModal title="SAVE RESULT AS LIST">
            <Button className="btn btn-dark btn btn-primary">
              Save Result
            </Button>
          </CreateListModal>
        </Col>
      </>
    );
  }

  return (
    <Container className="result-ops pt-4 pb-2 pl-4 pr-4">
      <ResultCounter />
      <Row>
        <UserOps />
        <Col lg={{ span: 3 }} className="d-inline equal-height-columns-2">
          <ResultSort />
        </Col>
        <Col
          lg={{ span: 3 }}
          className="d-inline equal-height-columns-2 result-ops-pagination-container"
        >
          <ResultsPerPage />
        </Col>
      </Row>
      <LoadingBar id="search-loading-bar" />
    </Container>
  );
}

export default ResultOps;
