import React from "react";
import { ListGroup, Row, Col } from "react-bootstrap";
import { Thumbnail } from "../ListItems/Thumbnail";
import { FaTrashAlt } from "react-icons/fa";
import "../ListViewer/ListViewer.css";
import "../MyListPage/ProfilePage.css";
import "./LoadingSkeleton.css";

export default function ListItemSkeleton() {
  return (
    <ListGroup.Item className={"d-flex left-align"}>
      <div className="d-flex" style={{ width: "70%", height: "8em" }}>
        <div style={{ width: "100%" }}>
          <div
            style={{
              height: "30%",
              width: "100%",
              background: "rgba(0, 0, 0, 0.03)",
            }}
          />
          <div className="empty-space-div" />

          <Row>
            <Col style={{ maxWidth: "fit-content" }}>
              <Thumbnail
                // src="images/default-tree.png"
                backgroundColor="rgba(0, 0, 0, 0.03)"
                width="5.5em"
                height="5.5em"
                fluid
                className="rounded tree_thumbnail"
              />
            </Col>
            <Col>
              <div
                style={{
                  height: "30%",
                  width: "40%",
                  background: "rgba(0, 0, 0, 0.03)",
                }}
              />
              <div className="empty-space-div" />

              <div
                style={{
                  height: "30%",
                  width: "40%",
                  background: "rgba(0, 0, 0, 0.03)",
                }}
              />
              <div className="empty-space-div" />
              <div
                style={{
                  height: "30%",
                  width: "40%",
                  background: "rgba(0, 0, 0, 0.03)",
                }}
              />
              <div className="empty-space-div" />
            </Col>
          </Row>
        </div>
      </div>
      <div
        style={{
          marginLeft: "auto",
          display: "flex",
          marginRight: "0.7rem",
          color: "2D2D2D",
        }}
      >
        {/* 
        <FaTrashAlt
          className="Icon"
          style={{ alignSelf: "center" }}
          size={30}
        />
        */}
      </div>
    </ListGroup.Item>
  );
}
