export const categories = [
  //Disabled until Inventories and for sale features are good to go
  //{ value: "Commercial/Sale List", label: "Commercial/Sale List" },
  { value: "General List", label: "General List" },
  { value: "Non-profit List", label: "Non-profit List" },
  { value: "Official City List", label: "Official City List" },
  { value: "Personal List", label: "Personal List" },
  { value: "Planting List", label: "Planting List" },
  { value: "Project List", label: "Project List" },
  { value: "Research List", label: "Research List" },
  { value: "School List", label: "School list" },
];

const california = [
  { value: "All of California", label: "All of California" },
  { value: "Inland Empire", label: "Inland Empire" },
  { value: "Inland Valleys", label: "Inland Valleys" },
  { value: "Interior West", label: "Interior West" },
  { value: "Northern Coast", label: "Northern Coast" },
  { value: "Southern Coast", label: "Southern Coast" },
  { value: "Southwest Deserts", label: "Southwest Deserts" },
];

const pacific_islands = [
  { value: "Pacific Islands", label: "Pacific Islands" },
  { value: "State of Hawai'i", label: "State of Hawai'i" },
  { value: "Big Island", label: "Big Island" },
  { value: "Maui", label: "Maui" },
  { value: "Moloka’i", label: "Moloka’i" },
  { value: "Oahu", label: "Oahu" },
  { value: "Kauai", label: "Kauai" },
  { value: "Ni’ihau", label: "Ni’ihau" },
  { value: "Guam", label: "Guam" },
  { value: "Samoa", label: "Samoa" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Palau", label: "Palau" },
  { value: "Mariana Islands", label: "Mariana Islands" },
];

export const regions = [
  {
    label: "California",
    options: california,
  },
  {
    label: "Pacific Islands",
    options: pacific_islands,
  },
];

export let keywords = [];
