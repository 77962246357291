import React from "react";
import { Col, Button } from "react-bootstrap";
import uuid from "react-uuid";
import "./ResultsPerPage.css";
import { useDispatch, useSelector } from "react-redux";
import { getSearchResultsThunk } from "../../actions/search";
import { NUMBER_OF_RESULTS } from "../../utilities/constants";

const ERROR_PREFIX = "components.ResultsPerPage";

const ResultsPerPage = () => {
  const searchOptions = useSelector(state => state.searchOptions);
  const dispatch = useDispatch();

  const handlePerPageClick = e => {
    try {
      const getSearchResults = getSearchResultsThunk({
        ...searchOptions,
        scroll_y: window.pageYOffset,
        activePage: 1,
        resultsPerPage: parseInt(e.target.value),
      });
      dispatch(getSearchResults);
    } catch (err) {
      console.log(`${ERROR_PREFIX}.handlePerPageClick: ${err}`);
    }
  };

  const renderButtons = () => {
    try {
      return NUMBER_OF_RESULTS.map(page => {
        return (
          <Button
            key={uuid()}
            variant="primary"
            className="border border-dark mr-1 result-per-page-btn"
            active={page === searchOptions.resultsPerPage}
            value={`${page}`}
            onClick={handlePerPageClick}
          >
            {page}
          </Button>
        );
      });
    } catch (err) {
      console.log(`${ERROR_PREFIX}.renderButtons: ${err}`);
      return null;
    }
  };

  return (
    <Col className="result-per-page">
      <div className="result-per-page-buttons">{renderButtons()}</div>
      <p className="align-right">results per page</p>
    </Col>
  );
};

export default ResultsPerPage;
