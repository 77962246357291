import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { Form } from "react-bootstrap";
import reactSelectStyles from "../../customUI/react-select-options";

const components = {
  DropdownIndicator: null,
};

const createOption = label => ({
  label,
  value: label,
});

const FormFieldMultiSelectText = props => {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const handleNewInput = () => {
    if (props.inputValidationFunc) {
      if (!props.inputValidationFunc(inputValue)) {
        return;
      }
    }
    setValue(prev => [...prev, createOption(inputValue)]);
    setInputValue([]);
    updateParent([...value, createOption(inputValue)]);
  };
  const handleKeyDown = event => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        //if the input is not valid, then prevent the default action
        handleNewInput();
        event.preventDefault();
    }
  };
  const checkDisabled = () => {
    try {
      //if any of the lockDependentVariables is set, then the field is disabled
      let value = props.lockDependentVariables
        .map(variable => props.parentComponent.state[variable].length)
        .reduce((a, b) => {
          return a + b;
        }, 0);

      if (value) return true;
      return false;
    } catch (err) {
      console.log(
        `Components.FormField.FormFieldMultitext.checkDisabled()-  ${err}`
      );
      return false;
    }
  };

  useEffect(() => {
    if (props.lockDependentVariables) {
      let val = checkDisabled();
      if (val) {
        setDisabled(true);
        setValue([]);
      } else setDisabled(false);
    }
    //populate the field with the current value

    if (props.parentComponent.state[props.field]) {
      try {
        let newValue = props.parentComponent.state[props.field].map(s =>
          createOption(s)
        );
        setValue(newValue);
      } catch (err) {
        console.log(
          `Components.FormField.FormFieldMultitext.useEffect()-  ${err}`
        );
      }
    }
  }, [props]);

  const updateParent = newValue => {
    try {
      setDisabled(checkDisabled());
      setValue(newValue);
      if (!newValue) {
        newValue = [];
      }
      newValue = newValue.map(s => s.label);
      //call back to parent component
      if (props.reset) {
        props.parentComponent.setState({
          [props.field]: newValue,
          [props.reset]: [],
          changed: true,
        });
      } else {
        props.parentComponent.setState({
          [props.field]: newValue,
          changed: true,
        });
      }
    } catch (err) {}
  };
  const handleOnBlur = () => {
    if (inputValue.length > 0) handleNewInput();
  };

  return (
    <Form.Group className="form-field-multitext">
      <Form.Label>{props.title}</Form.Label>

      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        styles={reactSelectStyles}
        onChange={newValue => updateParent(newValue)}
        onInputChange={newValue => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        onBlur={handleOnBlur}
        placeholder={disabled ? "Locked" : props.placeholder}
        value={value}
        isDisabled={disabled}
        autoFocus={props.autoFocus}
        required={props.required}
      />
      <Form.Text>{props.description}</Form.Text>
    </Form.Group>
  );
};

export default FormFieldMultiSelectText;
