import React from "react";
import "./RegionSelector.css";
import { setRegion } from "../../actions/setFilters";
import { connect } from "react-redux";

class RegionSelector extends React.Component {
  componentDidMount() {
    try {
      const path = window.location.pathname;
      let region = "";
      if (sessionStorage.ufeiRegion) {
        region = sessionStorage.ufeiRegion;
      }
      if (path === "/pacificislands") {
        this.props.setRegion("pacific islands");
      } else if (!region) {
        this.props.setRegion("");
      } else {
        this.props.setRegion(region);
      }
    } catch (err) {
      console.log(`components.RegionSelector.componentDidMount: ${err}`);
    }
  }

  handleRegionSelectorClick = () => {
    const region = !this.props.region.length ? "pacific islands" : "";
    this.props.setRegion(region);
  };

  renderRegionButton = () => {
    try {
      const title = !this.props.region.length
        ? "SEARCH PACIFIC ISLANDS"
        : "SEARCH ALL REGIONS";
      const icon = !this.props.region.length
        ? "images/icons/palmtree.svg"
        : "images/icons/acorn.svg";

      return (
        /* WARNING: REMOVING THE CLASS OF REGION-SELECTOR-BUTTON ON OUTSIDE DIV WILL BREAK EVERYTHING */

        <div
          className="region-selector-button m-auto d-block"
          onClick={this.handleRegionSelectorClick}
        >
          <div className="quicklinks-link">
            <span
              href="#"
              className="btn-circle-md bg-primary text-secondary quicklinks-link-icon d-inline-block"
            >
              <img src={icon} className="btn-icon" alt="" />
            </span>

            <p className="quicklinks-link-text d-inline-block m-auto">
              <strong>{title}</strong>
              <br />
            </p>
          </div>
        </div>
      );
    } catch (err) {
      console.log(`components.RegionSelector.renderRegionButton: ${err}`);
      return null;
    }
  };
  //Handle if this feature if put in the navbar

  renderRegionSelector = () => {
    try {
      const title = !this.props.region.length
        ? "SEARCH PACIFIC ISLANDS"
        : "SEARCH ALL REGIONS";

      return (
        <div
          className="region-selector-button m-auto d-block"
          onClick={this.handleRegionSelectorClick}
        >
          <div>{title}</div>
        </div>
      );
    } catch (err) {
      console.log(`components.RegionSelector.renderRegionButton: ${err}`);
      return null;
    }
  };

  render() {
    //Load different components based on where the region selector is being used
    switch (this.props.where) {
      case "QuickLinks":
        return this.renderRegionButton();
      case "Nav":
        return this.renderRegionSelector();
      default:
        return null;
    }
  }
}

const mapStateToProps = state => {
  return { region: state.region };
};

export default connect(mapStateToProps, { setRegion })(RegionSelector);
