import ListDetail from "../components/ListDetail/ListDetail";

export default (state = {}, action) => {
  switch (action.type) {
    case "SET_LIST_DETAIL":
      return action.payload;
    case "UPDATE_LIST_DETAIL":
      return { ...state, ...action.payload };
    case "SYNC_LIST_DETAIL":
      if (!Array.isArray(action.payload) || !state || !state.list_id)
        return state;
      const user_list = action.payload.find(l => l.list_id === state.list_id);
      return user_list ? { ...state, ...user_list } : state;
    default:
      return state;
  }
};
