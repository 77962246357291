import React from "react";
import "./TreeCharacteristicsGlossary.css";
import { HashLink } from "react-router-hash-link";
import entryTerms from "./entries";

class SearchHelp extends React.Component {
  constructor(props) {
    super();
  }
  componentDidMount() {
    if (this.props.match.params.treeName) {
      let treeName = this.props.match.params.treeName;
      this.props.getTreeDetail(treeName);
    } else {
      console.log(`components.TreeDetail: no match`);
    }
  }

  render() {
    let entries = entryTerms.sort((a, b) => a.name.localeCompare(b.name));

    let letters = [];

    entries.map(entry =>
      letters.includes(entry.name[0]) ? null : letters.push(entry.name[0])
    );

    return (
      <>
        <div className="id-space" id="content"></div>
        <div className="tree-characteristic-glossary container">
          <h1>Tree Characteristics Glossary</h1>
          <div>
            <h3>
              {" "}
              {
                //Displays an array of applicable letters to hop down the page
                letters.map(letter => (
                  <HashLink to={`/tree-characteristics-glossary#${letter}`}>
                    {letter}{" "}
                  </HashLink>
                ))
              }
            </h3>
          </div>

          <div>
            {entries.map((entry, i) => {
              let startLetter = entry.name[0] !== entries[i - 1]?.name[0];
              let endLetter = entry.name[0] !== entries[i + 1]?.name[0];
              let entryNameSnakeCase = entry.name
                .replaceAll(" ", "_")
                .toLowerCase();
              return (
                <div className="tree-characteristic-glossary-entry">
                  {startLetter ? (
                    <div id={entry.name[0]} className="id-space">
                      &nbsp;
                    </div>
                  ) : (
                    ""
                  )}
                  <div id={entryNameSnakeCase} className="id-space">
                    &nbsp;
                  </div>
                  <h3>{entry.name}</h3>
                  <div className="tree-characteristic-glossary-text">
                    {entry.text}
                  </div>
                  {endLetter ? (
                    <HashLink to={`/tree-characteristics-glossary#content`}>
                      Back to Top
                    </HashLink>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>

          <br />
          <br />
          <h2>References</h2>

          <h3>PG&amp;E. 1994.</h3>
          <p>
            A Shade Tree User Manual. TreeFinder, A Tree Selection Guide.
            <br /> Pacific Gas and ElecTric Company.
          </p>

          <h3>
            Gilman, E.F., H.W. Beck, D.G. Watson, P.Fowler, and N.R.
            Morgan.1993.
          </h3>
          <p>
            Southern trees. An expert system for selecting trees. (DOS)
            University of Florida and USDA Forest Service Southern Region.
          </p>

          <h3>
            Michael T. Benjamin, Mark Sudol, Laura Bloch and Arthur M. Winer
          </h3>
          <p>
            Low-Emitting Urban Forests: A Taxonomic Methodology for Assigning
            Isoprene and Monoterpene Emission Rates.{" "}
            <em>Atmospheric Environment.</em> Vol.30, No.9, pp.1437-1452, 1996.
          </p>

          <h3>UC Forest Products Laboratory</h3>
          <p>
            <em>Vegetation Guide for Landscaping in High Fire Risk Areas</em>,
            1997.
          </p>
        </div>
      </>
    );
  }
}

export default SearchHelp;
