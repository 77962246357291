import React, { useEffect, useState } from "react";
import { Col, Spinner } from "react-bootstrap";
import "./ResultCounter.css";

import { useSelector } from "react-redux";

function ResultCounter() {
  let count = useSelector(state => state.searchResults?.totalResults);

  return (
    <Col>
      <h3 className="result-counter-counter">
        {count === undefined ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden" style={{ display: "none" }}>
              Loading...
            </span>
          </Spinner>
        ) : (
          <span style={{ textAlign: "left" }} className="count">
            <a>{count && count > 0 ? count : 0}</a>
          </span>
        )}{" "}
        TREES
      </h3>
    </Col>
  );
}

export default ResultCounter;
