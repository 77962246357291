import React from "react";
import { Form } from "react-bootstrap";
import {
  ImCheckboxChecked,
  ImArrowUp,
  ImArrowDown,
  ImStarFull,
} from "react-icons/im";
import "./FormField.css";
import { FaTrashAlt } from "react-icons/fa";

const arrayEquality = (a1, a2) => {
  var i = a1.length;
  while (i--) {
    if (a1[i] !== a2[i]) return false;
  }
  return true;
};

var updateTimeout = null;

class FormFieldMultiPrimaries extends React.Component {
  constructor(props) {
    super(props);

    // PrimaryText takes in the field, like other FormField components, but there also must be
    // an array that follows the naming convention 'other_{field}' that are the "secondary" values
    if (props.parentComponent.state) {
      let value = props.parentComponent.state[props.field];
      if (value === undefined || value === null) {
        console.log(
          `Components.FormField.FormFieldMultiPrimaries- Cannot find field ${this.props.field} on the parent component.`
        );
      }

      let secondaryValue =
        props.parentComponent.state[`other_${this.props.field}`];

      if (secondaryValue === undefined || secondaryValue === null) {
        console.log(
          `Components.FormField.FormFieldMultiPrimaries- Cannot find secondary field other_${this.props.field} on the parent component.`
        );
      }

      if (!Array.isArray(secondaryValue)) {
        console.log(
          `Components.FormField.FormFieldMultiPrimaries- Cannot find secondary field other_${this.props.field} on the parent component.`
        );
      }
    } else {
      console.log(
        `Components.FormField.FormFieldMultiPrimaries- Please pass in the parent component that has the state.`
      );
    }

    this.state = {
      secondaryValue: [],
      saving: false,
      prevValue: null,
      prevSecondaryValue: [],
    };
  }
  //gets parent state into this state
  static getDerivedStateFromProps(props, state) {
    let newSecondaryValue = props.parentComponent.state[`other_${props.field}`];
    let newValue = newSecondaryValue[0];

    let secondaryEqual =
      newSecondaryValue.length === state.prevSecondaryValue.length &&
      arrayEquality(newSecondaryValue, state.prevSecondaryValue);

    if (newValue !== state.prevValue || !secondaryEqual) {
      return {
        value: newValue,
        prevValue: state.value,
        secondaryValue: newSecondaryValue,
        prevSecondaryValue: state.secondaryValue,
      };
    } else {
      return {};
    }
  }

  updateParentState = () => {
    let mainField = this.props.field;
    let secondaryField = `other_${this.props.field}`;

    this.props.parentComponent.setState({
      [mainField]: this.state.secondaryValue[0],
      [secondaryField]: this.state.secondaryValue,
      changed: true,
    });
  };

  change = (e, index) => {
    try {
      clearTimeout(updateTimeout);

      let newValue = e.target.value;

      let secondaryValues = this.state.secondaryValue;

      //Always set if it is the main value
      if (index === 0) {
        secondaryValues[0] = newValue;
        this.setState({ secondaryValue: secondaryValues, saving: 0 });
      }
      //Slightly differing setStates based on if the main value or secondary value is being edited
      //Removing Dup blocking because logic can't be easily done
      else {
        secondaryValues[index] = newValue;
        this.setState({ secondaryValue: secondaryValues, saving: index });
      }

      updateTimeout = setTimeout(() => {
        this.setState({ saving: false }, this.updateParentState);
      }, 500);
    } catch (err) {
      console.log(
        `Components.FormField.FormFieldMultiPrimaries.change()-  ${err}`
      );
      return null;
    }
  };

  changePrimary = value => {
    if (value !== this.state.secondaryValue[0]) {
      //Change the main value to the incoming value.
      this.updateParentState();
    }
  };

  addNew = () => {
    let secondaryValues = this.state.secondaryValue;

    //See how many blank entries there are.
    let blankEntries = secondaryValues.filter(x => x === "");

    //If there are 0 blanks, add in a new one.
    if (blankEntries.length === 0) {
      secondaryValues.push("");
      this.setState(
        { secondaryValue: secondaryValues },
        this.updateParentState
      );
    }
  };

  deleteEntry = e => {
    let value = e.currentTarget.getAttribute("data-value");

    //If they are deleteing the main value,
    if (value === this.state.secondaryValue[0]) {
      let secondaryValues = this.state.secondaryValue;

      //And there is a secondary value to take it's place,
      if (secondaryValues.length > 0) {
        //Splice the first secondary value from it's array and make it the new main value
        secondaryValues.splice(0, 1);
        this.changePrimary(secondaryValues[0]);

        this.setState(
          { secondaryValue: secondaryValues },
          this.updateParentState
        );
      }
    }
    //Otherwise, just delete the value by filtering it out.
    else {
      let secondaryValues = this.state.secondaryValue;
      secondaryValues = secondaryValues.filter(x => x !== value);

      this.setState(
        { secondaryValue: secondaryValues },
        this.updateParentState
      );
    }
  };
  //reorder values
  reorder = e => {
    let name = e.currentTarget.getAttribute("name");
    let direction = e.currentTarget.getAttribute("direction");

    let index = this.state.secondaryValue.indexOf(name);
    let secondaryValues = this.state.secondaryValue;

    if (direction === "up") {
      //do down stuff
      if (index === 0) {
        return;
      }
      let removed = secondaryValues.splice(index - 1, 1, name);
      secondaryValues.splice(index, 1, removed[0]);
    } else if (direction === "down") {
      //do up stuff
      if (index === secondaryValues.length - 1) {
        return;
      }
      let removed = secondaryValues.splice(index + 1, 1, name);
      secondaryValues.splice(index, 1, removed[0]);
    }
    //check if index is 0 if so make it primary
    this.changePrimary(secondaryValues[0]);
    this.setState({ secondaryValue: secondaryValues }, this.updateParentState);
  };

  renderOption = (value, i) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex", alignItems: "center" }} key={i}>
          {/* display number if not primary */}
          {i > 0 && <div style={{ width: "30px" }}>{i}.</div>}
          <input
            className="form-field-plain-text"
            value={value}
            onChange={e => this.change(e, i)}
            data-old-value={value}
            style={{ color: this.state.saving === i ? "grey" : "black" }}
          />
          &nbsp;
          <div className="form-check form-check-inline">
            <span className="overlay1">
              <ImArrowUp
                className="icon"
                onClick={this.reorder}
                direction="up"
                name={value}
              />
            </span>
            <span className="overlay1">
              <ImArrowDown
                className="icon"
                onClick={this.reorder}
                direction="down"
                name={value}
              />
            </span>
          </div>
          &nbsp;
          {
            //Only show the delete option if there's any secondary values. DO NOT allow delete on the last value.
            this.state.secondaryValue?.length > 0 ? (
              <span
                onClick={this.deleteEntry}
                data-value={value}
                style={{ cursor: "pointer" }}
              >
                <FaTrashAlt />
              </span>
            ) : (
              ""
            )
          }
        </div>
      </React.Fragment>
    );
  };

  render() {
    let primaryValue = this.state.secondaryValue[0];
    let otherValues = this.state.secondaryValue;
    // let otherValues = this.props.parentComponent.state.common_order;
    //update parent state with new order

    return (
      <Form.Group className="form-field-text">
        <Form.Label>{this.props.title}</Form.Label>

        {/* Render the primary value */}
        {this.renderOption(primaryValue, 0)}
        <Form.Label>
          {this.props.subtitle}
          <div style={{ fontSize: 10 }}>
            (top four alt names will be displayed)
          </div>
        </Form.Label>

        {/* Then the secondary values */}
        {/* i plus one for the true index */}
        {otherValues
          .slice(1)
          .map((otherValue, i) => this.renderOption(otherValue, i + 1))}

        {/* Then the add button */}
        <span
          onClick={this.addNew}
          className="form-button-primary"
          style={{ marginTop: ".5rem" }}
        >
          + Add New
        </span>
      </Form.Group>
    );
  }
}

export default FormFieldMultiPrimaries;
