import React from "react";
import "./NotFound.css";
import "../FeaturedQuote/FeaturedQuote.css";
import { Link } from "react-router-dom";

class NotFound extends React.Component {
  render() {
    return (
      <div className="container not-found-container">
        <h1>We're Sorry!</h1>
        <h2>The page you're looking for was not found- Error 404</h2>
        <br />
        <p>
          <button
            className="btn btn-default btn-lg"
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </button>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-primary btn-lg" to="/">
            SelecTree Home
          </Link>
        </p>
        <br />

        <h4>
          "It is a surprising and memorable, as well as valuable experience, to
          be lost in the woods any time."
        </h4>
        <p className="text-muted">-Henry David Thoreau</p>
      </div>
    );
  }
}

export default NotFound;
