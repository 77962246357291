export default (state = [], action) => {
  switch (action.type) {
    case "SEARCH_BY_NAME":
      return action.payload;
    case "SEARCH_BY_FAMILY_GENUS":
      return action.payload;
    default:
      return state;
  }
};
