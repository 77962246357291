import React from "react";
import { Form } from "react-bootstrap";

import { FaPencilAlt } from "react-icons/fa";

import { getAuthHeader } from "../../../utilities/header";
import api from "../../../apis/selectree_api";

import FormFieldPlainText from "../../FormField/FormFieldPlainText";

class PhotoModal extends React.Component {
  constructor(props) {
    super();

    this.state = {
      ...props.photo,
      modalOpen: false,
      changed: false,
      prevValue: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.photo.credit !== state.credit) {
      return { credit: props.photo.credit };
    } else {
      return {};
    }
  }

  toggleModal = () => {
    if (this.state.modalOpen && this.state.changed) {
      if (
        window.confirm(
          "Are you sure you wish to close this form? Information will not be saved."
        )
      ) {
        this.setState({ modalOpen: !this.state.modalOpen });
      }
    } else {
      this.setState({ modalOpen: !this.state.modalOpen });
    }
  };

  updatePhoto = async () => {
    let photoId = this.state.photo_link_id;
    let photoData = { ...this.state };
    delete photoData.photo_id;
    delete photoData.modalOpen;
    delete photoData.changed;
    delete photoData.link;

    this.setState({ saving: true });

    try {
      if (photoId) {
        await api.put(`/photo/update/${photoId}`, photoData, getAuthHeader());
        window.setTimeout(() => {
          this.props.parentComponent.setState(
            { forceData: true },
            this.setState({ saving: false, changed: false })
          );
        }, 500);
      } else {
        throw new Error("no id provided");
      }
    } catch (err) {
      console.log(`Error in client.actions.photo: ${err}`);
    }
  };

  render() {
    return (
      <div>
        <div onClick={this.toggleModal}>
          <FaPencilAlt />
        </div>

        {this.state.modalOpen ? (
          <div
            id="photo-modal-background"
            onMouseDown={e => {
              if (e.target === e.currentTarget) {
                this.toggleModal();
              }
            }}
          >
            {/* Make sure to close the modal on a background click ^^ */}
            <div className="photo-modal-main">
              {/* The main "close" button. */}
              <span
                className="photo-modal-close btn btn-primary"
                onClick={this.toggleModal}
              >
                X
              </span>
              <h2>Edit Photo</h2>
              <Form>
                <FormFieldPlainText
                  title="Attribute Name"
                  field="attribute_name"
                  parentComponent={this}
                />

                <FormFieldPlainText
                  title="Credit"
                  field="credit"
                  parentComponent={this}
                />
              </Form>
              <span
                onClick={this.toggleModal}
                className="form-button-secondary"
              >
                {" "}
                Cancel{" "}
              </span>
              &nbsp;&nbsp;&nbsp;
              <span
                onClick={this.updatePhoto}
                className={
                  this.state.saving
                    ? "form-button-secondary"
                    : "form-button-primary"
                }
              >
                {this.state.saving ? "Saving...." : "Save"}
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default PhotoModal;
