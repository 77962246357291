import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import "./SelectreeHeader.css";
// components
import SelectreeBanner from "../SelectreeBanner/SelectreeBanner";
import PacifcIslandsBanner from "../PacificIslandsBanner/PacificIslandsBanner";
import QuickLinks from "../QuickLinks/QuickLinks";
import SelectreeNavbar from "../SelectreeNavbar/SelectreeNavbar";
import { setRegion } from "../../actions/setFilters";

class SelectreeHeader extends React.Component {
  componentDidMount() {
    try {
      let region = "";
      if (sessionStorage.ufeiRegion) {
        region = sessionStorage.ufeiRegion;
      }
      this.props.setRegion(region);
    } catch (err) {
      console.log(`components.SelectTreeHeader.componentDidMount: ${err}`);
    }
  }

  renderBanner = () => {
    try {
      if (this.props.region) {
        switch (this.props.region) {
          case "pacific islands":
            return <PacifcIslandsBanner />;
          default:
            return <SelectreeBanner />;
        }
      } else {
        return <SelectreeBanner />;
      }
    } catch (err) {
      console.log(`components.SelectreeHeader.renderBanner: ${err}`);
      return <SelectreeBanner />;
    }
  };

  render() {
    return (
      <Container style={{ position: "relative", zIndex: "10" }} fluid>
        <SelectreeNavbar />
        {this.renderBanner()}
        <div className="d-lg-block">
          <QuickLinks />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { region: state.region };
};

export default connect(mapStateToProps, { setRegion })(SelectreeHeader);
