import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./UserAccountEdit.css";

import api from "../../apis/selectree_api";
import { getAuthHeader } from "../../utilities/header";

import FormFieldPlainText from "../FormField/FormFieldPlainText";
import ResponsiveAccordion from "../ResponsiveAccordion/ResponsiveAccordion";
import { toast } from "react-toastify";

class UserAccountEdit extends React.Component {
  constructor(props) {
    super();
    let user = {};
    try {
      user = JSON.parse(localStorage.getItem("user"));
    } catch (err) {
      console.log(`Error in components.UserAccount.render: ${err}`);
    }

    this.state = {
      ...user,
      open: false,
      changed: false,
      new_password: "",
      confirm_password: "",
      errors: [],
    };

    const UPPER_REGEX = new RegExp(/.*[A-Z]/);
    const LOWER_REGEX = new RegExp(/.*[a-z]/);
    const NUMBER_REGEX = new RegExp(/.*[0-9]/);
    const LENGTH_REGEX = new RegExp(/.{9,}$/);
    const SPECIAL_CHAR_REGEX = new RegExp(
      /.*[-'/`~!#*$@_%+=.,^&(){}[\]|;:"<>?\\]/
    );

    this.PASSPHRASE_LENGTH_REGEX = new RegExp(/.{20,}$/);

    this.VALID_PASSWORD_REGEX = new RegExp(
      `^(?=${[
        UPPER_REGEX.source,
        LOWER_REGEX.source,
        NUMBER_REGEX.source,
        LENGTH_REGEX.source,
        SPECIAL_CHAR_REGEX.source,
      ].join(")(?=")}).*$`
    );

    this.rules = [
      { label: "One Uppercase", pattern: UPPER_REGEX },
      { label: "One Lowercase", pattern: LOWER_REGEX },
      { label: "One Number", pattern: NUMBER_REGEX },
      { label: "One Special Character", pattern: SPECIAL_CHAR_REGEX },
      { label: "Min 9 Characters", pattern: LENGTH_REGEX },
    ];
  }

  updateUser = async () => {
    let data = this.state;

    try {
      if (data.id) {
        let res = await api.put(`/users/edit`, data, getAuthHeader());
        localStorage.setItem("user", JSON.stringify(res.data));
        window.location.href = "/account";
      } else {
        throw new Error("no id provided");
      }
    } catch (err) {
      console.log(`Error in client.actions.taxon: ${err}`);
    }
  };

  changePassword = async () => {
    let data = this.state;
    try {
      if (data.id) {
        let res = await api.put(
          `/users/change-password`,
          data,
          getAuthHeader()
        );

        if (res.data.message === "Changed") {
          toast.success("Password has been successfully changed");
          this.setState({ password_saved: true, errors: [] });
        } else if (res.data.message === "Failed") {
          toast.error("An error occurred while tyring to update your password");
          this.setState({
            password_saved: false,
            errors: res.data.errors.errors,
          });
        }
      } else {
        throw new Error("no id provided");
      }
    } catch (err) {
      if (err?.request?.status === 401) {
        toast.error(
          "Authorization failed. You may have missed your reset window and will have request another reset email"
        );
      } else {
        toast.error(
          "Looks like trouble on our end. Please contact us if the issue persists"
        );
      }
      console.log(`Error in client.actions.taxon: ${err}`);
    }
  };

  disableSubmit = () => {
    return (
      (!this.state.new_password.match(this.PASSPHRASE_LENGTH_REGEX) &&
        !this.state.new_password.match(this.VALID_PASSWORD_REGEX)) ||
      this.state.new_password !== this.state.confirm_password
    );
  };

  render() {
    if (
      this.state.new_password !== this.state.confirm_password &&
      this.state.password_saved
    ) {
      this.setState({ password_saved: false });
    }

    return (
      <div className="container pt-4 pb-4">
        <h1 className="mb-2">Edit User Account</h1>

        <div className="mb-4">
          <ResponsiveAccordion title="USER INFORMATION">
            <Container>
              <Row>
                <Col lg={4}>
                  <FormFieldPlainText
                    title="First Name"
                    field="first_name"
                    parentComponent={this}
                  />
                </Col>
                <Col lg={4}>
                  <FormFieldPlainText
                    title="Last Name"
                    field="last_name"
                    parentComponent={this}
                  />
                </Col>
                <Col lg={4}>
                  <FormFieldPlainText
                    title="Email"
                    field="email"
                    parentComponent={this}
                  />
                </Col>

                <Col lg={12}>
                  <Button onClick={this.updateUser}>
                    <span>Submit</span>
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                </Col>
              </Row>
            </Container>
          </ResponsiveAccordion>
        </div>

        <div className="mb-4">
          <ResponsiveAccordion title="CHANGE PASSWORD">
            <Container>
              <Row>
                <Col lg={12}>
                  <FormFieldPlainText
                    title="New Password"
                    field="new_password"
                    parentComponent={this}
                    kind="password"
                  />
                </Col>
                <Col lg={12}>
                  <FormFieldPlainText
                    title="Confirm New Password"
                    field="confirm_password"
                    parentComponent={this}
                    kind="password"
                  />
                </Col>

                {this.state.errors && this.state.errors.length > 0 ? (
                  <Col lg={12}>
                    {this.state.errors.map(e => (
                      <div>- {e}</div>
                    ))}
                  </Col>
                ) : (
                  ""
                )}

                <Col lg={12}>
                  <div
                    className="user-account-edit--password-warning"
                    style={{ height: "fit-content" }}
                  >
                    {this.rules.map(rule => {
                      const cn =
                        this.state.new_password &&
                        this.state.new_password.match(rule.pattern)
                          ? "valid-password-after"
                          : "valid-password-before";
                      return (
                        <div>
                          <div className={cn}>{rule.label}</div>
                        </div>
                      );
                    })}
                    <div>
                      <div
                        className={
                          this.state.new_password ===
                          this.state.confirm_password
                            ? "valid-password-after"
                            : "valid-password-before"
                        }
                      >
                        Passwords Match
                      </div>
                    </div>
                    <div style={{ paddingTop: ".5em", paddingBottom: ".5em" }}>
                      or
                    </div>
                    <div
                      className={
                        this.state.new_password.match(
                          this.PASSPHRASE_LENGTH_REGEX
                        )
                          ? "valid-password-after"
                          : "valid-password-before"
                      }
                    >
                      20+ Characters
                    </div>
                  </div>
                </Col>

                <Col lg={12}>
                  <div>
                    <Button
                      onClick={this.changePassword}
                      disabled={this.disableSubmit()}
                    >
                      <span>Submit</span>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </ResponsiveAccordion>
        </div>
      </div>
    );
  }
}

export default UserAccountEdit;
