import api from "../apis/selectree_api";

export const getSearchChar = () => async dispatch => {
  try {
    const response = await api.get("/tree/search-characteristics");
    return dispatch({ type: "SEARCH_CHARACTERISTICS_DATA", payload: response });
  } catch (err) {
    console.log(`actions.searchChar.getSearchChar: ${err}`);
    return dispatch({ type: "SEARCH_CHARACTERISTICS_DATA", payload: [] });
  }
};
