import React from "react";
import { Container, Spinner } from "react-bootstrap";
import { ImQuotesLeft } from "react-icons/im";
import "./FeaturedQuote.css";
import { getFeaturedQuote } from "../../actions/featuredQuote";
import htmlParser from "react-html-parser";

class FeaturedQuote extends React.Component {
  constructor(props) {
    super();
    this.state = {
      featuredQuote: null,
    };
  }

  componentDidMount() {
    try {
      this.setFeaturedQuote();
    } catch (err) {
      console.log(`components.FeaturedQuote.componentDidMount: ${err}`);
    }
  }

  setFeaturedQuote = async () => {
    try {
      const response = await getFeaturedQuote();
      if (response && response.data) {
        const { data } = response;
        this.setState({ featuredQuote: data });
      } else {
        throw new Error(`response is null`);
      }
    } catch (err) {
      console.log(`components.FeaturedQuote.setFeaturedQuote: ${err}`);
    }
  };

  renderFeaturedQuote = () => {
    try {
      return this.state.featuredQuote ? (
        <div className="featured-quote-quote-container">
          <ImQuotesLeft className="featured-quote-quote-icon text-primary" />

          <h2
            className="featured-quote-quotation"
            dangerouslySetInnerHTML={{
              __html: htmlParser(this.state.featuredQuote.quote),
            }}
          />
          <p className="text-muted featured-quote-citation">
            -{this.state.featuredQuote.author}
          </p>
        </div>
      ) : (
        <Container xs={{ span: 4, offset: 4 }} className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Container>
      );
    } catch (err) {
      console.log(`components.FeaturedQuote.renderFeaturedQuote: ${err}`);
      return null;
    }
  };
  render() {
    return (
      <div className="container featured-quote-container">
        {this.renderFeaturedQuote()}
      </div>
    );
  }
}

export default FeaturedQuote;
