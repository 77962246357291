import React, { useEffect, useState } from "react";
import "./ListCollaboratorModal.css";
import TextInput from "../FormField/TextInput";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  addListCollaborator,
  removeListCollaborator,
} from "../../actions/list";
import { useDispatch } from "react-redux";
import { FaMinus } from "react-icons/fa";

function ListCollaboratorModal(props) {
  const [show, setShow] = useState(props.show ? props.show : false);
  const [email, setEmail] = useState();

  const dispatch = useDispatch();

  //Needed for dynamic updates from the outside
  useEffect(() => setShow(props.show), [props.show]);

  const list = props.list;

  async function removeCollabs(user_id, list_id) {
    dispatch(removeListCollaborator(list_id, user_id)).then(res => {
      if (res.status === 202) {
        toast.success("Removed collaborator!");
      } else {
        toast.error("Failed to remove collaborator!");
      }
    });
  }

  function Collaborators({ collaborators = [] }) {
    return (
      <div className="mt-2">
        <h4>Current Collaborators</h4>
        {collaborators.map(c => (
          <div className="d-flex collaboratorItem pl-4 p-2">
            <div
              style={{ width: "fit-content", blockSize: "fit-content" }}
              className="inline-block mr-auto"
            >
              <div>{`${c.first_name} ${c.last_name}`}</div>
              <small style={{ color: "#2D2D2D" }}>
                <a>{c.email}</a>
              </small>
            </div>
            <FaMinus
              style={{ alignSelf: "center" }}
              className="Icon d-flex mr-2"
              onClick={() => removeCollabs(c.id, list.list_id)}
            />
          </div>
        ))}
      </div>
    );
  }

  async function onSubmit(e) {
    e.preventDefault();
    try {
      dispatch(addListCollaborator(list.list_id, email)).then(res => {
        if (res.status === 400) {
          toast.error("Error, user is already added to the list");
        } else {
          toast.success("User successfully added!");
        }
      });
    } catch (err) {
      if (err.response && err.response.status) {
        if (err.response.status === 401) {
          toast.error("Lack permissions to share!");
        } else {
          toast.error("Error, user does not exist");
        }
      } else {
        toast.error("Couldn't Connect to the server");
      }
      console.log(`components.AddRemoveCollaborator.addCollab: ${err}`);
    }
  }

  return (
    <Modal
      show={show}
      centered
      onHide={() => {
        setShow(false);
        //This is needed if parent state is also contorling this
        if (props.onHide) props.onHide();
      }}
    >
      <Modal.Header className="p-3 border-0" closeButton>
        <Modal.Title>Add Collaborators on "{list.name}"</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <Form onSubmit={onSubmit}>
          <TextInput
            type="email"
            placeholder="Enter email..."
            id="emailInput"
            onChange={(name, v) => setEmail(v)}
          />
        </Form>
        {list &&
        Array.isArray(list.collaborators) &&
        list.collaborators.length > 0 ? (
          <Collaborators collaborators={list.collaborators} />
        ) : (
          ""
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ListCollaboratorModal;
