import React from "react";
import "./LoadingBar.css";

export function movebar(id) {
  var elem = document.getElementById(id);
  if (!elem) return;
  var width = 1;
  var id = setInterval(frame, 5);
  function frame() {
    if (width >= 100) {
      elem.style.width = "0%";
      clearInterval(id);
    } else {
      width++;
      elem.style.width = width + "%";
    }
  }
}

export default function LoadingBar(props) {
  const { height } = props;

  return (
    <div
      style={{
        marginBottom: "1rem",
        backgroundColor: "#E5E5E5",
        width: "100%",
        height: height ? height : "0.1rem",
      }}
    >
      <div
        id={props.id}
        style={{
          backgroundColor: "#45737",
        }}
        className="loading-bar"
      />
    </div>
  );
}
