import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

export function AccountRoute({ component: component, path: path }) {
  const user = useSelector(state => state.user);
  return user ? (
    <>
      <Route exact path={path} component={component} />
    </>
  ) : (
    <Redirect to={{ pathname: "/" }} />
  );
}

export function AdminRoute({ roles: roles, component: component, path: path }) {
  const user = useSelector(state => state.user);
  return user && roles.includes(user.role_id) ? (
    <>
      <Route exact path={path} component={component} />
    </>
  ) : (
    <Redirect to={{ pathname: "/" }} />
  );
}
