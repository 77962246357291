import React, { useEffect, useState } from "react";
import api from "../../apis/selectree_api";
import MenuInput from "../FormField/MenuInput";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearchOptions,
  getFilterOptions,
  setFilterOptions,
  resetFilterOptions,
  transformQueryDataToJSON,
  transformFilterObjectToQueryData,
} from "../../utilities/sessionStorage";
import { getAuthHeader } from "../../utilities/header";
import { Button, Toast } from "react-bootstrap";
import { components } from "react-select";
import { Link } from "react-router-dom";
import qs from "query-string";
import "./SavedSearchPicker.css";
import { getSearchCharThunk, getSearchByNameThunk } from "../../actions/search";
import { getUserListsThunk } from "../../actions/list";
import {
  DEFAULT_FILTER_OPTIONS,
  SAVED_SEARCH,
} from "../../utilities/constants";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";

const ERROR_PREFIX = "components.SavedSearchPicker";

/* ---- Customization for button actions on MenuInput ---- */

const Menu = props => {
  return (
    <>
      <components.Menu {...props}>
        {props.selectProps.fetchingData ? (
          <span className="fetching">Fetching data...</span>
        ) : (
          <div>{props.children}</div>
        )}
      </components.Menu>
    </>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>{props.children}</div>
        <Link to="/#search-by-char">
          <div>
            <FaEdit
              className="Icon"
              size={25}
              style={{ color: props.isFocused ? "#FFFFFF" : "#2D2D2D" }}
            />
          </div>
        </Link>
      </div>
    </components.Option>
  );
};

/* ---- Main component ---- */

export default function SavedSearchPicker({
  updateFilters,
  onFilterPage,
  hideButtonActions,
}) {
  const dispatch = useDispatch();
  const searchOptions = useSelector(state => state.searchOptions);
  const selectedSearch = searchOptions.savedSearch;
  const [error, setError] = useState("");

  const user_lists = useSelector(state => state.user_lists);

  useEffect(() => {
    if (onFilterPage) {
      if (selectedSearch) {
        const filterOptions = transformQueryDataToJSON(selectedSearch.name);
        setFilterOptions(filterOptions);
        updateFilters(filterOptions);
      }
    }
  }, [selectedSearch]);

  const handleError = err => {
    if (err?.response?.data?.message) {
      setError(err.response.data.message);
    } else {
      setError("Unknown error.");
    }
  };

  /* ---- Helper functions for "search by chars" page ---- */

  // autopopulates filters based on dropdown selection
  const populateFilters = async search => {
    dispatch({
      type: "SET_SEARCH_OPTIONS",
      payload: { ...searchOptions, savedSearch: search },
    });
  };

  // saves query_data modifications to the database
  const updateSavedSearch = async () => {
    try {
      // get data for request body
      const filterOptions = getFilterOptions();
      //const search_nickname = JSON.parse(sessionStorage.getItem("selectedSearch")).label;
      const query_data = transformFilterObjectToQueryData(filterOptions);
      // update saved search filters
      await api.patch(
        `/list/saved-search/${selectedSearch.value}`,
        {
          query_data: query_data,
        },
        getAuthHeader()
      );
      populateFilters({ ...selectedSearch, name: query_data });
      toast.success("Saved Search Updated");
      dispatch(getUserListsThunk());
    } catch (err) {
      toast.error("Update Failed");
      handleError(err);
    }
  };

  /* ---- Helper functions for search results page ---- */

  return (
    <div>
      <div
        className={`save-search-picker-${onFilterPage ? "filter" : "search"}`}
      >
        <MenuInput
          value={selectedSearch}
          placeholder="Saved Searches..."
          components={!hideButtonActions && { Menu, Option }}
          onChange={e => {
            if (!onFilterPage) {
              // re-render results gallery via dropdown
              const params = qs.parse(e.name);
              let searchOptions = getSearchOptions();
              searchOptions.savedSearch = e;

              if (params.searchTerm) {
                searchOptions.searchTerm = params.searchTerm;
                dispatch(
                  getSearchByNameThunk(searchOptions, params.searchTerm)
                );
              } else {
                dispatch(getSearchCharThunk(searchOptions, e.name));
              }
            } else {
              if (!e) {
                updateFilters(DEFAULT_FILTER_OPTIONS);
              }
              populateFilters(e);
            }
          }}
          options={user_lists
            .filter(l => l.type === SAVED_SEARCH)
            .map(obj => ({
              value: obj.list_id,
              name: obj.query_data,
              label: obj.name,
            }))}
        />
      </div>

      {onFilterPage && (
        <Button
          style={{
            display: "inline-block",
            marginBottom: "0.2rem",
            marginLeft: "0.6rem",
          }}
          onClick={() => {
            updateSavedSearch();
          }}
        >
          Save
        </Button>
      )}
    </div>
  );
}
