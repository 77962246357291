import {
  getSearchOptions,
  setSearchPageOptions,
} from "../utilities/sessionStorage";
import { DEFAULT_SEARCH_OPTIONS } from "../utilities/constants";

export default (state = DEFAULT_SEARCH_OPTIONS, action) => {
  switch (action.type) {
    case "SET_SEARCH_OPTIONS":
      setSearchPageOptions(action.payload);
      return action.payload;
    default:
      const sessionSearchOptions = getSearchOptions();
      return sessionSearchOptions === null ? state : sessionSearchOptions;
  }
};
