import React from "react";

import "./UserAccounts.css";

import { Container, Row, Col } from "react-bootstrap";

import api from "../../apis/selectree_api";
import { getAuthHeader } from "../../utilities/header";

import { FaPencilAlt } from "react-icons/fa";

import FormFieldText from "../FormField/FormFieldText";

class UserAccountRow extends React.Component {
  constructor(props) {
    super();

    this.state = {
      edit: false,
      role: 0,
    };
  }

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  submitForm = async () => {
    let role_id = this.props.roles.filter(
      role => role.role_name === this.state.role
    )[0].role_id;

    console.log({ role_id, user_id: this.props.user.user_id });

    try {
      if (role_id > 0) {
        let res = await api.put(
          `/users/change-role`,
          { role_id, user_id: this.props.user.user_id },
          getAuthHeader()
        );
        this.setState({ edit: false });
        this.props.parentComponent.setState({ forceData: true });
      } else {
        throw new Error("no id provided");
      }
    } catch (err) {
      console.log(
        `Error in components.UserAccounts.UserAccountRow.submitForm: ${err}`
      );
    }
  };

  render() {
    let user = this.props.user;
    let options = this.props.roles.map(role => ({
      label: role.role_name,
      value: role.role_id,
    }));

    return (
      <Row className="user-account-row">
        <Col lg={3}>{user.email}</Col>
        <Col lg={3}>
          {user.first_name} {user.last_name}
          {user.post_nominal ? `, ${user.post_nominal}` : ""}
        </Col>
        <Col lg={3}>{user.organization}</Col>
        <Col lg={3}>
          {this.state.edit ? (
            <div>
              <FormFieldText
                title="Change Role"
                field="role"
                options={options}
                parentComponent={this}
              />
              <span onClick={this.toggleEdit} className="form-button-secondary">
                Cancel
              </span>
              &nbsp;
              <span onClick={this.submitForm} className="form-button-primary">
                Save
              </span>
            </div>
          ) : (
            <div>
              {user.role_name}{" "}
              <span onClick={this.toggleEdit} className="user-account-row-edit">
                <FaPencilAlt />
              </span>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default UserAccountRow;
