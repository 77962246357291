import api from "../apis/selectree_api";
import { getAuthHeader } from "../utilities/header";

export const uploadFile = async csvData => {
  try {
    const response = await api.post(
      "/tree/start-upload-file",
      csvData,
      getAuthHeader(),
      {
        timeout: 300000,
      }
    );
    return response;
  } catch (err) {
    console.log(`Error in client.actions.uploadFile: ${err}`);
  }
};
