import React from "react";
import { ListGroup, Row, Col } from "react-bootstrap";
import "reactjs-popup/dist/index.css";
import { Thumbnail } from "./Thumbnail";
import "./ListItem.css";
import { MONTHS, PAGE_TOP_DESKTOP } from "../../utilities/constants";
import { Link } from "react-router-dom";

export default function ListItem({
  src,
  heading,
  subHeading,
  updateTime,
  description,
  tags,
  badgeOptions,
  isActive,
  treeId,
  actionItem,
  treeIndex,
  href,
  ...listItemProps
}) {
  const date = updateTime ? new Date(updateTime) : null;

  if (date) date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

  return (
    <ListGroup.Item
      className={`${isActive ? "" : "ListItem"} d-flex left-align`}
      {...listItemProps}
    >
      <Link
        className="d-flex left-align"
        to={href}
        onClick={() => window.scrollTo(PAGE_TOP_DESKTOP)}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <div className="d-flex">
          <div className="flex-column item-content">
            <h5 style={{ width: "100%" }}>{heading}</h5>
            <Row style={{ color: "#767676" }}>
              <Col style={{ paddingLeft: "10px", paddingRight: "0" }}>
                {src ? (
                  <Thumbnail
                    src={src}
                    width="5.5em"
                    height="5.5em"
                    fluid
                    className="rounded tree_thumbnail"
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                <span className="text-overflow">
                  <>{subHeading}</>
                  <>
                    {date ? (
                      <>
                        , Updated:{" "}
                        {MONTHS[date.getMonth()] +
                          " " +
                          date.getDate() +
                          ", " +
                          date.getFullYear()}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                </span>
                {description ? (
                  <span className="text-overflow">
                    <>{description}</>
                  </span>
                ) : (
                  ""
                )}
                {tags && tags.length > 0 ? (
                  <span className="text-overflow">
                    <>Tags: </>
                    <>
                      {tags
                        .filter(
                          x => typeof x === "string" && x.trim().length > 0
                        )
                        .join(", ")}
                    </>
                  </span>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Link>
      <div
        style={{
          marginLeft: "auto",
          display: "flex",
          marginRight: "0.7rem",
          color: "2D2D2D",
        }}
      >
        {actionItem ? actionItem : ""}
      </div>
    </ListGroup.Item>
  );
}
