import { NUMBER_OF_RESULTS } from "../utilities/constants";

const DEFAULT_SEARCH_OPTIONS = {
  activePage: 1,
  searchTerm: "",
  listSearchTerms: [],
  regions: [],
  categories: [],
  resultsPerPage: NUMBER_OF_RESULTS[0],
  sort: 1,
  scroll_y: 0,
};
export default (state = DEFAULT_SEARCH_OPTIONS, action) => {
  switch (action.type) {
    case "LIST_SEARCH_OPTIONS":
      return action.payload;
    default:
      return state;
  }
};
