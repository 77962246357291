import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import "./TreeDetail.css";

// action creators
import { getTreeDetail } from "../../actions/treeDetail";

// components
import TreeNameInfo from "../TreeNameInfo/TreeNameInfo";
import TreeDetailGallery from "../TreeDetailGallery/TreeDetailGallery";
import TreeInfo from "../TreeInfo/TreeInfo";
import SearchByNameSection from "../SearchByNameSection/SearchByNameSection";

import ErrorPage from "../ErrorPage/ErrorPage";
import { getUserListsThunk } from "../../actions/list";
import { PAGE_TOP_DESKTOP } from "../../utilities/constants";
import RoleGate from "../RoleGate/RoleGate";

export default function TreeDetail() {
  const params = useParams();
  const tree_id = parseInt(params.treeName);

  const dispatch = useDispatch();
  const treeDetail = useSelector(state => state.treeDetail);

  useEffect(() => {
    try {
      //Only get lists if logged In
      dispatch(getUserListsThunk());
      dispatch(getTreeDetail(tree_id));
    } catch (err) {
      console.log(`components.TreeDetail.useEffect: ${err}`);
    }
  }, [params]);

  return treeDetail && !Array.isArray(treeDetail) ? (
    <>
      <Container fluid className="tree-detail--tree-name-info">
        <Container>
          {/* <TreeNameInfo champCount={this.state.champCount} treeDetail={treeDetail} /> */}
          <TreeNameInfo treeDetail={treeDetail} />
        </Container>
      </Container>

      <Container fluid className="tree-detail--tree-detail-gallery pb-4 mb-4">
        <Container>
          <TreeDetailGallery treeDetail={treeDetail} />
        </Container>
      </Container>

      <Container fluid className="tree-detail--tree-info">
        <Container>
          <TreeInfo treeDetail={treeDetail} />
        </Container>
      </Container>

      {/*<Container>
            <div className="m-3 mb-5 tree-detail--nursury-warning p-3">
               <h1 className="p-3"><FaMap /></h1>
               <h5 className="p-3">
                  This tree is not yet part of the SelecTree Nursery Connection. If you would like to see this tree listed, or know of a nursery that sells it,
                  please contact us at ufei@capoly.edu.
               </h5>
            </div>
         </Container> */}

      <RoleGate allowed_roles={[2, 3]}>
        <div style={{ textAlign: "center", marginTop: ".5rem" }}>
          <Link
            className="form-button-primary"
            to={`/tree-detail/${treeDetail.data.treeId}/edit`}
            onClick={() => window.scrollTo(PAGE_TOP_DESKTOP)}
          >
            Edit this Tree
          </Link>
        </div>
      </RoleGate>
      <div className="d-none d-lg-block">
        <Container>
          <hr />
        </Container>
        <SearchByNameSection />
      </div>
    </>
  ) : (
    <Container xs={{ span: 4, offset: 4 }} className="text-center">
      {!treeDetail ? (
        <ErrorPage
          message={"There are no trees found that match that query."}
        />
      ) : (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
    </Container>
  );
}
