import React from "react";
import {
  Container,
  Row,
  Col,
  Image as ReactImage,
  Spinner,
} from "react-bootstrap";
import { getFeaturedTree } from "../../actions/featuredTree";
import htmlParser from "react-html-parser";
import { getFilterOptions } from "../../utilities/sessionStorage";
import { connect } from "react-redux";

import "./FeaturedTree.css";

class FeaturedTree extends React.Component {
  constructor(props) {
    super();
    this.state = {
      featuredTree: null,
      imageReady: false,
      preloadedImage: 0,
    };
  }

  componentDidMount() {
    try {
      let { region } = getFilterOptions();
      region = region ? region : null;
      this.setFeaturedTree(region);
    } catch (err) {
      console.log(`components.FeaturedTree.componentDidMount: ${err}`);
    }
  }

  componentDidUpdate(prevProps) {
    try {
      if (prevProps !== this.props) {
        const region = Array.isArray(this.props.region)
          ? null
          : this.props.region;
        this.setFeaturedTree(region);
      }
    } catch (err) {
      console.log(`components.FeaturedTree.componentDidUpdate: ${err}`);
    }
  }

  setFeaturedTree = async region => {
    try {
      const result = await getFeaturedTree(region);
      if (result && result.data && result.data.length) {
        const [data] = result.data;
        this.setState({ featuredTree: data });
        if (data.link) {
          this.preloadImage(data.link);
        } else {
          throw new Error(`data.link does not exist`);
        }
      } else {
        throw new Error(`result is null`);
      }
    } catch (err) {
      console.log(`components.FeaturedTree.setFeaturedTree: ${err}`);
    }
  };

  preloadImage = link => {
    try {
      const image_prefix = process.env.REACT_APP_BASE_IMAGE_URL;
      let img = new Image();
      img.src = `${image_prefix}${link}`;
      img.onload = () => {
        this.setState(prevState => ({
          preloadedImage: prevState.preloadedImage + 1,
        }));
        if (this.state.preloadedImage) {
          this.setState({ imageReady: true });
        }
      };
      img.onerror = evt => {
        console.log(
          `image not loaded: ${evt.currentTarget.getAttribute("src")}`
        );
      };
    } catch (err) {
      console.log(`components.FeaturedTree.preloadImage: ${err}`);
      return;
    }
  };

  handleTreeDetailClick = () => {
    try {
      let treeId = this.state.featuredTree.tree_id;
      if (treeId != null) {
        return (document.location.href = `/tree-detail/${treeId}`);
      } else {
        return (document.location.href = `/search-results`);
      }
    } catch (err) {
      console.log(`components.FeaturedTree.handleTreeDetailClick: ${err}`);
      return null;
    }
  };

  renderFeaturedTree = () => {
    try {
      const image_prefix = process.env.REACT_APP_BASE_IMAGE_URL;
      return this.state.imageReady ? (
        <>
          <div className="d-block d-lg-none">
            <hr className="hr-bold-short-black mb-0" />
            <h3 className="featured-tree-mobile-label category-label">
              FEATURED TREE
            </h3>
            <hr className="hr-bold-short-black mt-0 mb-0" />
          </div>
          <Row>
            <Col xs={12} md={6} lg={4}>
              <div className="featured-tree-image m-4">
                <ReactImage
                  src={`${image_prefix}${this.state.featuredTree.link}`}
                  onClick={this.handleTreeDetailClick}
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={8} className="featured-tree-text">
              <div className="d-none d-lg-block">
                <h3
                  className="category-label"
                  onClick={this.handleTreeDetailClick}
                >
                  FEATURED TREE
                </h3>
                <hr />
              </div>
              <h4
                className="featured-tree-label mt-md-4 mt-lg-0"
                onClick={this.handleTreeDetailClick}
              >
                {this.state.featuredTree.common}
              </h4>
              <p
                className="font-italic text-muted featured-tree-scientific-name"
                onClick={this.handleTreeDetailClick}
              >
                {htmlParser(this.state.featuredTree.name_concat)}
              </p>

              <p
                className="d-block d-lg-none"
                dangerouslySetInnerHTML={{
                  __html: htmlParser(this.state.featuredTree.memo),
                }}
              ></p>
              <p
                className="d-none d-lg-block"
                dangerouslySetInnerHTML={{
                  __html: htmlParser(this.state.featuredTree.memo),
                }}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Container xs={{ span: 4, offset: 4 }} className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Container>
      );
    } catch (err) {
      console.log(`components.FeaturedTree.renderFeaturedTree: ${err}`);
      return null;
    }
  };

  render() {
    return (
      <Container className="featured-tree-container pt-5">
        {this.renderFeaturedTree()}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { region: state.region };
};

export default connect(mapStateToProps, {})(FeaturedTree);
