import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import htmlParser from "react-html-parser";
import TreeDetailListWidget from "../TreeDetailListWidget/TreeDetailListWidget";
import "./TreeNameInfo.css";
import uuid from "react-uuid";
import { FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

import {
  DEFAULT_SORT,
  DEFAULT_ACTIVE_PAGE,
  NUMBER_OF_RESULTS,
} from "../../utilities/constants";
import RoleGate from "../RoleGate/RoleGate";

class TreeNameInfo extends React.Component {
  state = {
    isCommonCollapsed: true,
    isSynonymCollapsed: true,
  };

  renderScientificName = () => {
    try {
      if (this.props.treeDetail.scientificName) {
        return <span>{htmlParser(this.props.treeDetail.scientificName)}</span>;
      } else {
        throw new Error(`missing scientific name`);
      }
    } catch (err) {
      console.log(`components.TreeNameInfo.renderScientificName: ${err}`);
      return null;
    }
  };

  removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  toggleCommonCollapse = () => {
    this.setState(prevState => ({
      isCommonCollapsed: !prevState.isCommonCollapsed,
    }));
  };

  toggleSynonymCollapse = () => {
    this.setState(prevState => ({
      isSynonymCollapsed: !prevState.isSynonymCollapsed,
    }));
  };

  render() {
    if (this.props.treeDetail) {
      //filter added to not display primary
      const displayedCommonNames = this.state.isCommonCollapsed
        ? this.props.treeDetail.otherCommonNames.slice(1, 5)
        : this.props.treeDetail.otherCommonNames.slice(1);
      const displayedSynonyms = this.state.isSynonymCollapsed
        ? this.props.treeDetail.synonyms.slice(0, 5)
        : this.props.treeDetail.synonyms.slice(0);

      return (
        <Container className="tree-name-info">
          <Row>
            <Col xs={{ span: 12 }}>
              <Row className="tree-name-info-row">
                <Col
                  xs={{ span: 12 }}
                  md={4}
                  className="less-line-spacing tree-name-info-left-text"
                >
                  <h2 className="label-demibold">
                    {this.props.treeDetail.commonName
                      ? this.props.treeDetail.commonName
                      : null}
                  </h2>
                  <p className="font-italic text-lg tree-name-info-science-name">
                    {this.renderScientificName()}
                  </p>
                  <h4 className="label-demibold">
                    FAMILY{" "}
                    <span className="tree-name-info-family font-weight-normal">
                      {this.props.treeDetail.familyName
                        ? this.props.treeDetail.familyName
                        : null}
                    </span>
                  </h4>
                </Col>
                <Col
                  xs={{ span: 12 }}
                  md={4}
                  lg={4}
                  className="less-line-spacing tree-name-info-middle-text"
                >
                  <h4 className="label-demibold">SYNONYMS</h4>
                  {displayedSynonyms.length
                    ? displayedSynonyms.map(s => {
                        return (
                          <p className="font-italic" key={uuid()}>
                            {htmlParser(s.name_concat)}
                          </p>
                        );
                      })
                    : ""}
                  {/* Render the karat (caret) toggle with appropriate text based on the state. */}
                  {this.props.treeDetail.synonyms.length > 5 && (
                    <p
                      className="karat-toggle"
                      onClick={this.toggleSynonymCollapse}
                      style={{ cursor: "pointer", color: "#457537" }}
                    >
                      {this.state.isSynonymCollapsed
                        ? "Show More"
                        : "Show Less"}
                    </p>
                  )}
                </Col>
                <Col
                  xs={{ span: 12 }}
                  md={4}
                  lg={4}
                  className="less-line-spacing tree-name-info-right-text"
                >
                  <h4 className="label-demibold">ADDITIONAL COMMON NAMES</h4>
                  {displayedCommonNames.length
                    ? displayedCommonNames.map((s, idx) => {
                        // Show only the first 4 common names when collapsed.
                        // if (isCollapsed && idx >= 4) {
                        //   return null;
                        // }
                        return <p key={uuid()}>{s}</p>;
                      })
                    : ""}
                  {/* Render the karat (caret) toggle with appropriate text based on the state. */}
                  {this.props.treeDetail.otherCommonNames.length > 5 && (
                    <p
                      className="karat-toggle"
                      onClick={this.toggleCommonCollapse}
                      style={{ cursor: "pointer", color: "#457537" }}
                    >
                      {this.state.isCommonCollapsed ? "Show More" : "Show Less"}
                    </p>
                  )}
                </Col>

                <Col xs={{ span: 12 }} md={12} lg={12}>
                  {this.props.treeDetail.redirectTree.redirectTreeId ? (
                    <p className="tree-name-info-family">
                      See also:&nbsp;
                      <a
                        href={`/tree-detail/${this.props.treeDetail.redirectTree.redirectTreeId}`}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              this.props.treeDetail.redirectTree
                                .redirectTreeName,
                          }}
                        ></span>
                      </a>
                    </p>
                  ) : (
                    ""
                  )}
                  <div
                    className="inline-flex pt-1 "
                    style={{ paddingInline: "0rem" }}
                  >
                    <TreeDetailListWidget
                      treeId={this.props.treeDetail.treeId}
                      icon={
                        <FaPlusCircle
                          className="Icon"
                          style={{ marginRight: "0.5rem" }}
                          size={32}
                        />
                      }
                    />
                    <RoleGate allowed_roles={[2, 3]}>
                      <span className="pr-2">
                        <Link
                          className="form-button-primary"
                          to={`/tree-detail/${this.props.treeDetail.treeId}/edit`}
                        >
                          Edit this Tree
                        </Link>
                      </span>
                    </RoleGate>
                    <span className="tree-name-info-family pr-2">
                      See: all{" "}
                      <a
                        href={`search-results#searchTerm=${this.props.treeDetail.genus}&activePage=${DEFAULT_ACTIVE_PAGE}&resultsPerPage=${NUMBER_OF_RESULTS[1]}&sort=${DEFAULT_SORT}`}
                        className="font-italic primary"
                      >
                        {this.props.treeDetail.genus}
                      </a>
                    </span>
                    {/* load only if there are co-champs champ */}
                    {this.props.treeDetail.champ_count > 0 && (
                      <span className="tree-name-info-family">
                        or{" "}
                        <a
                          href={`https://californiabigtrees.calpoly.edu/search-results/?searchTerm=${this.removeTags(
                            this.props.treeDetail.scientificName
                          )}&activePage=1&resultsPerPage=10&sort=1`}
                          className="font-italic primary"
                        >
                          Champion{" "}
                        </a>
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      );
    } else {
      console.log(`components.TreeNameInfo: treeDetail empty`);
      return null;
    }
  }
}

const mapStateToProps = state => {
  return { treeDetail: state.treeDetail.data };
};

export default connect(mapStateToProps, {})(TreeNameInfo);
