import React, { useState, useEffect, useRef } from "react";
import qs from "query-string";

import "./SearchByName.css";
import { FaSearch, FaArrowRight } from "react-icons/fa";
import SearchByNameSuggestionBox from "./SearchByNameSuggestionBox";

import api from "../../apis/selectree_api";
import { getAuthHeader } from "../../utilities/header";
import { Form } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { getSearchByNameThunk } from "../../actions/search";
import useSyncFetch from "../../hooks/useSyncFetch";
import {
  DEFAULT_ACTIVE_PAGE,
  PAGE_TOP_DESKTOP,
} from "../../utilities/constants";

function SearchByName({ onSubmit, placeholder }) {
  let params = qs.parse(window.location.hash);
  const [searchTerm, setSearchTerm] = useState(
    params.searchTerm ? params.searchTerm : ""
  );
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedTree, setSelectedTree] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(0);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const inputElement = useRef();
  const history = useHistory();

  let searchOptions = useSelector(state => state.searchOptions);
  let region = undefined;

  if (sessionStorage.ufeiFilterOptions) {
    const options = JSON.parse(sessionStorage.ufeiFilterOptions);
    region = options.region;
  }
  //api to grab search suggestions
  const [suggestions] = useSyncFetch(
    [],
    async () => {
      try {
        if (searchTerm.length >= 1)
          return await api.get(
            `/tree/search-by-name?searchString=${searchTerm.trim()}&region=${
              region ? region : "all"
            }`,
            getAuthHeader()
          );
      } catch (err) {}
    },
    [searchTerm]
  );

  const blurInput = () => {
    inputElement.current.blur();
  };

  function submitForm(e) {
    //Use given onSubmit if given one
    if (onSubmit) {
      blurInput();
      onSubmit(e, selectedTree);
      return;
    }

    //Otherwise use Default Behavior
    e.preventDefault();
    if (suggestions.length === 1) {
      history.push(`/tree-detail/${suggestions[0].tree_id}`);
      blurInput();
    } else if (selectedTree.tree_id) {
      history.push(`/tree-detail/${selectedTree.tree_id}`);
      blurInput();
    } else if (pathname === "/search-results") {
      blurInput();
      history.push(`/search-results#searchTerm=${searchTerm}`);
      const getSearchResults = getSearchByNameThunk(
        {
          ...searchOptions,
          activePage: DEFAULT_ACTIVE_PAGE,
          savedSearch: "",
        },
        searchTerm
      );
      dispatch(getSearchResults);
    } else {
      blurInput();
      dispatch({
        type: "SET_SEARCH_RESULTS_AND_OPTIONS",
        payload: {
          searchResults: [],
          searchOptions: { ...searchOptions, savedSearch: "" },
        },
      });
      history.push(`/search-results#searchTerm=${searchTerm}`);
    }
    window.scrollTo(PAGE_TOP_DESKTOP);
  }
  //get current index of selected tree in suggestions list
  function getTreeIndex() {
    if (suggestions) {
      for (let i = 0; i < suggestions.length; i++) {
        if (suggestions[i] === selectedTree) return i;
      }
    }
    return -1;
  }
  //handle key control
  function handleKeyPress(e) {
    //get current index of selected tree in suggestions list
    let curHoverIndex = getTreeIndex();

    //handle down arrow
    if (e.key === "ArrowDown") {
      //stop default scroll behavior on some browsers
      e.preventDefault();
      //make first index to be the first suggestion if no suggestion is selected
      if (selectedTree === false || selectedTree === undefined) {
        setSelectedTree(suggestions[0]); //change selected tree to be the first suggestion
      } else if (curHoverIndex < suggestions.length - 1) {
        setSelectedTree(suggestions[curHoverIndex + 1]); //change selected tree
      }
    }
    //handle up arrow
    else if (e.key === "ArrowUp") {
      //stop default scroll behavior on some browsers
      e.preventDefault();
      //moves up on the list if not at the top
      if (curHoverIndex > 0) {
        setSelectedTree(suggestions[curHoverIndex - 1]); //change selected tree
      }
    }

    //scrolls the suggestion box if the hovered card is not in view
    //get current hovered card
    let hoveredCard = document.querySelector(`.${"persist-suggestion-hover"}`);
    if (hoveredCard !== null) {
      hoveredCard.parentNode.scrollTo({
        top: hoveredCard.offsetTop - hoveredCard.parentNode.offsetTop,
        behavior: "smooth",
      });
    }
  }
  return (
    <div className="search-by-name">
      <div className="search-by-name-container">
        <Form onSubmit={submitForm} autoComplete="off">
          <input
            ref={inputElement}
            id="search-by-name-search-box"
            className="search-by-name-input"
            placeholder={placeholder ? placeholder : "Enter a tree name..."}
            autoComplete="false"
            onFocus={() => setShowSuggestions(true)}
            onBlur={() => setShowSuggestions(false)}
            onInput={e => {
              if (selectedTree) {
                setSelectedTree(false);
              }
              setSearchTerm(e.target.value);
            }}
            //detect key press and dispatch accordingly
            onKeyDown={e => handleKeyPress(e)}
            value={
              selectedTree
                ? selectedTree.accepted_scientific.replace("&times", "x")
                : searchTerm
            }
          />
          {searchTerm ? (
            <button
              type="button"
              className="search-by-name-clear"
              onClick={() => {
                if (selectedTree) setSelectedTree(false);
                setSearchTerm("");
              }}
            >
              X
            </button>
          ) : (
            ""
          )}

          <button
            type="submit"
            className={`search-by-name-submit label-medium ${
              selectedTree && "search-by-name-button-ready"
            }`}
          >
            {selectedTree ? <FaArrowRight /> : <FaSearch />}
          </button>
        </Form>
        <SearchByNameSuggestionBox
          selectedTree={selectedTree}
          setSelectedTree={val => {
            if (showSuggestions) setSelectedTree(val);
          }}
          submitForm={submitForm}
          showSuggestions={searchTerm !== "" ? showSuggestions : false}
          suggestions={suggestions ? suggestions : []}
        />
      </div>
    </div>
  );
}
export default SearchByName;
