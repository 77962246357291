import React from "react";
import { Col, Row } from "react-bootstrap";
import MenuInput from "../FormField/MenuInput";

import {
  TREE_LIST,
  SAVED_SEARCH,
  INVENTORY,
  LIST_ADMIN,
} from "../../utilities/constants";

const filterSaveSearch = v => v.type === SAVED_SEARCH;
const filterTreeList = v => v.type === TREE_LIST;
const filterInventories = v => v.type === INVENTORY;
const filterYours = v => v.role === LIST_ADMIN;
const filterShared = v => v.role !== LIST_ADMIN;

export function filterLists(filter, list) {
  switch (filter.value) {
    case 0:
      return list.filter(filterTreeList);
    case 1:
      return list.filter(filterSaveSearch);
    case 2:
      return list.filter(filterInventories);
    case 3:
      return list.filter(filterYours);
    case 4:
      return list.filter(filterShared);
    default:
      return list;
  }
}

export default function ListFilter(props) {
  const LIST_FILTER_OPTIONS = [
    { value: 0, label: "Tree Lists" },
    { value: 1, label: "Saved Searches" },
    { value: 2, label: "For Sale" },
    { value: 3, label: "Created by me" },
    { value: 4, label: "Shared with me" },
  ];

  return (
    <Row className="">
      <Col>
        <MenuInput
          name="type"
          id="list-type-filter"
          placeholder="Filter Lists by..."
          value={props.defaultValue ? props.defaultValue : false}
          onChange={e => {
            if (props.onChange) props.onChange(e ? e : false);
          }}
          isSearchable={false}
          isClearable={true}
          options={LIST_FILTER_OPTIONS}
        />
      </Col>
    </Row>
  );
}
