import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./Appraisals.css";

class Appraisals extends React.Component {
  constructor(props) {
    super();
  }
  componentDidMount() {
    if (this.props.match.params.treeName) {
      let treeName = this.props.match.params.treeName;
      this.props.getTreeDetail(treeName);
    } else {
      console.log(`components.TreeDetail: no match`);
    }
  }

  render() {
    return (
      <div className="appraisals container">
        <>
          <p style={{ textAlign: "center" }}>
            <strong>Regional Plant Appraisal Committee Statement</strong>
          </p>
          <p>
            The Regional Plant Appraisal Advisory Board of the Western Chapter
            of the ISA has completed a review of existing species information
            that may be of use with both the previous{" "}
            <em>
              9<sup>th</sup>
            </em>{" "}
            edition and the current{" "}
            <em>
              10<sup>th</sup>
            </em>
            <em>&nbsp;Edition</em> of the{" "}
            <em>CTLA Guide for Plant Appraisal</em> in some appraisal
            assignments.&nbsp;In those assignments requiring a unit cost
            (cost/square inch of trunk area) we have suggested that using the
            cost of a 24-inch boxed tree&nbsp;or equivalent size as the
            “‘Largest Commonly Available Nursery Tree,” (LCANT) may be
            appropriate.&nbsp;We have provided limited information regarding
            group ratings, and approximate cross-sectional area of the
            appropriate group in that box size. Palms within the nursery trade
            are assessed for cost using “brown trunk feet” (BTF) rather than the
            traditional metric of "cross-sectional area" for trunk dimensions.
            The appraisal methodology for palms should involve the consideration
            of the species-specific cost per brown trunk foot of the palm
            species in the wholesale nursery and the actual BTF of the palm
            being appraised. The appraiser should use their professional
            judgment to decide what the appropriate size nursery tree is for
            their region and assignment, and investigate applicable costs.&nbsp;
          </p>
          <p>
            <em>
              The Advisory Board strongly suggests that it is most appropriate,
              and in fact critical, for appraisers to do their own comprehensive
              research and analysis of methodologies and plant material, and
              employ factors and processes that are well
            </em>
            <em>‐</em>
            <em>supported and defensible.</em>
          </p>
          <p style={{ textAlign: "center" }}>
            <strong>Approximate Cross-sectional Area for 24” Box Trees</strong>
          </p>
          <div style={{ textAlign: "center" }}>
            <table style={{ margin: "auto" }} border="1">
              <tbody>
                <tr>
                  <td>
                    <strong>Group</strong>
                  </td>
                  <td>
                    <strong>Approximate cross-sectional area*</strong>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    <strong>2.09</strong>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <strong>2.24</strong>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <strong>3.80</strong>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    <strong>4.75</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            *These values are “approximations” from the 2004 RPAG for 24” box
            tree size. No level of accuracy is implied. The appraiser should
            gather real world data when the assignment warrants.
          </p>
          <p>
            •{" "}
            <span style={{ textDecoration: "underline" }}>
              <strong>Cost</strong>
            </span>
            - Wholesale nursery costs vary significantly, between regions,
            markets, and species, as well as varying significantly over time.
            Therefore, cost data has not been included and the appraiser should
            perform their own investigation as appropriate for their region and
            assignment.
          </p>
          <p>
            •{" "}
            <span style={{ textDecoration: "underline" }}>
              <strong>Species</strong>
            </span>
            -The 10<sup>th</sup> edition states that “…functional limitations
            incorporate a species genotype with a specific location…” and
            implies that “species ratings” as such, are no longer appropriate.
            The SelecTree website will list climate suitability for various
            species which can be considered and incorporated into the factors
            addressing “functional limitations”, to the extent deemed
            appropriate by the appraiser.
          </p>
        </>
      </div>
    );
  }
}

export default Appraisals;
