import React, { useState } from "react";
import { addListThunk } from "../../actions/list";
import api from "../../apis/selectree_api";
import { getAuthHeader } from "../../utilities/header";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ListForm from "../TreeDetailListWidget/ListForm";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export default function CreateListModal(props) {
  const [modal, updateModal] = useState(false);
  const dispatch = useDispatch();

  return (
    <div>
      <div onClick={() => updateModal(true)}>{props.children}</div>
      <Modal show={modal} centered onHide={() => updateModal(false)}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListForm
            actionLabel="Create List"
            savedSearch
            formAction={async formData => {
              const list = (
                await api.post(`/list/create`, formData, getAuthHeader())
              ).data;
              dispatch(addListThunk(list.list_id)).then(response => {
                if (response.status < 300) {
                  toast.success("New list successfully created!");
                  updateModal(false);
                } else {
                  toast.error("Failed to create list!");
                }
              });
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
