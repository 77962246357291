import api from "../apis/selectree_api";
import { getAuthHeader } from "../utilities/header";
export const download = async downloadCharacteristics => {
  try {
    const response = await api.get(
      `/tree/download-file?${downloadCharacteristics.join("&")}`,
      getAuthHeader()
    );

    console.log("Got a response from download action!!\n");
    console.log("response in actions\n", response);
    return response;
  } catch (err) {
    console.log(`Error in client.actions.download: ${err}`);
  }
};
