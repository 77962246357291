import React from "react";
import { Image } from "react-bootstrap";

export function Thumbnail({
  width = "5.5em",
  height = "5.5em",
  backgroundColor = "white",
  marginRight = "0em",
  ...inputProps
}) {
  const style = {
    alignSelf: "center",
    objectFit: "cover",
    backgroundColor,
    width,
    height,
    marginRight: marginRight,
  };
  return <Image style={style} {...inputProps} />;
}
