import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import "./SearchByCharCard.css";

import FormFieldCheckbox from "../FormField/FormFieldCheckbox";
import FormFieldMultiText from "../FormField/FormFieldMultiText";
import { optionsReturn } from "../../utilities/searchChar";

class FlowersAndFruit extends React.Component {
  constructor(props) {
    super();
    this.state = {
      flowerColors: [],
      fruitTypes: [],
    };
  }

  componentDidUpdate = prevProps => {
    try {
      if (this.props.searchCharacteristics) {
        if (prevProps !== this.props) {
          const { flowerColors, fruitTypes } = this.props.searchCharacteristics;
          this.setState({
            flowerColors: flowerColors,
            fruitTypes: fruitTypes,
          });
        }
      }
    } catch (err) {
      console.log(
        `components.SearchByCharCard.FlowersAndFruits.componentDidUpdate: ${err}`
      );
    }
  };

  optionsFlowerColors = () => {
    try {
      return optionsReturn(this.state.flowerColors);
    } catch (err) {
      console.log(
        `components.SearchByCharCard.FlowersAndFruit.optionsFlowerColors: ${err}`
      );
    }
  };

  optionsFruitTypes = () => {
    try {
      return optionsReturn(this.state.fruitTypes);
    } catch (err) {
      console.log(
        `components.SearchByCharCard.FlowersAndFruit.optionsFruitTypes: ${err}`
      );
    }
  };

  render() {
    let parentComponent = this.props.parentComponent;
    return this.props.searchCharacteristics ? (
      <Container className="search-by-char-card--flowers-and-fruit">
        <Row>
          <Col lg={4}>
            <FormFieldMultiText
              title="Flower Color"
              field="flower_color"
              options={this.optionsFlowerColors()}
              parentComponent={parentComponent}
            />
          </Col>
          <Col lg={4}>
            <FormFieldMultiText
              title="Fruit Type"
              field="fruit_type"
              options={this.optionsFruitTypes()}
              parentComponent={parentComponent}
            />
          </Col>
          <Col lg={4}>
            <FormFieldCheckbox
              title="Has Fragrance?"
              field="fragrance"
              options={[{ label: "Yes", value: 1 }]}
              parentComponent={parentComponent}
            />
          </Col>
        </Row>
      </Container>
    ) : (
      <Container>
        <div>
          <p>Loading...</p>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { searchCharacteristics: state.searchCharacteristics.data };
};
export default connect(mapStateToProps, {})(FlowersAndFruit);
