import React from "react";
import { ListGroup } from "react-bootstrap";
import "reactjs-popup/dist/index.css";
import { Thumbnail } from "./Thumbnail";
import { Link } from "react-router-dom";
import { PAGE_TOP_DESKTOP } from "../../utilities/constants";

export default function TreeListItem({
  src,
  heading,
  subHeading,
  isActive,
  treeId,
  actionItem,
  treeIndex,
  href,
  ...listItemProps
}) {
  return (
    <div className="TreeListItem">
      <ListGroup.Item
        className={`${isActive ? "" : "ListItem"} d-flex left-align`}
        {...listItemProps}
      >
        <Link
          className="d-flex left-align"
          to={href}
          onClick={() => window.scrollTo(PAGE_TOP_DESKTOP)}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {src ? (
            <Thumbnail
              src={src}
              width="5.5em"
              height="5.5em"
              marginRight="1em"
              fluid
              className="rounded tree_thumbnail"
            />
          ) : (
            ""
          )}
          <div style={{ width: "60%" }} className="d-block">
            <div className="d-flex">
              <div className="flex-column item-content">
                {treeIndex !== undefined ? (
                  <h5 style={{ fontSize: "1rem" }} className="text-overflow">
                    {treeIndex + 1 + ". " + heading}
                  </h5>
                ) : (
                  <h5 style={{ fontSize: "1rem" }} className="text-overflow">
                    {heading}
                  </h5>
                )}
                <span style={{ fontSize: "0.9rem" }} className="text-overflow">
                  <i>{subHeading}</i>
                </span>
              </div>
            </div>
          </div>
        </Link>
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            marginRight: "0.7rem",
            color: "2D2D2D",
          }}
        >
          {actionItem ? actionItem : ""}
        </div>
      </ListGroup.Item>
    </div>
  );
}
