import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import FormFieldText from "../FormField/FormFieldText";
import FormFieldRange from "../FormField/FormFieldRange";
import { optionsReturn } from "../../utilities/searchChar";
import FormFieldMultiText from "../FormField/FormFieldMultiText";
import { getGenus } from "../../actions/genus";
import { getFamily } from "../../actions/family";
class DownloadFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      genus: 0,
      family: 0,
      missingInfo: optionsReturn(
        this.props.parentComponent.totalCharacteristics
      ),
    };

    console.log("Download Filters constructor", this.state);
  }

  async componentDidMount() {
    console.log("DownloadFilter: in componentDidMount!!\n");
    let genusPromise = await getGenus();
    let genusArrDict = genusPromise.data[0];
    let genusList = [];
    let familyPromise = await getFamily();
    let familyArrDict = familyPromise.data[0];
    let familyList = [];
    console.log("This is familyPromise", familyPromise);
    for (let i = 2; i < genusArrDict.length; i++) {
      genusList.push(genusArrDict[i].genus);
    }
    for (let i = 0; i < familyArrDict.length; i++) {
      familyList.push(familyArrDict[i].family);
    }

    this.setState({
      genus: genusList,
      family: familyList,
    });
  }

  ToggleButton() {
    this.setState({ textflag: !this.state.textflag });
  }

  getGenusFields = () => {
    return this.state.genus;
  };

  optionsGenus = () => {
    try {
      return optionsReturn(this.state.genus);
    } catch (err) {
      console.log(
        `components.SearchByCharCard.DownloadFilters.optionsGenus: ${err}`
      );
    }
  };

  optionsFamily = () => {
    try {
      return optionsReturn(this.state.family);
    } catch (err) {
      console.log(
        `components.SearchByCharCard.DownloadFilters.optionsFamily: ${err}`
      );
    }
  };

  optionsMissingInfo = () => {
    try {
      return this.state.missingInfo;
    } catch (err) {
      console.log(
        `components.SearchByCharCard.DownloadFilters.optionsFamily: ${err}`
      );
    }
  };

  render() {
    let parentComponent = this.props.parentComponent;
    return (
      <Container className="search-by-char-card--download-filters">
        <Row>
          <Col lg={{ span: 6 }}>
            <FormFieldText
              title="Genus"
              field="genus"
              options={this.optionsGenus()}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 6 }}>
            <FormFieldText
              title="Family"
              field="family"
              options={this.optionsFamily()}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 6 }}>
            <FormFieldRange
              title="Photos"
              minField="minPhotos"
              maxField="maxPhotos"
              options={{
                0: "0",
                10: "10",
                20: "20",
                30: "30",
                40: "40",
                50: "50",
                60: "60",
              }}
              parentComponent={parentComponent}
            />
          </Col>

          {!this.state.textflag && this.props.text}
          <Col lg={{ span: 6 }}>
            <FormFieldMultiText
              title="Missing Info?"
              field="missingInfo"
              options={this.optionsMissingInfo()}
              parentComponent={parentComponent}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default DownloadFilters;
