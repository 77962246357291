import React from "react";
import { Modal, Button } from "react-bootstrap";

const DeleteModal = ({
  showModal,
  hideModal,
  confirmModal,
  params,
  message,
}) => {
  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={() => {
            hideModal();
            confirmModal(params);
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
