import React, { useState, useRef } from "react";
import "./SearchByName.css";
import uuid from "react-uuid";

/**
 * Convert a template string into HTML To Parse out the text body
 * @param  {String} str The template string
 * @return {String}       The template HTML
 */
function ExtractHTMLText(str) {
  var parser = new DOMParser();
  var doc = parser.parseFromString(str, "text/html");
  return doc.body.textContent;
}

function SearchByNameSuggestionCard(props) {
  let { suggestion, setSelectedTree } = props;

  function chooseTreeClickHandler(e) {
    try {
      if (props.submitForm) props.submitForm(e);
    } catch (err) {
      console.log(`component.SearchByName.searchByNameClickHandler: ${err}`);
    }
  }

  const cleanedScientific = ExtractHTMLText(
    suggestion.accepted_scientific.replace("&times", "x")
  );

  return (
    <div
      id="card"
      key={uuid()}
      className={`search-by-name-suggestion-card ${
        props.selectedTree && props.selectedTree === suggestion
          ? "persist-suggestion-hover"
          : ""
      }`}
      onMouseDown={chooseTreeClickHandler}
      onMouseMove={() => {
        let cleanedSuggestion = suggestion;
        cleanedSuggestion.accepted_scientific = cleanedScientific;
        setSelectedTree(suggestion);
      }}
    >
      <div className="label-medium">
        {/*suggestion.accepted_scientific.replace("&times", "x")*/}
        {cleanedScientific}
      </div>
      <div className="small">
        {
          /*suggestion.accepted_scientific*/ cleanedScientific !==
          suggestion.name_concat
            ? `Synonym for: ${ExtractHTMLText(suggestion.name_unformatted)}`
            : ""
        }
      </div>

      <div className="small">{suggestion.accepted_common}</div>
      <div className="small">
        {suggestion.accepted_common !== suggestion.common
          ? `AKA: ${suggestion.common}`
          : ""}
      </div>
    </div>
  );
}

export default SearchByNameSuggestionCard;
