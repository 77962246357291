import { createStore, applyMiddleware, compose } from "redux";
import reducers from "../../reducers/reducers";
import thunk from "redux-thunk";

// For Browser Redux Devtools Extension
import { composeWithDevTools } from "redux-devtools-extension";

export const store = createStore(
  reducers,
  process.env.REACT_APP_ENV !== "prod"
    ? composeWithDevTools(applyMiddleware(thunk))
    : compose(applyMiddleware(thunk))
);
