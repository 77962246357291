import React from "react";
import { connect } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";

import FormFieldSingleCheckbox from "../FormField/FormFieldSingleCheckbox";
import FormFieldPlainText from "../FormField/FormFieldPlainText";
import FormFieldMultiText from "../FormField/FormFieldMultiText";
import FormFieldRichText from "../FormField/FormFieldRichText";
import FormFieldText from "../FormField/FormFieldText";
import { optionsReturnForForm } from "../../utilities/searchChar";

class SiteConditions extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  optionsFor = field => {
    try {
      if (
        this.props.searchCharacteristics &&
        this.props.searchCharacteristics[field]
      ) {
        return optionsReturnForForm(this.props.searchCharacteristics[field]);
      } else {
        return [];
      }
    } catch (err) {
      console.log(`components.TreeFormCard.SiteConditions.optionsFor: ${err}`);
    }
  };
  render() {
    let parentComponent = this.props.parentComponent;
    return (
      <Container className="search-by-char-card--site-conditions">
        <Row>
          <Col lg={6}>
            <FormFieldRichText
              title="General Information"
              field="memo"
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={6}>
            <FormFieldSingleCheckbox
              title="Publish Status"
              field="display"
              options={[
                { label: "Draft", value: "Draft" },
                { label: "Published", value: "Published" },
              ]}
              required={true}
              parentComponent={parentComponent}
            />

            <FormFieldSingleCheckbox
              title="Pacific Islands?"
              field="pacific_island"
              options={[
                { label: "No", value: "0" },
                { label: "Yes", value: "1" },
              ]}
              required={true}
              parentComponent={parentComponent}
            />

            <Row>
              <Col lg={4}>
                <FormFieldSingleCheckbox
                  title="Has redirect?"
                  field="has_redirect"
                  options={[
                    { label: "No", value: "0" },
                    { label: "Yes", value: "1" },
                  ]}
                  required={true}
                  parentComponent={parentComponent}
                />
              </Col>

              <Col lg={8}>
                {parentComponent.state.has_redirect === "1" ? (
                  <FormFieldPlainText
                    title="Redirect Tree ID"
                    field="redirect"
                    parentComponent={parentComponent}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Col>

          <Col lg={12}>
            <FormFieldPlainText
              title="Photo Locations"
              field="photolocations"
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={6}>
            <FormFieldMultiText
              title="Landscape Application"
              field="landscape_application"
              parentComponent={parentComponent}
              options={this.optionsFor("landscapeApplications")}
            />
          </Col>
          <Col lg={6}>
            <FormFieldMultiText
              title="Landscape Use"
              field="landscape_use"
              options={this.optionsFor("landscapeUses")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={3}>
            <FormFieldPlainText
              title="Native Range"
              field="native_range"
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={3}>
            <FormFieldSingleCheckbox
              title="Is CA Native?"
              field="california_native"
              options={[
                { label: "No", value: "No" },
                { label: "Yes", value: "Yes" },
              ]}
              parentComponent={this.props.parentComponent}
            />
          </Col>
          <Col lg={3}>
            <FormFieldSingleCheckbox
              title="Invasive to Hawaii"
              field="pi_invasive"
              options={[
                { label: "No", value: "No" },
                { label: "Yes", value: "Yes" },
              ]}
              parentComponent={parentComponent}
            />
          </Col>
          {/* <Col lg={6}>
                                <FormFieldSingleCheckbox
                                    title="Is Hawaii Native?"
                                    field="pacific_island"
                                    options={[{ label: "No", value: "No" }, { label: "Yes", value: "Yes" }]}
                                    parentComponent={this.props.parentComponent}
                                />
                            </Col> */}

          <Col lg={3}>
            <FormFieldSingleCheckbox
              title="Invasive to California"
              field="ca_invasive"
              options={[
                { label: "No", value: "0" },
                { label: "Yes", value: "1" },
              ]}
              parentComponent={parentComponent}
            />
          </Col>
          <Col lg={3}>
            <FormFieldSingleCheckbox
              title="Schoolyard Tree?"
              field="schoolyard"
              options={[
                { label: "No", value: "0" },
                { label: "Yes", value: "1" },
              ]}
              parentComponent={this.props.parentComponent}
            />
          </Col>
          <Col lg={3}>
            <FormFieldMultiText
              title="Climate Adapted Regions"
              field="climate_adapted_regions"
              options={this.optionsFor("climateZones")}
              parentComponent={parentComponent}
            />
          </Col>
          {parentComponent.state.schoolyard === "1" ? (
            <Col lg={12}>
              <FormFieldRichText
                title="Schoolyard Use"
                field="schoolyardUseNotes"
                parentComponent={parentComponent}
              />
            </Col>
          ) : (
            ""
          )}

          <Col lg={12}>
            <FormFieldPlainText
              title="Invasiveness Text"
              field="invasive_text"
              parentComponent={parentComponent}
            />
          </Col>
          <Col lg={12}>
            <FormFieldRichText
              title="Ethnobotanical Info/Tree Use"
              field="ethnobotanical_info"
              parentComponent={parentComponent}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { searchCharacteristics: state.searchCharacteristics.data };
};
export default connect(mapStateToProps, {})(SiteConditions);
