import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";

import "./ResetPassword.css";

import api from "../../apis/selectree_api";

import FormFieldPlainText from "../FormField/FormFieldPlainText";
import ResponsiveAccordion from "../ResponsiveAccordion/ResponsiveAccordion";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { PAGE_TOP_DESKTOP } from "../../utilities/constants";

class ResetPassword extends React.Component {
  constructor(props) {
    super();
    this.handleForm = props;

    this.state = {
      password_saved: false,
      new_password: "",
      confirm_password: "",
      errors: [],
    };
    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get("token");
    this.auth = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const UPPER_REGEX = new RegExp(/.*[A-Z]/);
    const LOWER_REGEX = new RegExp(/.*[a-z]/);
    const NUMBER_REGEX = new RegExp(/.*[0-9]/);
    const LENGTH_REGEX = new RegExp(/.{9,}$/);
    const SPECIAL_CHAR_REGEX = new RegExp(
      /.*[-'/`~!#*$@_%+=.,^&(){}[\]|;:"<>?\\]/
    );

    this.PASSPHRASE_LENGTH_REGEX = new RegExp(/.{20,}$/);

    this.VALID_PASSWORD_REGEX = new RegExp(
      `^(?=${[
        UPPER_REGEX.source,
        LOWER_REGEX.source,
        NUMBER_REGEX.source,
        LENGTH_REGEX.source,
        SPECIAL_CHAR_REGEX.source,
      ].join(")(?=")}).*$`
    );

    this.rules = [
      { label: "One Uppercase", pattern: UPPER_REGEX },
      { label: "One Lowercase", pattern: LOWER_REGEX },
      { label: "One Number", pattern: NUMBER_REGEX },
      { label: "One Special Character", pattern: SPECIAL_CHAR_REGEX },
      { label: "Min 9 Characters", pattern: LENGTH_REGEX },
    ];
  }

  login = data => {
    localStorage.setItem("user", JSON.stringify(data.response));
    localStorage.setItem("token", data.token);
    localStorage.setItem("loggedIn", true);
    window.location.href = window.location.origin;
  };

  changePassword = async () => {
    try {
      let res = await api.put(
        `/users/change-password`,
        { new_password: this.state.new_password },
        this.auth
      );
      if (res.data.message === "Changed") {
        delete res.data.message;

        let data = { ...res.data };
        toast.success("Password has been successfully changed");
        this.setState({ password_saved: true, errors: [] });
        this.props.setUserData(data);
        window.scrollTo(PAGE_TOP_DESKTOP);
        this.props.history.push("/");
      } else if (res.data.message === "Failed") {
        toast.error("An error occurred while trying to update your password");
        this.setState({
          password_saved: false,
          errors: res.data.errors.errors,
        });
      }
    } catch (err) {
      if (err?.request?.status === 401) {
        toast.error(
          "Authorization failed. You may have missed your reset window and will have request another reset email"
        );
      } else {
        toast.error(
          "Looks like trouble on our end. Please contact us if the issue persists"
        );
      }
      console.log(`Error in client.resetpassword: ${err}`);
    }
  };

  disableSubmit = () => {
    return (
      (!this.state.new_password.match(this.PASSPHRASE_LENGTH_REGEX) &&
        !this.state.new_password.match(this.VALID_PASSWORD_REGEX)) ||
      this.state.new_password !== this.state.confirm_password
    );
  };

  render() {
    if (
      this.state.new_password !== this.state.confirm_password &&
      this.state.password_saved
    ) {
      this.setState({ password_saved: false });
    }

    return (
      <div className="container pt-4 pb-4">
        <div className="mb-4">
          <ResponsiveAccordion title="Reset Password">
            <Container>
              <Row>
                <Col lg={12}>
                  <FormFieldPlainText
                    title="New Password"
                    field="new_password"
                    parentComponent={this}
                    kind="password"
                  />
                </Col>
                <Col lg={12}>
                  <FormFieldPlainText
                    title="Confirm New Password"
                    field="confirm_password"
                    parentComponent={this}
                    kind="password"
                  />
                </Col>

                {this.state.errors && this.state.errors.length > 0 ? (
                  <Col lg={12}>
                    {this.state.errors.map(e => (
                      <div>- {e}</div>
                    ))}
                  </Col>
                ) : (
                  ""
                )}

                <Col lg={12}>
                  <div
                    className="user-account-edit--password-warning"
                    style={{ height: "fit-content" }}
                  >
                    {this.rules.map(rule => {
                      const cn =
                        this.state.new_password &&
                        this.state.new_password.match(rule.pattern)
                          ? "valid-password-after"
                          : "valid-password-before";
                      return (
                        <div>
                          <div className={cn}>{rule.label}</div>
                        </div>
                      );
                    })}
                    <div>
                      <div
                        className={
                          this.state.new_password ===
                          this.state.confirm_password
                            ? "valid-password-after"
                            : "valid-password-before"
                        }
                      >
                        Passwords Match
                      </div>
                    </div>
                    <div style={{ paddingTop: ".5em", paddingBottom: ".5em" }}>
                      or
                    </div>
                    <div
                      className={
                        this.state.new_password.match(
                          this.PASSPHRASE_LENGTH_REGEX
                        )
                          ? "valid-password-after"
                          : "valid-password-before"
                      }
                    >
                      20+ Characters
                    </div>
                  </div>
                </Col>

                <Col lg={12}>
                  <div>
                    {this.state.password_saved ? (
                      <div>Your password is saved. Loging you in</div>
                    ) : (
                      ""
                    )}
                    <Button
                      onClick={this.changePassword}
                      disabled={this.disableSubmit()}
                    >
                      <span>Submit</span>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </ResponsiveAccordion>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { treeDetail: state.treeDetail.data };
};

const setUserData = user_data => async dispatch =>
  dispatch({ type: "LOGIN", payload: user_data });

export default connect(mapStateToProps, { setUserData })(
  withRouter(ResetPassword)
);
