import React from "react";
import { Navbar, Nav, Image, Container, NavDropdown } from "react-bootstrap";

import api from "../../apis/selectree_api";

import Login from "../Login/Login";

import "./selectreeNavbar.css";
import SearchByName from "../SearchByName/SearchByName";

import { getAuthHeader } from "../../utilities/header";
import { connect } from "react-redux";
import { setRegion } from "../../actions/setFilters";
import { withRouter } from "react-router-dom";
// components added for quick links to navbar
import RegionSelector from "../RegionSelector/RegionSelector";
import { PAGE_TOP_DESKTOP } from "../../utilities/constants";
import { Link } from "react-router-dom";
class SelectreeNavbar extends React.Component {
  componentDidMount() {
    try {
      let region = "";
      if (sessionStorage.ufeiRegion) {
        region = sessionStorage.ufeiRegion;
      }
      this.props.setRegion(region);
    } catch (err) {
      console.log(`components.SelectTreeHeader.componentDidMount: ${err}`);
    }
  }

  addTree = async () => {
    try {
      let response = await api.post(`/tree/add`, {}, getAuthHeader());
      window.location.href = `tree-detail/${response.data}/edit`;
    } catch (err) {
      console.log(`Error in client.components.SelectreeNavbar.addTree: ${err}`);
    }
  };

  downloadPage() {
    try {
      let response = api.post(`/tree/download-file`, {}, getAuthHeader());
      window.location.href = `download`;
    } catch (err) {
      console.log(
        `Error in client.components.SelectreeNavbar.download: ${err}`
      );
    }
  }

  uploadPage() {
    try {
      let response = api.post(`/upload-file`, {}, getAuthHeader());
      window.location.href = `upload`;
    } catch (err) {
      console.log(`Error in client.components.SelectreeNavbar.upload: ${err}`);
    }
  }

  render() {
    const { history } = this.props;

    return (
      <Container fluid>
        <Navbar
          expand="xl"
          className="selectree-navbar bg-light navbar-light border-0"
        >
          <Navbar.Brand
            href="https://ufei.calpoly.edu"
            className="col-4 col-lg-2 mr-auto d-inline-block align-top"
          >
            {this.props.region === "pacific islands" ? (
              <Image
                src={`${process.env.PUBLIC_URL}/images/logos/ufei_palms.png`}
                className="selectree-navbar-logo"
                fluid
              />
            ) : (
              <Image
                src={`${process.env.PUBLIC_URL}/images/logos/UFEI.png`}
                className="selectree-navbar-logo"
                fluid
              />
            )}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto d-lg-flex justify-content-between align-items-center">
              <NavDropdown
                title="RESOURCES"
                id="collasible-nav-dropdown-1"
                alignRight
                drop={"down"}
              >
                <NavDropdown.Item
                  onClick={e => {
                    e.stopPropagation();
                    history.push("");
                  }}
                >
                  SELECTREE
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://californiabigtrees.calpoly.edu/"
                  target="_blank"
                >
                  CALIFORNIA BIG TREES
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://urbantreekey.calpoly.edu/"
                  target="_blank"
                >
                  URBAN TREE KEY
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://datastudio.google.com/reporting/880d448d-de26-48d3-b563-0c6317e456e4/page/jWHKB"
                  target="_blank"
                >
                  URBAN TREE INVENTORY
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://pacificislandstreekey.calpoly.edu/"
                  target="_blank"
                >
                  PACIFIC ISLANDS TREE KEY
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://jventu09.users.earthengine.app/view/urban-tree-detector"
                  target="_blank"
                >
                  URBAN TREE DETECTOR
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://calpoly.maps.arcgis.com/apps/webappviewer/index.html?id=3c4233d842a64e41ac9cf3713848a481"
                  target="_blank"
                >
                  CA NATIVE TREES
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={e => {
                    e.stopPropagation();
                    history.push("/search-lists");
                  }}
                >
                  TREE LISTS
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="DETAILS"
                id="collasible-nav-dropdown-2"
                alignRight
                drop={"down"}
              >
                <NavDropdown.Item
                  href="https://ufei.calpoly.edu/about-ufei/"
                  target="_blank"
                >
                  ABOUT UFEI
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://ufei.calpoly.edu/ufei-publications/"
                  target="_blank"
                >
                  UFEI PUBLICATIONS
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://ufei.calpoly.edu/references/"
                  target="_blank"
                >
                  CITATIONS
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://ufei.calpoly.edu/urban-wood/"
                  target="_blank"
                >
                  URBAN WOOD INFORMATION
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://ufei.calpoly.edu/pitch-canker-task-force/"
                  target="_blank"
                >
                  PITCH CANKER TASK FORCE
                </NavDropdown.Item>
              </NavDropdown>

              <Login defaultModal={false} />
            </Nav>
          </Navbar.Collapse>

          <div className="d-lg-none container-fluid selectree-navbar-search">
            <SearchByName />
          </div>
        </Navbar>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { region: state.region };
};

export default connect(mapStateToProps, { setRegion })(
  withRouter(SelectreeNavbar)
);
