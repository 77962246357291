import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import BaseSelect from "react-select";
import FixRequiredSelect from "./FixRequiredSelect";
import "./FormField.css";

const Select = props => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

function MenuInput(props) {
  const {
    value,
    defaultValue,
    label,
    onChange,
    required,
    id,
    name,
    hasError,
    isMulti,
    errorMessage,
    isClearable,
    ...inputProps
  } = props;

  const [edited, setEditStatus] = useState("false");

  function decideColor(state, error) {
    if (state.isFocused) return "1px solid #457537";
    else if (error === "") return "1px solid red";

    return "1px solid lightgrey";
  }

  const selectStyle = {
    option: (provided, state) => ({
      ...provided,
      "&:active": {
        backgroundColor: "#457537", // background color when clicked
      },
      backgroundColor: state.isFocused ? "#457537" : "white",
      color: state.isFocused ? "white" : "black",
    }),
    control: (base, state) => ({
      ...base,
      "&:hover": { borderColor: "#457537" }, // border style on hover
      border: decideColor(state, hasError),
      boxShadow: state.isFocused ? "5px #457537" : 0,
    }),
    menu: provided => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  return (
    <Form.Group className="formInput">
      {label && label !== "" ? (
        <Form.Label>
          {label}
          {props.required ? <a style={{ color: "red" }}> *</a> : ""}
        </Form.Label>
      ) : (
        ""
      )}
      <Select
        defaultValue={
          isMulti
            ? defaultValue
              ? defaultValue
              : []
            : defaultValue
            ? defaultValue
            : ""
        }
        value={isMulti ? (value ? value : []) : value ? value : ""}
        className="react-select"
        isMulti={isMulti}
        {...inputProps}
        styles={selectStyle}
        required={props.required}
        isClearable={true}
        onChange={e => {
          props.onChange(e ? e : "");
        }}
        onBlur={() => setEditStatus("true")}
      />
    </Form.Group>
  );
}

export default MenuInput;
