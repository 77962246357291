import api from "../apis/selectree_api";
export const getGenus = async () => {
  try {
    const response = await api.get(`/tree/genus`);
    console.log("Got a response from genus!!\n");
    return response;
  } catch (err) {
    console.log(`Error in client.actions.download: ${err}`);
  }
};
