import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
// react-select styling options
import reactSelectStyles from "../../customUI/react-select-options";
import "./FormField.css";

class FormFieldMultiText extends React.Component {
  constructor(props) {
    super();

    if (props.parentComponent.state) {
      let value = props.parentComponent.state[props.field];
      if (value === undefined || value === null) {
        console.log(
          `Components.FormField.FormFieldMultitext- Cannot find field ${props.field} on the parent component.`
        );
      } else if (!Array.isArray(value)) {
        console.log(
          `Components.FormField.FormFieldMultitext- Please make sure ${props.field} on the parent component defaults to an array.`
        );
      }
    } else {
      console.log(
        `Components.FormField.FormFieldMultitext- Please pass in the parent component that has the state.`
      );
    }

    this.state = {
      disabled: false,
      curPlaceHolder: props.placeholder,
    };
  }

  change = selected => {
    try {
      if (!selected) {
        selected = [];
      }

      //Get just the values
      let newValue = selected.map(s => s.label);
      let field = this.props.field;
      if (this.props.reset) {
        this.props.parentComponent.setState({
          [field]: newValue,
          [this.props.reset]: [],
          changed: true,
        });
      } else {
        this.props.parentComponent.setState({
          [field]: newValue,
          changed: true,
        });
      }
    } catch (err) {
      console.log(`Components.FormField.FormFieldMultitext.change()-  ${err}`);
      return null;
    }
  };
  //checks if the field is disabled based on if any lockDependentVariables is set
  checkDisabled = () => {
    try {
      //if any of the lockDependentVariables is set, then the field is disabled
      let value = this.props.lockDependentVariables
        .map(variable => this.props.parentComponent.state[variable].length)
        .reduce((a, b) => {
          return a + b;
        }, 0);

      if (value) return true;
      return false;
    } catch (err) {
      console.log(
        `Components.FormField.FormFieldMultitext.checkDisabled()-  ${err}`
      );
      return false;
    }
  };
  componentDidUpdate = prevProps => {
    try {
      if (prevProps !== this.props) {
        //check if the field should be disabled
        if (this.props.lockDependentVariables) {
          let val = this.checkDisabled();
          if (val) {
            this.setState({ curPlaceHolder: "Locked" });
          } else this.setState({ curPlaceHolder: this.props.placeholder });
          this.setState({ disabled: val });
        }
      }
    } catch (err) {
      console.log(
        `Components.FormField.FormFieldMultitext.componentDidUpdate()-  ${err}`
      );
      return null;
    }
  };

  render() {
    let defaultValues = [];

    if (this.props.parentComponent.state[this.props.field]) {
      this.props.parentComponent.state[this.props.field].forEach(
        chosenValue => {
          let options = this.props.options;
          if (options) {
            options.forEach(option => {
              if (chosenValue === option.label) {
                defaultValues.push(option);
              }
            });
          } else {
            console.log(
              `Components.FormField.FormFieldMultitext.render()- No options for ${this.props.field}`
            );
          }
        }
      );
    } else {
      console.log(
        `Components.FormField.FormFieldMultitext.render()- Cannot find ${this.props.field} on parent component`
      );
    }

    return (
      <Form.Group className="form-field-multitext">
        <Form.Label>{this.props.title}</Form.Label>
        <Select
          id={`${this.props.title.replaceAll(" ", "_")}-select`}
          defaultValue={defaultValues}
          isMulti
          placeholder={this.state.curPlaceHolder}
          closeMenuOnSelect={false}
          className="basic-multi-select"
          classNamePrefix="select"
          options={this.props.options}
          styles={reactSelectStyles}
          onChange={this.change}
          value={defaultValues}
          isDisabled={this.state.disabled}
        />
      </Form.Group>
    );
  }
}

export default FormFieldMultiText;
