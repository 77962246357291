import React from "react";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";

export function copyToClipboard(input) {
  //navigator only accessbile if https
  if (navigator.clipboard && window.isSecureContext) {
    try {
      navigator.clipboard.writeText(input);
      toast.success(`Copied ${input}!`);
    } catch (err) {
      console.log(err);
      toast.error(`Failed to add to clipboard!`);
    }

    //Fall back if clipboard not available
  } else {
    const textarea = document.createElement("textarea");
    textarea.value = input;

    // Move the textarea outside the viewport to make it invisible
    textarea.style.position = "absolute";
    textarea.style.left = "-99999999px";

    document.body.prepend(textarea);

    // highlight the content of the textarea element
    textarea.select();

    try {
      //Note this is depericated but there is no alternative and this should
      //realistically only run on dev.
      document.execCommand("copy");
      toast.success(`Copied ${input}!`);
    } catch (err) {
      console.log(err);
      toast.error(`Failed to add to clipboard!`);
    } finally {
      textarea.remove();
    }
  }
}

export default function CopyButton({ copy_text, size }) {
  return (
    <MdContentCopy
      size={size ? size : 25}
      className="Icon ml-1"
      onClick={() => copyToClipboard(copy_text)}
    />
  );
}
