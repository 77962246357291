import React, { useState } from "react";
import { Button, Form, Nav } from "react-bootstrap";
import api from "../../apis/selectree_api";
import TextInput from "../FormField/TextInput.js";

function AccountVerify(props) {
  const { handleForm } = props;

  const [email, updateEmail] = useState("");
  const [emailSent, updateEmailSent] = useState(false);

  async function submitForm(e) {
    e.preventDefault();
    try {
      console.log("Submitting form!");
      let res = await api.post(`/users/resend-verification`, {
        email: email,
      });

      if (res) {
        updateEmailSent(res.data);
        e.stopPropagation();
      } else {
        console.log(res);
        updateEmailSent("There was an error processing this request");
        e.stopPropagation();
      }
    } catch (err) {
      console.log(
        `Error in client.components.login.ForgotPassword.submitForm: ${err}`
      );
    }
  }

  return (
    <div>
      <h2>Account Verification</h2>
      <p>
        Please enter your email. You will recieve a verification email that will
        expire in 5 minutes if your account needs verification.
      </p>

      <Form onSubmit={submitForm}>
        <TextInput
          label={"Your Email"}
          name="forgotten_password_email"
          value={email}
          onChange={(name, v) => updateEmail(v)}
          id="forgotten-password-email-form"
        />
        <Button type="submit" style={{ marginBottom: "0.5rem" }}>
          Send Email
        </Button>

        {emailSent ? <div>{emailSent}</div> : ""}

        <Nav className="nav-margin">
          <Nav.Link
            className="nav-length"
            style={{ padding: "0.0rem" }}
            onClick={() => handleForm("toggleSide", "login")}
          >
            Back to Login
          </Nav.Link>
          <Nav.Link
            className="nav-length"
            style={{ padding: "0.0rem" }}
            onClick={() => handleForm("toggleSide", "password_recovery")}
          >
            Forgot your password?
          </Nav.Link>
          <Nav.Link
            className="nav-length"
            style={{ padding: "0.0rem" }}
            onClick={() => handleForm("toggleSide", "signup")}
          >
            Need to create an account?
          </Nav.Link>
        </Nav>
      </Form>
    </div>
  );
}

export default AccountVerify;
