import React from "react";
import { connect } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";

import Taxons from "./EditTaxon/Taxons";

import FormFieldPlainText from "../FormField/FormFieldPlainText";
import FormFieldMultiText from "../FormField/FormFieldMultiText";
import FormFieldMultiPrimaries from "../FormField/FormFieldMultiPrimaries";
import { optionsReturnForForm } from "../../utilities/searchChar";

class NameInfo extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  optionsFor = field => {
    try {
      if (
        this.props.searchCharacteristics &&
        this.props.searchCharacteristics[field]
      ) {
        return optionsReturnForForm(this.props.searchCharacteristics[field]);
      } else {
        return [];
      }
    } catch (err) {
      console.log(`components.TreeFormCard.NameInfo.optionsFor: ${err}`);
    }
  };

  render() {
    let parentComponent = this.props.parentComponent;
    return (
      <Container className="search-by-char-card--site-conditions">
        <Row>
          <Col lg={8}>
            <Taxons parentComponent={parentComponent} />
          </Col>

          <Col lg={4}>
            <FormFieldPlainText
              title="Family Name"
              field="family"
              parentComponent={parentComponent}
            />

            <FormFieldMultiPrimaries
              title="Primary Common Name"
              subtitle="Alternative Common Name(s)"
              field="common_name"
              parentComponent={parentComponent}
            />

            <FormFieldMultiText
              title="Generic Common Name(s)"
              field="generic"
              options={this.optionsFor("genericCommonNames")}
              parentComponent={parentComponent}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { searchCharacteristics: state.searchCharacteristics.data };
};
export default connect(mapStateToProps, {})(NameInfo);
