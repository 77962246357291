import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import { Pagination } from "react-bootstrap";

export default function ResultPagination(props) {
  const {
    defaultPage,
    visiblePages,
    numberOfResults,
    resultsPerPage,
    onPageChange,
  } = props;
  const [activePage, setActivePage] = useState(1);

  const endPage = Math.ceil(numberOfResults / resultsPerPage);

  useEffect(() => {
    setActivePage(defaultPage);
  }, [defaultPage]);

  const LEFT_END = activePage < visiblePages;
  const RIGHT_END = !LEFT_END && activePage > endPage - visiblePages;
  const STATIC = endPage <= visiblePages;

  let start = activePage;
  let end = endPage;

  if (STATIC) start = 1;
  else if (LEFT_END) {
    start = 1;
    end = start + visiblePages - 1;
  } else if (RIGHT_END) start = 1 + endPage - visiblePages;

  function handlePageChange(page) {
    setActivePage(page);
    if (onPageChange) onPageChange(page);
  }
  /*
  let page_items = [];

  for (
    let pageNum = start;
    pageNum <= Math.min(end, start + visiblePages - 1);
    pageNum++
  ) {
    page_items.push(
      <Pagination.Item
        key={uuid()}
        active={activePage === pageNum}
        onClick={() => handlePageChange(pageNum)}
        data-page-number={pageNum}
      >
        {pageNum}
      </Pagination.Item>
    );
  }*/
  function renderPaginations() {
    let page_items = [];

    for (
      let pageNum = start;
      pageNum <= Math.min(end, start + visiblePages - 1);
      pageNum++
    ) {
      page_items.push(
        <Pagination.Item
          key={uuid()}
          active={activePage === pageNum}
          onClick={() => handlePageChange(pageNum)}
          data-page-number={pageNum}
        >
          {pageNum}
        </Pagination.Item>
      );
    }

    return page_items;
  }

  function PaginationRow() {
    return (
      <Pagination className="result-gallery-pagination mb-2">
        {!LEFT_END && !STATIC ? (
          <>
            <Pagination.First
              className="box-link"
              onClick={() => handlePageChange(1)}
              data-page-number={"first"}
            />
            <Pagination.Prev
              className="box-link"
              onClick={() => handlePageChange(activePage - 1)}
              data-page-number={"prev"}
            />
          </>
        ) : (
          ""
        )}

        {renderPaginations()}

        {!RIGHT_END && !STATIC ? (
          <>
            <Pagination.Ellipsis />
            <Pagination.Next
              className="box-link"
              onClick={() => handlePageChange(activePage + 1)}
              data-page-number={"next"}
            />
            <Pagination.Last
              className="box-link"
              onClick={() => handlePageChange(endPage)}
              data-page-number={"last"}
            />
          </>
        ) : (
          ""
        )}
      </Pagination>
    );
  }

  return (
    <div>
      {numberOfResults > resultsPerPage ? <PaginationRow /> : ""}
      {props.children}
      {numberOfResults > resultsPerPage && props.children ? (
        <PaginationRow />
      ) : (
        ""
      )}
    </div>
  );
}
