import React, { useEffect, useRef, useState } from "react";
import { Container, NavDropdown } from "react-bootstrap";
import { useParams } from "react-router";
import ListViewer from "../ListViewer/ListViewer";
import ActionDropdown, {
  CopyAction,
  DownloadAction,
} from "../ActionDropdown/ActionDropdown";
import {
  LIST_ADMIN,
  LIST_COLLABORATOR,
  LIST_FOLLOWER,
  SAVED_SEARCH,
  MONTHS,
} from "../../utilities/constants";
import ListCollaboratorModal from "../ListCollaboratorModal/ListCollaboratorModal";
import UpdateListModal from "../ListModals/UpdateListModal";
import { FaEdit, FaEnvelope, FaTree } from "react-icons/fa";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  addTreeThunk,
  removeTreeThunk,
  followThunk,
  getListDetail,
} from "../../actions/list";
import { toast } from "react-toastify";
import { ListPaneSkeleton } from "../LoadingSkeletons/ListViewerLoadingSkeleton";
import { EmptyPane } from "../MyListPage/MyListPage";
import "./ListDetail.css";

//TODO Make Loading Skeleton with 6 blank lists
export default function ListDetail() {
  const { list_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [follower, setFollower] = useState(null);

  const [shareModal, setShareModal] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const list = useSelector(state => state.list_detail);

  useEffect(() => {
    dispatch(getListDetail(list_id)).then(() => setLoading(false));
    setError(false);
  }, [follower, user]);

  function getDate(updateTime) {
    const date = updateTime ? new Date(updateTime) : null;

    if (date) date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

    return (
      MONTHS[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
    );
  }

  function removeTree({ list, tree }) {
    dispatch(removeTreeThunk(list.list_id, tree.tree_id)).then(res =>
      res.status < 300
        ? toast.success(`Removed ${tree.commonName} from ${list.name}`)
        : toast.error(`Failed to remove ${tree.commonName} from ${list.name}`)
    );
  }

  function addTree({ list, tree_option }) {
    dispatch(addTreeThunk([tree_option.tree_id], list.list_id)).then(res =>
      res.status < 300
        ? toast.success(`Added ${tree_option.label} to ${list.name}`)
        : toast.error(`Failed to Add ${tree_option.label} to ${list.name}`)
    );
  }

  function onListUpdate(response) {
    response.status < 300
      ? toast.success("Successfully Updated!")
      : toast.error("Update Failed!");
  }

  function checkCallerRole(l) {
    return l.caller_role === LIST_ADMIN || l.caller_role === LIST_COLLABORATOR;
  }

  function FollowAction(l) {
    async function follow() {
      dispatch(followThunk(true, list_id)).then(response => {
        response.status
          ? toast.success("Successfully Followed!")
          : toast.error("Follow Failed!");
      });
    }
    async function unfollow() {
      dispatch(followThunk(false, list_id)).then(response => {
        response.status
          ? toast.success("Successfully Unfollowed!")
          : toast.error("Unfollow Failed!");
      });
    }

    return l.caller_role === LIST_FOLLOWER ? (
      <IoMdHeart
        className="Icon"
        size={30}
        onClick={e => {
          e.stopPropagation();
          unfollow();
          setFollower(false);
        }}
      />
    ) : (
      <IoMdHeartEmpty
        className="Icon"
        size={30}
        onClick={e => {
          e.stopPropagation();
          follow();
          setFollower(true);
        }}
      />
    );
  }

  //Loading View
  if (loading) return <ListPaneSkeleton />;

  //Error View
  if (error || !list) {
    return <EmptyPane msg="You have no access to this list" />;
  }

  function Actions() {
    const admin_only = checkCallerRole(list) ? (
      <div>
        <NavDropdown.Item
          className="action-item"
          onClick={() => setShareModal(true)}
        >
          <FaEnvelope className="action-icon" size={20} />
          Share
        </NavDropdown.Item>
      </div>
    ) : (
      ""
    );

    const public_only =
      list.isPublic || list.viewable_from_link ? (
        <div>
          <CopyAction
            copy_text={`${window.location.origin}/list/${list.list_id}`}
          />
        </div>
      ) : (
        ""
      );

    const always_actions = (
      <div>
        <DownloadAction
          obj_array={list.trees.map(t => ({
            tree_id: t.tree_id,
            scientificName: t.scientificName,
            commonName: t.commonName,
          }))}
          filename={list.name}
        />
      </div>
    );

    return (
      <ActionDropdown className="d-flex">
        {admin_only}
        {public_only}
        {always_actions}
      </ActionDropdown>
    );
  }

  function EmWrapper({ children }) {
    return (
      <span
        className="bg-primary text-secondary"
        style={{
          borderRadius: "0.5rem",
          padding: "0.1rem 0.5rem",
          marginRight: "0.2rem",
          marginTop: "0.5rem",
        }}
      >
        {children}
      </span>
    );
  }

  function DescriptionWrapper(props) {
    const [cover, setCover] = useState(true);
    const container = useRef();

    const toggleCover = () => {
      if (!cover) container.current.style.height = null;
      setCover(!cover);
    };
    const paragraph = <p className="align-text-left">{props.text}</p>;

    return props.text && props.text.length > props.charMin ? (
      <div style={{ position: "relative", marginBottom: "0.5rem" }}>
        <div
          ref={container}
          className={cover ? "list-description-container" : ""}
        >
          {cover ? (
            <div>
              <div
                className="white-transparent-grad"
                style={{ position: "absolute", height: "10rem", width: "100%" }}
              />
            </div>
          ) : (
            ""
          )}
          {paragraph}
        </div>
        <div onClick={toggleCover} className="list-description-toggle">
          <div className="list-description-toggle-text">
            {cover ? "See More" : "Hide"}
          </div>
        </div>
      </div>
    ) : (
      paragraph
    );
  }

  return (
    <Container className="text-left mt-4 mb-4">
      <h3 className="d-flex">
        <b>{list.name.toUpperCase()}</b>
        <span
          style={{
            width: "auto",
            marginLeft: "auto",
            alignSelf: "center",
            fontSize: "1.25rem",
          }}
        >
          {checkCallerRole(list) ? (
            <UpdateListModal
              list={list}
              title={`UPDATE: ${list.name}`}
              onUpdate={onListUpdate}
            >
              <FaEdit size={30} className="Icon" />
            </UpdateListModal>
          ) : Object.keys(user ? user : {}).length !== 0 ? (
            FollowAction(list)
          ) : (
            ""
          )}
          <Actions />
        </span>
      </h3>
      <div style={{ color: "#767676" }}>
        <h6 style={{ display: "flex", flexWrap: "wrap" }}>
          <EmWrapper>{list.first_name + " " + list.last_name}</EmWrapper>
          {list.date_updated ? (
            <EmWrapper>Updated {list.date_updated}</EmWrapper>
          ) : (
            ""
          )}
          <EmWrapper>
            {`${list.trees ? list.trees.length : 0}`}
            <FaTree style={{ marginBottom: "0.2rem" }} />
          </EmWrapper>
        </h6>
        <DescriptionWrapper text={list.description} charMin={295} />
        <p className="align-text-left">
          Regions: {list.regions ? list.regions.join(", ") : ""}
        </p>
        <p className="align-text-left">
          {/*<b>TAGS:</b>{" "}*/}
          {[
            list.type,
            list.isPublic ? "Public" : "Private",
            list.keywords ? list.keywords.join(", ") : "",
          ]
            .filter(x => typeof x === "string" && x.trim().length > 0)
            .join(", ")}
        </p>
      </div>
      <ListViewer
        list={list}
        removeTree={
          checkCallerRole(list) && list.type !== SAVED_SEARCH
            ? removeTree
            : false
        }
        addTree={
          checkCallerRole(list) && list.type !== SAVED_SEARCH ? addTree : false
        }
      />

      <ListCollaboratorModal
        show={shareModal}
        onHide={() => setShareModal(false)}
        list={list}
      />
    </Container>
  );
}
