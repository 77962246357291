import React, { useState } from "react";
import { Button, Form, Spinner, Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "../../apis/selectree_api";
import TextInput from "../FormField/TextInput.js";

function SignupForm(props) {
  const { handleForm } = props;
  const [confirmPassword, updateConfirmPassword] = useState("");
  const [error, updateError] = useState(false);
  const [success, updateSuccess] = useState(false);

  const [state, updateState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  const [loading, updateLoading] = useState(false);

  const UPPER_REGEX = new RegExp(/.*[A-Z]/);
  const LOWER_REGEX = new RegExp(/.*[a-z]/);
  const NUMBER_REGEX = new RegExp(/.*[0-9]/);
  const LENGTH_REGEX = new RegExp(/.{9,}$/);
  const SPECIAL_CHAR_REGEX = new RegExp(
    /.*[-'/`~!#*$@_%+=.,^&(){}[\]|;:"<>?\\]/
  );

  const VALID_PASSWORD_REGEX = new RegExp(
    `^(?=${[
      UPPER_REGEX.source,
      LOWER_REGEX.source,
      NUMBER_REGEX.source,
      LENGTH_REGEX.source,
      SPECIAL_CHAR_REGEX.source,
    ].join(")(?=")}).*$`
  );

  const PASSPHRASE_LENGTH_REGEX = new RegExp(/.{20,}$/);

  const rules = [
    {
      label: "One Uppercase",
      pattern: UPPER_REGEX,
      error_message: "Password must include at least one uppercase character",
    },
    {
      label: "One Lowercase",
      pattern: LOWER_REGEX,
      error_message: "Password must include at least one lowercase character",
    },
    {
      label: "One Number",
      pattern: NUMBER_REGEX,
      error_message: "Passwords must include at least one number",
    },
    {
      label: "One Special Character",
      pattern: SPECIAL_CHAR_REGEX,
      error_message: "Passwords must include at least one special character",
    },
    {
      label: "Min 9 Characters",
      pattern: LENGTH_REGEX,
      error_message: "Passwords must have at least 9 characters",
    },
  ];

  function update(name, v) {
    updateState({
      ...state,
      [name]: v,
    });
  }

  function disableSubmit() {
    return (
      loading ||
      state.email === "" ||
      state.first_name === "" ||
      state.last_name === ""
    );
  }

  function checkPasswords() {
    let error_messages = rules.reduce((filtered, rule) => {
      if (!state.password.match(rule.pattern)) {
        filtered.push(rule.error_message);
      }
      return filtered;
    }, []);

    const passwordsMatch = state.password === confirmPassword;

    if (!passwordsMatch) {
      error_messages.push("Passwords must match");
    }

    const long_password_message = !state.password.match(PASSPHRASE_LENGTH_REGEX)
      ? "Password must be at least 20 characters long"
      : false;

    if (!long_password_message) {
      if (passwordsMatch) {
        return null;
      } else {
        return "Error: Passwords must match";
      }
    } else if (error_messages.length && long_password_message) {
      return "Error: " + error_messages[0] + " or " + long_password_message;
    } else if (error_messages.length) {
      return "Error: " + error_messages[0];
    }
    return null;
  }

  async function submitForm(e) {
    e.preventDefault();
    updateSuccess(false);
    updateError(false);
    updateLoading(true);
    try {
      console.log("Submitting form!");
      const passwordError = checkPasswords();
      if (passwordError) {
        toast.error(passwordError);
        updateError(passwordError);
        updateLoading(false);
        return false;
      }
      const first_name = state.first_name;
      const last_name = state.last_name;
      const email = state.email;
      const password = state.password;
      let res = await api.post(`/users/signup`, {
        first_name,
        last_name,
        email,
        password,
        url: window.location.origin,
      });
      console.log("Signup Success!");
      updateSuccess(
        "An email was sent to verify and enable your account. It will expire in 5 minutes"
      );
      toast.success(
        "Check your email to verify your account! It will expire in 5 minutes"
      );
      updateLoading(false);
    } catch (err) {
      updateLoading(false);
      updateError("Error: " + err.response.data);
      toast.error("Error: " + err.response.data);
      console.log(
        `Error in client.components.login.SignupForm.submitForm: ${err.response.data}`
      );
    }
  }

  let renderLoading = () => {
    return (
      <Spinner animation="border" role="status">
        <div className="sr-only">Loading...</div>
      </Spinner>
    );
  };

  return (
    <div>
      <h2>Account Creation</h2>
      <p>Please provide your email & password.</p>

      <Form onSubmit={submitForm}>
        <TextInput
          label={"First Name"}
          name="first_name"
          value={state.first_name}
          onChange={update}
          id="signup-email-form-first"
          required
        />
        <TextInput
          label={"Last Name"}
          name="last_name"
          value={state.last_name}
          onChange={update}
          id="signup-email-form-last"
          required
        />
        <TextInput
          type="email"
          label={"Email"}
          name="email"
          value={state.email}
          onChange={update}
          id="signup-email-form"
          required
        />
        <TextInput
          label={"Password"}
          name="password"
          type="password"
          value={state.password}
          onChange={update}
          id="signup-password-form"
          required
        />
        <TextInput
          label={"Confirm Password"}
          name="confirm_password"
          type="password"
          value={confirmPassword}
          onChange={(name, v) => updateConfirmPassword(v)}
          id="signup-password-form"
          required
        />
        <div
          className="user-account-edit--password-warning"
          style={{ height: "fit-content" }}
        >
          {rules.map((rule, index) => {
            const cn =
              state.password && state.password.match(rule.pattern)
                ? "valid-password-after"
                : "valid-password-before";
            return (
              <div key={index}>
                <div className={cn}>{rule.label}</div>
              </div>
            );
          })}
          <div>
            <div
              className={
                state.password === confirmPassword
                  ? "valid-password-after"
                  : "valid-password-before"
              }
            >
              Passwords Match
            </div>
          </div>
          <div style={{ paddingTop: ".5em", paddingBottom: ".5em" }}>or</div>
          <div
            className={
              state.password.match(PASSPHRASE_LENGTH_REGEX)
                ? "valid-password-after"
                : "valid-password-before"
            }
          >
            20+ Characters
          </div>
        </div>

        {success ? <div className="display-linebreak">{success}</div> : ""}

        {error ? (
          <div className="display-linebreak" style={{ color: "red" }}>
            {error}
          </div>
        ) : (
          ""
        )}
        <Button
          type="submit"
          disabled={disableSubmit()}
          style={{ marginBottom: "0.5rem" }}
        >
          {loading && renderLoading()}
          {!loading && <span> Signup</span>}
        </Button>

        <Nav className="nav-margin">
          <Nav.Link
            className="nav-length"
            onClick={() => handleForm("toggleSide", "login")}
            style={{ padding: "0.0rem" }}
          >
            Back to Login
          </Nav.Link>
          <Nav.Link
            className="nav-length"
            style={{ padding: "0.0rem" }}
            onClick={() => handleForm("toggleSide", "password_recovery")}
          >
            Forgot your password?
          </Nav.Link>
          <Nav.Link
            style={{ padding: "0.0rem" }}
            className="nav-length"
            onClick={() => handleForm("toggleSide", "verify")}
          >
            Need to verify an account?
          </Nav.Link>
        </Nav>
      </Form>
    </div>
  );
}

export default SignupForm;
