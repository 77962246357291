import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";

import { FaTrashAlt, FaPencilAlt } from "react-icons/fa";

import api from "../../../apis/selectree_api";
import { getAuthHeader } from "../../../utilities/header";

import FormFieldPlainText from "../../FormField/FormFieldPlainText";
import FormFieldText from "../../FormField/FormFieldText";

class TaxonForm extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ...props.taxon,
      open: false,
    };
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  updateTaxon = async () => {
    let taxonId = this.state.taxon_id;
    let taxonData = this.state;
    delete taxonData.taxon_id;
    delete taxonData.open;
    delete taxonData.changed;

    try {
      if (taxonId) {
        await api.put(
          `/tree/taxon/update/${taxonId}`,
          taxonData,
          getAuthHeader()
        );
        this.props.taxonComponent.setState({ forceData: true });
      } else {
        throw new Error("no id provided");
      }
    } catch (err) {
      console.log(`Error in client.actions.taxon: ${err}`);
    }
  };

  deleteTaxon = async () => {
    let taxonId = this.state.taxon_id;
    try {
      if (taxonId) {
        if (
          window.confirm(
            "Are you sure you wish to delete this taxon? There is no undoing this action."
          )
        ) {
          await api.delete(`/tree/taxon/delete/${taxonId}`, getAuthHeader());
          this.props.taxonComponent.setState({ forceData: true });
        }
      } else {
        throw new Error("no id provided");
      }
    } catch (err) {
      console.log(`Error in client.actions.taxon: ${err}`);
    }
  };

  render() {
    return (
      <>
        {this.state.open ? (
          <Row>
            <Col lg={6}>
              <FormFieldText
                title="Sequence"
                field="sequence"
                parentComponent={this}
                options={this.props.sequenceOptions}
                required={true}
              />

              <FormFieldPlainText
                title="Genus Name"
                field="genus"
                parentComponent={this}
              />

              <FormFieldPlainText
                title="Species Name"
                field="species"
                parentComponent={this}
              />

              <FormFieldPlainText
                title="Hybrid Alt Species"
                field="hybrid_alt_species"
                parentComponent={this}
              />
            </Col>

            <Col lg={6}>
              <FormFieldPlainText
                title="Subspecies"
                field="subspecies"
                parentComponent={this}
              />

              <FormFieldPlainText
                title="Variety"
                field="variety"
                parentComponent={this}
              />

              <FormFieldPlainText
                title="Cultivar"
                field="cultivar"
                parentComponent={this}
              />

              <FormFieldPlainText
                title="Hybrid X"
                field="hybrid_x"
                parentComponent={this}
              />
            </Col>

            <Col lg={12}>
              <span onClick={this.toggle} className="form-button-secondary">
                {" "}
                Cancel{" "}
              </span>
              &nbsp;&nbsp;&nbsp;
              <span onClick={this.updateTaxon} className="form-button-primary">
                {" "}
                Save{" "}
              </span>
            </Col>
          </Row>
        ) : (
          <div>
            {this.state.sequence}.&nbsp;
            <span
              dangerouslySetInnerHTML={{ __html: this.state.name_concat }}
            />
            <span onClick={this.toggle} className="taxon-edit-button">
              <FaPencilAlt />
            </span>
            {this.props.sequenceOptions?.length > 1 ? (
              <span onClick={this.deleteTaxon} className="taxon-edit-button">
                <FaTrashAlt />
              </span>
            ) : (
              ""
            )}
          </div>
        )}
        <hr />
      </>
    );
  }
}

const mapStateToProps = state => {
  return { searchCharacteristics: state.searchCharacteristics.data };
};
export default connect(mapStateToProps, {})(TaxonForm);
