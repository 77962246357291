export default (state = [], action) => {
  let response = [];

  switch (action.type) {
    case "SET_USER_LISTS":
      return action.payload;
    case "UPDATE_USER_LISTS":
      for (let i = 0; i < state.length; i++) {
        response.push(state[i]);
        if (state[i].list_id === action.payload.list_id) {
          response[i] = action.payload;
        }
      }
      return response;
    case "UPDATE_ONE_USER_LIST":
      const index = state.findIndex(l => l.list_id === action.payload.list_id);
      if (index > -1) {
        state[index] = { ...state[index], ...action.payload };
      }
      return state;
    case "DELETE_USER_LIST":
      for (let i = 0; i < state.length; i++) {
        if (state[i].list_id !== action.payload) {
          response.push(state[i]);
        }
      }
      return response;
    case "ADD_USER_LIST":
      response = [action.payload, ...state];
      return response;
    default:
      return state;
  }
};
