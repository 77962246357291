import api from "../apis/selectree_api";

export const getFeaturedTree = async region => {
  try {
    const response = await api.get(`/tree/featured-tree/${region}`);
    return response;
  } catch (err) {
    console.log(`Error in client.actions.featuredTree: ${err}`);
  }
};
