import React from "react";

export default [
  {
    name: "Tree Height",
    text: (
      <div>
        <p>
          Height is the maximum height (in feet) to which a tree may potentially
          grow. Many variables influence the actual final height of a tree.
          References sometimes vary considerably when reporting maximum tree
          height. In this case, SelecTree will report a range of potential
          maximum heights. Urban environments may inhibit the potential of a
          tree to reach the maximum height it would in a natural setting. It is
          important, though, to consider overhead restrictions before planting a
          tree.
        </p>
      </div>
    ),
  },
  {
    name: "Tree Shape",
    text: (
      <div>
        <p>
          Tree Shape identifies the generally definable shape tree canopies take
          as they mature. As with height, care and urban environments will
          provide many influencing variables. Tree shapes are defined as
          follows:
        </p>
        <dl>
          <dt>Columnar</dt>
          <dd>erect and almost parallel, resembling a column.</dd>
          <dt>Conical</dt>
          <dd>
            oval at the base, elongated and tapering to a narrower width at the
            top.
          </dd>
          <dt>Fan Palm</dt>
          <dd>
            fan shaped leaves with venation of the leaves extending like the
            ribs of a fan.
          </dd>
          <dt>Feather Palm</dt>
          <dd>
            palm frond resembling a feather with leaflets growing outward from a
            long central stem.
          </dd>
          <dt>Oval</dt>
          <dd>appearing elliptical, resembling an egg.</dd>
          <dt>Rounded</dt>
          <dd>ball-like or circular.</dd>
          <dt>Sword Palm</dt>
          <dd>palm fronds which are undivided and upright, bladelike.</dd>
          <dt>Umbrella</dt>
          <dd>branches extending outward and down, as an umbrella does.</dd>
          <dt>Vase</dt>
          <dd>a narrow base, widening and arching outward towards the top.</dd>
        </dl>
        <p>
          <img
            src="/images/tree-characteristics-glossary/araucaria-columnaris-tree-5.jpg"
            alt="Araucaria columnaris"
            title="Araucaria columnaris"
          />
          <img
            src="/images/tree-characteristics-glossary/acer-palmatum-tree-3.jpg"
            alt="Acer palmatum"
            title="Acer palmatum"
          />
          <img
            src="/images/tree-characteristics-glossary/cocos-nucifera-trees.jpg"
            alt="Cocos nucifera"
            title="Cocos nucifera"
          />
        </p>
      </div>
    ),
  },
  {
    name: "Foliage Type",
    text: (
      <div>
        <p>
          Foliage Type refers to whether a tree retains or drops its foliage
          when they are no longer needed for energy production. Foliage Type is
          defined as follows:
        </p>
        <dl>
          <dt>Deciduous</dt>
          <dd>trees lose their leaves completely every winter.</dd>
          <dt>Evergreen</dt>
          <dd>
            trees never lose all of their leaves/needles at one time, but
            generally have specific periods of leaf drop. They remain green
            throughout the winter.
          </dd>
          <dt>Partial Deciduous</dt>
          <dd>
            trees will lose a majority of their leaves during fall, but will
            retain a lower density of leaves during dormancy.
          </dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Fall Color",
    text: (
      <div>
        <p>
          refers to the distinct foliage color that occurs on the upper side of
          the mature fall leaf. Fall foliage is often an aesthetic consideration
          when choosing a landscape tree because of the seasonal value and
          beauty it can add to an area. Weather, soil type, and cultural
          practices can influence reliability and intensity of fall foliage
          color in some trees. Select a color term which comes closest to the
          one you desire, if it is not already listed.
        </p>
        <p>
          <img
            src="/images/tree-characteristics-glossary/acer-palmatum-ornatum-leaves-fall.jpg"
            alt="Acer palmatum ornatum"
            title="Acer palmatum ornatum"
          />
          <img
            src="/images/tree-characteristics-glossary/liquidambar-styraciflua-canopy-fall.jpg"
            alt="Liquidambar styraciflua"
            title="Liquidambar styraciflua"
          />
          <img
            src="/images/tree-characteristics-glossary/ginkgo-biloba-tree-fall-2.jpg"
            alt="Ginkgo biloba"
            title="Ginkgo biloba"
          />
        </p>
      </div>
    ),
  },
  {
    name: "Leaf Form",
    text: (
      <div>
        <p>
          Leaves can be simple or compound, depending on how their blade is
          divided (into leaflets or not).
        </p>

        <dl>
          <dt>Simple leaf</dt>
          <dd>A leaf with an undivided blade</dd>
          <dt>Compound leaf</dt>
          <dd>A leaf where the blade is divided, forming leaflets.</dd>
          <dt>Palmately compound leaf</dt>
          <dd>
            A leaf that has its leaflets radiating outwards from the end of the
            petiole or leaf stalk.
          </dd>
          <dt>Pinnately compound leaf</dt>
          <dd>
            A leaf where the leaflets are arranged along the middle vein,
            sometimes the primary leaflets are divided again into secondary
            leaflets, or a bipinnately compound leaf.
          </dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Leaf Arrangement",
    text: (
      <div>
        The arrangement of leaves on an axis or stem; the number of leaf
        attached at each position on the stem. 1=alternate, 2=opposite, 3 or
        more=whorled.
      </div>
    ),
  },
  {
    name: "CA Native",
    text: (
      <div>
        A species that was in California before the first European settlements
        at the end of the 18th century.
      </div>
    ),
  },
  {
    name: "Flower Color",
    text: (
      <div>
        <p>
          Flower Color attempts to select the predominant color appearance of
          the flowers, but not the slight variations which occasionally occur.
          Typically, the colors are on petals, but occasionally the stamens are
          the more visible color elements. As with color of foliage and fruit,
          flower color can help the homeowner assess the tree's ornamental
          value. Select a color term which comes closest to the one you desire,
          if it is not already listed.
        </p>

        <p>
          <img
            src="/images/tree-characteristics-glossary/bauhinia-variegata-flower-close-up.jpg"
            alt="Bauhinia variegata"
            title="Bauhinia variegata"
          />
          <img
            src="/images/tree-characteristics-glossary/fremontodendron-californicum-pacific-sunset-flower.jpg"
            alt="Fremontodendron californicum"
            title="Fremontodendron californicum"
          />
          <img
            src="/images/tree-characteristics-glossary/eucalyptus-sideroxylon-flowers.jpg"
            alt="Eucalyptus sideroxylon"
            title="Eucalyptus sideroxylon"
          />
        </p>
      </div>
    ),
  },
  {
    name: "Fruit Type",
    text: (
      <div>
        <p>Fruit Type is reported as follows:</p>
        <dl>
          <dt>Achene</dt>
          <dd>
            a small, dry, thin-walled fruit that does not split open when ripe.
          </dd>
          <dt>Acorn</dt>
          <dd>
            a nut partially surrounded by a fibrous or woddy cap (involucre)
            (oak).
          </dd>
          <dt>Aril</dt>
          <dd>
            an outer covering or appendage surrounding the seed, arising at or
            near the point at which it is attached to the branch (yew).
          </dd>
          <dt>Berry</dt>
          <dd>
            aggregate fleshy fruit having few or many seeds, but not a single
            stone (mulberry).
          </dd>
          <dt>Capsule</dt>
          <dd>
            fruit that contains two or more seeds, and that dries and splits
            open not necessarily along any existing ridge or seam.
          </dd>
          <dt>Cone</dt>
          <dd>
            a conical structure consisting of stiff, overlapping, woody scales
            between which are the seeds (pines, hemlock).
          </dd>
          <dt>Drupe</dt>
          <dd>
            fleshy fruit having a single hard stone (cherry, olive, peach).
          </dd>
          <dt>Follicle</dt>
          <dd>
            a single chambered fruit that splits along only one seam to release
            its seeds (catalpa).
          </dd>
          <dt>Hesperidium</dt>
          <dd>
            a form of berry having a thickened, leathery rind and juicy pulp
            divided into sections (orange).
          </dd>
          <dt>Husk/Nut</dt>
          <dd>having a membranous or green outer envelope (walnut).</dd>
          <dt>Nutlike</dt>
          <dd>
            like a nut, but being a dry, single seeded fruit, which doesn't shed
            its seed when ripe. The term is often used loosely of any hard
            fruit.
          </dd>
          <dt>Pod</dt>
          <dd>
            a fruit that contains many seeds, and that usually dries and splits
            open at the seam of its pod-like protective coating (honey locust).
          </dd>
          <dt>Pome</dt>
          <dd>fleshy fruit having seeds, but no stone (apple, pear).</dd>
          <dt>Winged seed/Samara</dt>
          <dd>
            having wing-like structures on the exterior of the seed by which the
            air might lift the seed for dispersal (maples).
          </dd>
        </dl>

        <p>
          <img
            src="/images/tree-characteristics-glossary/acer-campestre-leaves-and-fruit.jpg"
            alt="Acer campestre"
            title="Acer campestre - winged seed"
          />
          <img
            src="/images/tree-characteristics-glossary/syzygium-smithii-fruit.jpg"
            alt="Syzygium smithii"
            title="Syzygium smithii"
          />
          <img
            src="/images/tree-characteristics-glossary/pinus-densiflora-cones.jpg"
            alt="Pinus densiflora"
            title="Pinus densiflora"
          />
        </p>
      </div>
    ),
  },
  {
    name: "Fragrance",
    text: (
      <div>
        <p>
          Fragrance can act as an asset or a disadvantage. Some people are
          allergic to pollen which they associate with flower fragrance.
          However, some flowers may be fragrant but not allergenic. Other
          individuals may decidedly want pleasant smelling plants and enjoy the
          wildlife they attract. Fragrance can derive from all parts of the
          tree, flowers being the most typically fragrant. Some trees are not
          actually fragrant until their bark, leaves or roots are crushed. Only
          those plant parts which emit their fragrance freely into the air, and
          would therefor be a direct influence on a homeowner's property, are
          listed here. Some trees have unpleasant smelling pollen, and these
          foul or unpleasant odors are also noted.
        </p>
      </div>
    ),
  },
  {
    name: "Sunset Climate Zone",
    text: (
      <div>
        <p>
          Sunset's Graden Climate Zones are a key component in selecting the
          right tree.{" "}
          <a
            href="http://www.sunset.com/garden/climate-zones"
            target="_blank"
            rel="noopener noreferrer"
          >
            Determine what zone you live in{" "}
            <span
              class="glyphicon glyphicon-new-window"
              aria-hidden="true"
            ></span>
          </a>
          .{" "}
          <a
            href="http://www.sunset.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit Sunset's website{" "}
            <span
              class="glyphicon glyphicon-new-window"
              aria-hidden="true"
            ></span>
          </a>
        </p>
        <p>
          Sunset's Garden Climate Zone system is based on a combination of six
          factors. They are:
        </p>
        <ul>
          <li>Latitude</li>
          <li>Elevation</li>
          <li>Influence of the Pacific Ocean</li>
          <li>Influence of the continental air mass</li>
          <li>Mountains and hills</li>
          <li>Local terrain</li>
        </ul>
        <p>
          Although there are 24 climate zones represented in the west,
          California does not have zones 5,6,or 12. Note: SelecTree doesn't
          provide for the zone subdivisions (a and b).
        </p>
      </div>
    ),
  },
  {
    name: "USDA Hardiness Zone",
    text: (
      <div>
        <p>
          USDA Hardiness Zones 4 - 11 are found in California. A zone is an area
          based on the average annual minimum winter temperature, divided into
          10-degree °F zones. Zone designations help determine which plants are
          most likely to thrive at a given location. SelecTree does not include
          the further differentiated sub-zones (a, b).
        </p>
        <dl class="dl-horizontal">
          <dt>Zone 1</dt>
          <dd>below -50 °F</dd>
          <dt>Zone 2</dt>
          <dd>-50 to -40 °F</dd>
          <dt>Zone 3</dt>
          <dd>-40 to -30 °F</dd>
          <dt>Zone 4</dt>
          <dd>-30 to -20 °F</dd>
          <dt>Zone 5</dt>
          <dd>-20 to -10 °F</dd>
          <dt>Zone 6</dt>
          <dd>-10 to 0 °F</dd>
          <dt>Zone 7</dt>
          <dd>0 to 10 °F</dd>
          <dt>Zone 8</dt>
          <dd>10 to 20 °F</dd>
          <dt>Zone 9</dt>
          <dd>20 to 30 °F</dd>
          <dt>Zone 10</dt>
          <dd>30 to 40 °F</dd>
          <dt>Zone 11</dt>
          <dd>above 40 °F</dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Water Use Rating",
    text: (
      <div>
        The amount of water necessary for a tree to grow in a healthy way.
      </div>
    ),
  },
  {
    name: "Available Planting Area",
    text: <div>The area available for root growth of a tree</div>,
  },
  {
    name: "Sun Exposure",
    text: (
      <div>
        <p>Exposure is reported as follows:</p>
        <dl class="dl-horizontal">
          <dt>Sun</dt>
          <dd>6 or more hours of direct sunlight per day</dd>
          <dt>Shade</dt>
          <dd>Less than 2 hours of direct sunlight per day</dd>
          <dt>Filtered</dt>
          <dd>2 to 6 hours of filtered sunlight per day</dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Deer Resistant",
    text: (
      <div>
        <p>'Yes' means the tree is neither edible nor appealing to deer.</p>
      </div>
    ),
  },
  {
    name: "Salt Tolerant",
    text: (
      <div>
        <p>
          Salinity Tolerance ratings reflect soil salinity in two geographic
          areas (coastal or inland). The terms 'Good' and 'Moderate' are used to
          qualify each of the ratings in this category. Trees that do well in
          saline conditions qualify as 'Good', while trees that don't do as well
          qualify as 'Moderate'.
        </p>
      </div>
    ),
  },
  {
    name: "Utility Precautions",
    text: (
      <div>
        <p>
          {/* <img src="images/icons/util_square_ico.png" alt="Utility Friendly Tree" />
                &nbsp;Trees labeled with this icon are safe for planting near residential power lines.  
                For further information see <a href="/right-tree-right-place/utility-precautions">Utility Precautions</a>.  */}
          Power line friendly trees have a maximum height of 25 feet or less.
          Additional information is available at{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://ufei.calpoly.edu/right-tree-right-place-utility-precautions/"
          >
            Right Tree Right Place - Utility Precautions
          </a>
          . Please contact your local utility to request an arborist for a site
          consultation.
        </p>
      </div>
    ),
  },
  {
    name: "Taxonomy",
    text: (
      <div>
        <p>
          Botanical names (Family, Genus and Species) are the Latin nomenclature
          for a plant by which it is identified. Using botanical names ensures
          the acquisition of the exact plant desired, because common names of
          plants are not consistent from place to place. The botanical name
          consists of three parts, family, genus and the species. Family and
          genus are always first (respectively), with the first letter
          capitalized. The species name comes next, and is usually in lower
          case. Helpful references are listed here.
        </p>
        <ul>
          <li>
            <a
              href="http://www.theplantlist.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Plant List{" "}
              <span
                class="glyphicon glyphicon-new-window"
                aria-hidden="true"
              ></span>
            </a>
          </li>
          <li>
            <a
              href="http://ucjeps.berkeley.edu/interchange/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jepson Interchange Parallel Treamtment to TJM2{" "}
              <span
                class="glyphicon glyphicon-new-window"
                aria-hidden="true"
              ></span>
            </a>{" "}
            (Use for all California natives)
          </li>
          <li>
            <a
              href="http://www.efloras.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              eFloras.org{" "}
              <span
                class="glyphicon glyphicon-new-window"
                aria-hidden="true"
              ></span>
            </a>
          </li>
          <li>
            <a
              href="http://www.tropicos.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tropicos{" "}
              <span
                class="glyphicon glyphicon-new-window"
                aria-hidden="true"
              ></span>
            </a>
          </li>
        </ul>
        <p>
          Cultivars are horticulturally or agriculturally derived varieties of a
          plant, and they are usually cultivated for specific characteristics
          such as color, lack of or production of fruit, or unique foliage
          characteristics.
        </p>
        <p>
          Common names of plants vary tremendously from place to place, and are
          not a reliable identifying feature. Common names are usually of local
          value, because they derive from laymen gardners who create names based
          on some visible characteristic, or reference to a local individual.
        </p>
      </div>
    ),
  },

  {
    name: "Armament",
    text: (
      <div>
        <p>
          These trees include those with thorns, spines or other sharp plant
          parts. This feature is not always a disadvantage since these trees can
          intentionally be planted as a barrier.
        </p>
        <p>
          <img
            src="/images/tree-characteristics-glossary/ceiba-insignis-armor.jpg"
            alt="Ceiba insignis"
            title="Ceiba insignis"
          />
          <img
            src="/images/tree-characteristics-glossary/cercidium-floridum-floridum-thorns.jpg"
            alt="Cercidium floridum subsp floridum"
            title="Cercidium floridum subsp floridum"
          />
          <img
            src="/images/tree-characteristics-glossary/crataegus-phaenopyrum-thorns.jpg"
            alt="Crataegus phaenopyrum"
            title="Crataegus phaenopyrum"
          />
        </p>
      </div>
    ),
  },

  {
    name: "Bark Color",
    text: (
      <div>
        <p>
          Bark Color is not often recognized as an added feature of the tree,
          but color variation on twigs and trunk is as extensive as that of
          leaves. Bark color may be very variable as the tree matures, and can
          be an interesting winter feature. It can also beautifully contrast or
          compliment foliage during the growing season. The mature color is what
          is identified here, as observed on the trunk of the tree. Some trees
          have multicolored bark, and are identified as 'multicolored', as well
          as with the other color selections. 'Striking' has been included as an
          option if the tree's bark is unusually good looking. Select a color
          term which comes closest to the one you desire, if it is not already
          listed.
        </p>
        <p>
          <img
            src="/images/tree-characteristics-glossary/acer-davidii-bark.jpg"
            alt="Acer davidii"
            title="Acer davidii"
          />
          <img
            src="/images/tree-characteristics-glossary/acer-griseum-bark-2.jpg"
            alt="Acer griseum"
            title="Acer griseum"
          />
          <img
            src="/images/tree-characteristics-glossary/eucalyptus-camaldulensis-bark-2.jpg"
            alt="Eucalyptus camaldulensis"
            title="Eucalyptus camaldulensis"
          />
        </p>
      </div>
    ),
  },
  {
    name: "Bark Texture",
    text: (
      <div>
        <p>
          Bark Texture of a tree may vary depending upon the age of the tree and
          the location on the tree where the bark is observed. In SelecTree, it
          is associated with mature trees, observed on the trunk of the tree.
          Bark Texture is reported as follows:
        </p>
        <dl>
          <dt>Blocky</dt>
          <dd>
            Having sections which project in distinctive block-like shapes
            (pear, flowering dogwood).
          </dd>
          <dt>Exfoliating</dt>
          <dd>
            Tending to peel away in layers, and eventually fall to the ground
            (paperbark maple, common horsechestnut).
          </dd>
          <dt>Fibrous</dt>
          <dd>Having loose thread-like fibers (bottlebrush, date palm).</dd>
          <dt>Furrowed/Fissured</dt>
          <dd>
            Having grooves, cracks, splits or narrow depressions, opposite of
            'ridged' (noble fir, white fir, Italian cypress, Japanese maple).
          </dd>
          <dt>Ridged</dt>
          <dd>Having raised lines or ridges, opposite of 'furrowed'.</dd>
          <dt>Rough</dt>
          <dd>
            Having a bumpy, uneven surface, not necessarily following any
            pattern or structure (Norfolk Island pine, lily magnolia).
          </dd>
          <dt>Scaly/Flaky/Platy</dt>
          <dd>
            Part or wholly covered with scales or thin plates (Chinese elm,
            sycamore).
          </dd>
          <dt>Smooth</dt>
          <dd>
            Having an even consistency, without irregularities or projections
            (red alder, lemon).
          </dd>
          <dt>Spiny</dt>
          <dd>
            Covered with spines, thorns or other stiff projections (sawleaf
            zelkova, floss silk tree).
          </dd>
          <dt>Striated</dt>
          <dd>
            Marked with shallow stripes (weeping bottlebrush, sour cherry).
          </dd>
        </dl>
        <p>
          <img
            src="/images/tree-characteristics-glossary/diospyros-virginiana-bark.jpg"
            alt="Diospyros virginiana"
            title="Diospyros virginiana"
          />
          <img
            src="/images/tree-characteristics-glossary/betula-nigra-heritage-bark.jpg"
            alt="Betula nigra heritage"
            title="Betula nigra heritage"
          />
          <img
            src="/images/tree-characteristics-glossary/corymbia-citriodora-bark.jpg"
            alt="Corymbia citriodora"
            title="Corymbia citriodora"
          />
        </p>
      </div>
    ),
  },

  {
    name: "Biogenic Emissions",
    text: (
      <div>
        {/* <p>(WARNING! Only 234 trees have this information. Using this category in an attribute search will limit matching trees considerably.)</p> */}
        <p>
          Biogenic Emissions estimates volatile organic compound (VOC) emissions
          from vegetation and is an important consideration when large-scale
          tree plantings occur. This is especially important if air quality in
          the area is already poor.
          {/* The information contained in SelecTree is taken directly (with permission) from the paper Low-Emitting Urban Forests: A Taxonomic Methodology for Assigning Isoprene and
            Monoterpene Emission Rates, by Michael T. Benjamin, Mark Sudol, Laura Bloch and Arthur M. Winer. Atmospheric Environment. Vol.30, No.9, pp.1437-1452, 1996.*/}
        </p>
        <p>
          Trees are classified as 'Low', 'Moderate', or 'High', based on "the
          sum of the hourly emission rates of isoprene and monoterpenes,
          expressed as microgram emissions per gram dry leaf weight per hour",
          as follows:
        </p>
        <dl class="dl-horizontal">
          <dt>Low</dt>
          <dd>Less than 1 microgram total emissions</dd>
          <dt>Moderate</dt>
          <dd>Between 1-10 microgram total emissions</dd>
          <dt>High</dt>
          <dd>Greater than 10 microgram total emissions</dd>
        </dl>
      </div>
    ),
  },

  {
    name: "Branch Strength",
    text: (
      <div>
        <p>
          Branch Strength is controlled by the inherent strength of the wood,
          although strong winds, branching patterns, pruning practices, and
          overly abundant fruit can cause broken branches. Limb breakage can be
          a common occurrence in trees, but some are more prone to it than
          others. Trees with weak wood include those that have been commonly
          reported for limb breakage and disrupting power in the PG&amp;E
          service area (central to northern California).
        </p>
      </div>
    ),
  },

  {
    name: "Invasive",
    text: (
      <div>
        <p>
          SelecTree's invasive plant information is taken from Cal-IPC{" "}
          <a
            href="http://www.cal-ipc.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            California Invasive Plant Council{" "}
            <span
              class="glyphicon glyphicon-new-window"
              aria-hidden="true"
            ></span>
          </a>{" "}
          on August 2006 and is displayed in the tree notes or memo section of
          the tree report.
        </p>
        <p>Cal-IPC rates invasive plants as High, Moderate and Limited.</p>
        <dl>
          <dt>High</dt>
          <dd>
            Species have severe ecological impacts on physical processes, plant
            and animal communities, and vegetation structure.
          </dd>
          <dt>Moderate</dt>
          <dd>
            Species have substantial and apparent but generally not severe
            ecological impacts on physical processes, plant and animal
            communities, and vegetation structure.
          </dd>
          <dt>Limited</dt>
          <dd>
            Species are invasive but their ecological impacts are minor on a
            statewide level or there was not enough information to justify a
            higher score.
          </dd>
        </dl>
      </div>
    ),
  },
  // {
  //    REMOVED AT CLIENT REQUEST 2021-07-30 - MW
  //     name: "Density in Leaf",
  //     text: <div>
  //         <p>
  //             Density in Leaf is important in determining the amount of shade and shelter a tree may provide.
  //             Factors to consider are a tree's leaf size and shape, and branching pattern.
  //             The rating refers to the relative density when compared to other species in leaf.
  //         </p>
  //     </div>
  // },
  // {
  //    REMOVED AT CLIENT REQUEST 2021-07-30 - MW
  //     name: "Density Out of Leaf",
  //     text: <div>
  //         <p>Density Out of Leaf is important for those interested in visual screening or shelter during the winter.
  //             Also, the more warming from the sun that is desired during the winter, the less dense the out of leaf condition should be.
  //             This category refers to the relative density when compared to other species out of leaf.
  //             Besides the density of the natural branching pattern, dried fruit and other persisting features are also considered.
  //         </p>
  //     </div>
  // },
  // {
  //    REMOVED AT CLIENT REQUEST 2021-07-30 - MW
  //     name: "Desirable Wildlife Plant",
  //     text: <div>
  //         <p>
  //             Every tree could be construed to have wildlife value, but those listed here are especially useful because they provide an easy food or shelter source.
  //         </p>
  //     </div>
  // },
  {
    name: "Disease Resistance",
    text: (
      <div>
        <p>
          Disease Resistance is a genetic characteristic that determines the
          tree's ability to resist disease. Trees that are resistant to a
          disease either do not contract the disease or show little or few
          symptoms of the disease. Possessing low-level disease symptoms does
          not significantly affect the health of the tree nor its aesthetic
          qualities. Because not all trees have been tested for all diseases,
          much data is not known or documented. This field makes no claim of
          listing all diseases, or their complex types to which tree show a
          degree of resistance. Also examine the 'Disease Susceptibility' field
          for more information on diseases.
        </p>
      </div>
    ),
  },
  {
    name: "Disease Susceptibility",
    text: (
      <div>
        <p>
          Trees are susceptible to diseases. A disease can result from bacteria,
          fungi or viruses. Sometimes even the environment contributes to
          diseases: air pollution, mechanical damage, a deficiency or excess of
          nutrients, water, sunlight, or the wrong climate. Generally for a
          disease to occur, three conditions must exist. The host must be
          present; the pathogen must be present; and the environmental
          conditions conducive to the disease must be present. Because not all
          trees have been tested for all diseases, much data is not known or
          documented. This field makes no claim of listing all diseases, or
          their complex types of any particular tree. Also examine the 'Disease
          Resistant' field for more information on diseases.
        </p>
      </div>
    ),
  },
  // {
  //    REMOVED AT CLIENT REQUEST 2021-07-30 - MW
  //     name: "Fire Resistance",
  //     text: <div>
  //         <p><strong>Live in a fire risk area?</strong> Please check with your local <a href="http://www.firesafecouncil.org/" target="_blank" rel="noopener noreferrer">Fire Council <span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></a> or Fire Department before planting trees!</p>
  //         <p>(WARNING! Only 305 trees have this information. Using this category in an attribute search will limit matching trees considerably.) Fire Resistance information is taken from The UC Forest Products Laboratory, Vegetation Guide for Landscaping in High Fire Risk Areas, 1997. Current information on this resource is available at UC Forest Products Laboratory - PreFire Engineering.</p>
  //         <p>
  //             Favorable fire performance rating is based on the following characteristics:</p>

  //         <ul>
  //             <li>a low surface area to volume ratio, such as plants with thick, broad leaves as opposed to those with narrow, needle-like leaves.</li>
  //             <li>a high moisture content, as found in succulents and other plants with fleshy foliage.</li>
  //             <li>a low percentage of dead matter or debris.</li>
  //         </ul>

  //         <p>
  //             Unfavorable fire performance rating is based on the following characteristics:</p>
  //         <ul>
  //             <li>a high surface area to volume ratio.</li>
  //             <li>a low moisture content.</li>
  //             <li>a high percentage of dead matter or debris.</li>
  //         </ul>

  //         <p>
  //             Conflicting fire performance rating is based on being listed as fire resistant in some references and as fire prone in other references.</p>

  //         <p>Note: All trees are flammable under certain conditions. Care should be taken to lessen factors that contribute to their flammability and hazard. For further information on tree selection precautions, visit the <a href="/right-tree-right-place">Right Tree Right Place</a> information.</p>
  //     </div>
  // },
  {
    name: "Flower Type",
    text: (
      <div>
        <dl>
          <dt>Monoecious</dt>
          <dd>separate male and female reproductive parts on the same tree.</dd>
          <dt>Dioecious</dt>
          <dd>
            either male or female reproductive parts. Trees may be referred to
            as male or female.
          </dd>
          <dt>Perfect</dt>
          <dd>male and female reproductive parts in each flower.</dd>
        </dl>
        <p>
          <img
            src="/images/tree-characteristics-glossary/alnus-rhombifolia-flowers.jpg"
            alt="Alnus rhombifolia"
            title="Alnus rhombifolia - monoecious"
          />
          <img
            src="/images/tree-characteristics-glossary/pistacia-chinensis-flowers-male.jpg"
            alt="Pistacia chinensis"
            title="Pistacia chinensis - dioecious"
          />
          <img
            src="/images/tree-characteristics-glossary/bauhinia-x-blakeana-flower-2.jpg"
            alt="Bauhinia &times; blakeana"
            title="Bauhinia &times; blakeana - perfect"
          />
        </p>
      </div>
    ),
  },
  {
    name: "Flower Showiness",
    text: (
      <div>
        <p>
          Flower Showiness attempts to describe the value of the flower as a key
          aesthetic characteristic. 'Showy' flowers are those which are large or
          colorful enough to stand out prominantly, while 'inconspicuous'
          flowers are aften difficult to see or identify. Some genera, such as
          Cornus and Cotinus, are described here as having 'Showy' flowers when
          in actuality the flowers are very inconspicuous next to showy bracts
          or inflorescences.
        </p>
        <p>
          <img
            src="/images/tree-characteristics-glossary/cotinus-coggygria-flame-inflorescences.jpg"
            alt="Cotinus coggygria inflorescences"
            title="Cotinus coggygria inflorescences"
          />
          <img
            src="/images/tree-characteristics-glossary/acer-japonicum-leaves-and-flowers.jpg"
            alt="Acer japonicum"
            title="Acer japonicum"
          />
          <img
            src="/images/tree-characteristics-glossary/spathodea-campanulata-flower.jpg"
            alt="Spathodea campanulata"
            title="Spathodea campanulata"
          />
        </p>
      </div>
    ),
  },
  {
    name: "Flower Time",
    text: (
      <div>
        <p>
          Trees produce flowers at different times throughout the year,
          depending on the species. This field identifies which season the
          flowers will appear on the tree when growing in central and northern
          California (PG&amp;E's service area). Other variables, such as weather
          or watering schedules, can also influence flowering time.
        </p>
      </div>
    ),
  },

  {
    name: "Foliage Growth Color",
    text: (
      <div>
        <p>
          Foliage Growth Color refers to the prominent foliage color that occurs
          on the upper side of the fully expanded leaf. Weather, soil type, and
          cultural practices can influence the foliage growth color in some
          trees. Select a color term which comes closest to the one you desire,
          if it is not already listed.
        </p>
        <p>
          <img
            src="/images/tree-characteristics-glossary/erythrina-crista-galli-leaves-close-up.jpg"
            alt="Erythrina crista-galli"
            title="Erythrina_crista-galli"
          />
          <img
            src="/images/tree-characteristics-glossary/cornus-mas-variegata-leaf-close-up.jpg"
            alt="Cornus mas variegata"
            title="Cornus mas variegata"
          />
          <img
            src="/images/tree-characteristics-glossary/acacia-baileyana-leaf-close-up.jpg"
            alt="Acacia baileyana"
            title="Acacia baileyana"
          />
        </p>
      </div>
    ),
  },

  {
    name: "Foliage Shape",
    text: (
      <div>
        <p>
          Foliage Shape is varied and complex. Foliage Shape is defined as
          follows:
        </p>
        <dl>
          <dt>Bipinnately Compound</dt>
          <dd>
            opposite leaflets (like pinnately compound leaves) that are
            subdivided themselves into leaflets.
          </dd>
          <dt>Cordate</dt>
          <dd>having a heart-shaped outline.</dd>
          <dt>Deltoid</dt>
          <dd>having a triangular outline.</dd>
          <dt>Elliptic</dt>
          <dd>
            having the outline of an ellipse (longer than broad with blunt or
            round ends).
          </dd>
          <dt>Falcate</dt>
          <dd>curved and tapering to a point, sickle shaped.</dd>
          <dt>Frond</dt>
          <dd>large compound or simple leaves typical of palms or ferns.</dd>
          <dt>Holly-like</dt>
          <dd>ovate leaves with a spinose margin.</dd>
          <dt>Lanceolate</dt>
          <dd>
            four to six times as long as wide, narrow and tapering at each end.
          </dd>
          <dt>Linear</dt>
          <dd>narrow and elongated with nearly parallel sides.</dd>
          <dt>Lobed</dt>
          <dd>a leaf having deeply indented margins.</dd>
          <dt>Needle</dt>
          <dd>stiff, narrow leaves typical of conifers.</dd>
          <dt>Oblanceolate</dt>
          <dd>broader and rounded at the tip, and tapering at the base.</dd>
          <dt>Oblong</dt>
          <dd>
            three to four times longer than wide, with approximate parallel
            sides.
          </dd>
          <dt>Obovate</dt>
          <dd>
            egg shape in outline, with the narrow end attached to the stem.
          </dd>
          <dt>Oval</dt>
          <dd>broad, ellipse shape with rounded ends.</dd>
          <dt>Ovate</dt>
          <dd>
            broad and rounded at the base and tapering at the end, egg shaped.
          </dd>
          <dt>Palmate</dt>
          <dd>having lobes radiating from one point.</dd>
          <dt>Palmately Compound</dt>
          <dd>
            a leaf composed of leaflets which radiate from one central point.
          </dd>
          <dt>Pinnately Compound Odd</dt>
          <dd>
            leaflets arranged symmetrically on either side of a central petiole
            with a single terminal leaflet at the end (i.e. resulting in an odd
            number of leaflets).
          </dd>
          <dt>Pinnately Compound Even</dt>
          <dd>
            leaflets arranged symmetrically on either side of a central petiole
            with a pair of leaflets at the end (i.e. resulting in an even number
            of leaflets).
          </dd>
          <dt>Pinnatifid</dt>
          <dd>
            a simple leaf having narrow lobes or divisions not reaching the
            midrib.
          </dd>
          <dt>Rectangular</dt>
          <dd>resembling a rectangle in outline.</dd>
          <dt>Rhomboidal</dt>
          <dd>resembling a rhomboid in outline, diamond shaped.</dd>
          <dt>Round</dt>
          <dd>having a circular outline.</dd>
          <dt>Scalelike</dt>
          <dd>consisting of overlapping layers, like fish scales.</dd>
          <dt>Spatulate</dt>
          <dd>
            shaped like a spatula or spoon in outline, broad rounded apex and
            narrow base.
          </dd>
          <dt>Trifoliate</dt>
          <dd>having three leaflets attached to a central point.</dd>
          <dt>Trilobed</dt>
          <dd>a simple leaf with three lobes.</dd>
        </dl>

        <p>
          <img
            src="/images/tree-characteristics-glossary/jacaranda-mimosifolia-leaves-opposite-2.jpg"
            alt="Jacaranda mimosifolia"
            title="Jacaranda mimosifolia"
          />
          <img
            src="/images/tree-characteristics-glossary/juniperus-chinensis-leaves-and-fruit-2.jpg"
            alt="Juniperus chinensis"
            title="Juniperus chinensis"
          />
          <img
            src="/images/tree-characteristics-glossary/celtis-sinensis-leaves-and-fruit.jpg"
            alt="Celtis sinensis"
            title="Celtis sinensis"
          />
        </p>
      </div>
    ),
  },
  {
    name: "Fruit Color",
    text: (
      <div>
        <p>
          Fruit Color attempts to select the predominant color appearance of the
          fruit on the tree when viewed from a distance, but not the slight
          variations which occasionally occur. As with fruit size, relative
          quantity and color of foliage, fruit color can help the homeowner
          assess the fruit's ornamental value. Select a color term which comes
          closest to the one you desire, if it is not already listed.
        </p>
        <p>
          <img
            src="/images/tree-characteristics-glossary/celtis-australis-fruit-2.jpg"
            alt="Celtis australis"
            title="Celtis australis"
          />
          <img
            src="/images/tree-characteristics-glossary/cupaniopsis-anacardioides-fruit-and-seeds.jpg"
            alt="Cupaniopsis anacardioides"
            title="Cupaniopsis anacardioides"
          />
          <img
            src="/images/tree-characteristics-glossary/afrocarpus-falcatus-cones-close-up.jpg"
            alt="Afrocarpus falcatus"
            title="Afrocarpus falcatus"
          />
        </p>
      </div>
    ),
  },
  {
    name: "Fruit Size",
    text: (
      <div>
        <p>
          Fruit Size is the size (in inches) to which the mature fruit may
          potentially develop. Many variables influence the actual mature size
          of the fruit, and any one kind of fruit can develop into a wide range
          of sizes. The "Very Large" size class is very variable since some
          fruits may mature to eight inches or more in size. Fruit size may help
          to determine the possible amount of work to pick or clean up the
          fruit. Fruit Size is defined as follows:
        </p>
        <dl class="dl-horizontal">
          <dt>Very Small</dt>
          <dd>less than 0.25 inches</dd>
          <dt>Small</dt>
          <dd>0.25 to 0.50 inches</dd>
          <dt>Medium</dt>
          <dd>0.50 to 1.50 inches</dd>
          <dt>Large</dt>
          <dd>1.50 to 3.00 inches</dd>
          <dt>Very Large</dt>
          <dd>greater than 3.00 inches</dd>
        </dl>

        <p>
          <img
            src="/images/tree-characteristics-glossary/cinnamomum-camphora-fruit-close-up.jpg"
            alt="Cinnamomum camphora"
            title="Cinnamomum camphora"
          />
          <img
            src="/images/tree-characteristics-glossary/quercus-agrifolia-acorns.jpg"
            alt="Quercus agrifolia"
            title="Quercus agrifolia"
          />
          <img
            src="/images/tree-characteristics-glossary/araucaria-bidwillii-fruit.jpg"
            alt="Araucaria bidwillii"
            title="Araucaria bidwillii"
          />
        </p>
      </div>
    ),
  },
  {
    name: "Fruit Value",
    text: (
      <div>
        <p>Fruit Value is reported as follows:</p>
        <dl>
          <dt>Edible</dt>
          <dd>
            the fruit is safe to eat, but the preparation requirements should be
            researched, since not all fruit can be eaten fresh.
          </dd>
          <dt>Wildlife Value</dt>
          <dd>
            this is a benefit to those who wish to attract or maintain animals
            in their area. These trees should not be planted if you do not want
            to attract wildlife.
          </dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Fruiting Habit",
    text: (
      <div>
        <p>
          Fruiting Habit is the fruiting characteristic displayed by trees that
          may be desired or undesired. Fruiting Habit is defined as follows:
        </p>

        <dl>
          <dt>Fruitless</dt>
          <dd>
            no fruit is produced, or the fruit aborts before it matures. Some
            tree varieties have been specially developed to not produce fruit.
          </dd>
          <dt>Few</dt>
          <dd>a small quantity of fruit is produced.</dd>
          <dt>Prolific</dt>
          <dd>
            a great abundance of fruit is produced that may be very showy or
            edible. This may be desired if you are interested in harvesting the
            fruit for eating, but may be undesired and considered messy if not.
          </dd>
          <dt>Persistent</dt>
          <dd>fruit is retained or appears on the tree most of the year.</dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Fruiting Time",
    text: (
      <div>
        <p>
          Fruit can appear any time of year on trees depending on the species.
          Other variables, such as weather or watering schedules, can also
          influence fruiting time.
        </p>
      </div>
    ),
  },
  // {
  //    REMOVED AT CLIENT REQUEST 2021-07-30 - MW
  //     name: "Functional Form",
  //     text: <div>
  //         <p>Functional Form refers to how the can be used in the landscape based on its characteristic form. Functional form is defined as follows:</p>

  //         <dl>
  //             <dt>Low Canopy</dt>
  //             <dd>forming an overhead canopy of tree branches at a height of 20-35 feet.</dd>
  //             <dt>High Canopy</dt>
  //             <dd>forming a canopy at a height of 35-50 feet.</dd>
  //             <dt>Extensive area</dt>
  //             <dd>trees requiring large spaces to avoid unnecessary liabilities in terms of costly maintenance and creating potential hazards in the future, including damage to homes and utility services or injuries to people.</dd>
  //         </dl>

  //     </div>
  // },
  {
    name: "Growth Rate",
    text: (
      <div>
        <p>
          Growth Rate (in inches) identifies the maximum relative rate a tree
          will grow. As with height, urban environments will provide many
          influencing variables.
        </p>
        <p>Maximum Growth Rate is defined as follows:</p>
        <dl>
          <dt>Slow (12)</dt>
          <dd>1 to 12 inches per year</dd>
          <dt>Slow to Moderate (12-24)</dt>
          <dd>12 to 24 inches per year</dd>
          <dt>Moderate (24)</dt>
          <dd>24 inches per year</dd>
          <dt>Moderate to Fast (24-36)</dt>
          <dd>24 to 36 inches per year</dd>
          <dt>Fast (36)</dt>
          <dd>36 inches or more per year</dd>
        </dl>
      </div>
    ),
  },
  // {
  //    REMOVED AT CLIENT REQUEST 2021-07-30 - MW
  //     name: "Habit",
  //     text: <div>
  //         <p>Habit refers to the tree's natural growth habit. Habit is reported as follows:</p>
  //         <dl>
  //             <dt>Spreading</dt>
  //             <dd>generously sending branches out to the sides and taking up available space. This term is often used as both a form and a growth habit description, generally describing trees which are wider that they are high.</dd>
  //             <dt>Compact</dt>
  //             <dd>tending to grow tightly and close together within itself.</dd>
  //             <dt>Erect</dt>
  //             <dd>distinct upward growth, vertical configuration.</dd>
  //             <dt>Weeping</dt>
  //             <dd>long, narrow branches which tend to droop downward.</dd>
  //         </dl>
  //         <p>
  //             <img src="/images/tree-characteristics-glossary/callistemon-viminalis-tree-2.jpg" alt="Callistemon viminalis" title="Callistemon viminalis" />
  //             <img src="/images/tree-characteristics-glossary/acer-rubrum-red-sunset-tree.jpg" alt="Acer rubrum" title="Acer rubrum" />
  //             <img src="/images/tree-characteristics-glossary/acacia-covenyi-tree.jpg" alt="Acacia covenyi" title="Acacia covenyi" />
  //         </p>
  //     </div>
  // },
  {
    name: "Health Hazard",
    text: (
      <div>
        <p>
          Some plants produce substances or allergenic materials which can harm
          humans or animals who come in contact with them. The level of toxicity
          is variable in the 'poisonous' selection. In many cases the toxicity
          level may be similar to that of many common plants such as the green
          portion of carrots, the seed of nutmeg, or the seeds of an apple.
          Consult your local or county Public Health Services or Agency for
          further information on plant toxins and irritants. These are reported
          as follows:
        </p>
        <dl>
          <dt>Allergy</dt>
          <dd>
            the tree may cause an allergic reaction due to its airborne pollen
            or a chemical it extrudes onto its bark or leaves.
          </dd>
          <dt>Irritant</dt>
          <dd>
            some aspects of the tree such as plant hairs, oils or odor may cause
            irritation to the skin, eyes, nose or throat.
          </dd>
          <dt>Poisonous</dt>
          <dd>
            a substance produced by the tree can cause injury, illness or death.
          </dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Landscape Application",
    text: (
      <div>
        <p>
          Landscape Application refers to how the tree's natural characteristics
          and form can be used in the landscape. Landscape Application is
          reported as follows:
        </p>
        <dl>
          <dt>Screen</dt>
          <dd>
            tree can be used to create a visual of physical barrier 10-35 feet
            high.
          </dd>
          <dt>Riparian</dt>
          <dd>
            tree grows in riparian areas and can be used to restore or
            reestablish such areas.
          </dd>
          <dt>Buffer Strip/Median</dt>
          <dd>
            a tree suited for a median strip in a highway or a buffer strip
            around the edge of a parking lot. Most local governments regulate
            planting in these areas.
          </dd>
          <dt>Specimen</dt>
          <dd>
            a tree (such as River Birch) that is worthy for planting by itself
            and featured in the landscape due to one or several attractive
            traits such as glossy or unusual foliage, striking habit, nice bark,
            interesting multiple-trunked arrangement, attractive, long-lasting
            fruit or flower display.
          </dd>
          <dt>Container</dt>
          <dd>
            usually a small tree. Trees in containers need regular irrigation
            and fertilization and require frequent replacement.
          </dd>
          <dt>Street Tree</dt>
          <dd>
            recommended for planting along a street where there is plenty of
            soil space for root expansion.
          </dd>
          <dt>Shade Tree</dt>
          <dd>
            A tree (such as Live Oak) with a rounded, oval or spreading habit
            that usually casts significant shade.
          </dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Landscape Use",
    text: (
      <div>
        <p>
          Common Landscape Use refers to the tree's artificially encouraged
          form. Landscape Use is reported as follows:
        </p>
        <dl>
          <dt>Topiary</dt>
          <dd>
            intensive, long-term pruning to create decorative shapes, such as
            animals.
          </dd>
          <dt>Bonsai</dt>
          <dd>
            carefully trained, dwarfed plants designed to look like a tree or
            landscape in miniature. Created by wiring and pruning of branches,
            and pruning of roots.
          </dd>
          <dt>Espalier</dt>
          <dd>
            {" "}
            branches can be trained to some formal shape or pattern on a flat
            plane against a wall, or on a trellis or fence.
          </dd>
          <dt>Hedged</dt>
          <dd>can be clipped to form a solid screen or barrier.</dd>
          <dt>Pleached</dt>
          <dd>
            branches are woven together to form a 'mat', useful as a hedge or
            arbor.
          </dd>
          <dt>Pollarding</dt>
          <dd>
            a tree whose major branches are cut to short branch stubs, where
            shoots are annually pruned off completely to maintain a low growth
            form each season.
          </dd>
        </dl>
        <p>
          <img
            src="/images/tree-characteristics-glossary/ginkgo-biloba-tree-espalier-fall.jpg"
            alt="Ginkgo biloba"
            title="Ginkgo biloba espalier - winged seed"
          />
          <img
            src="/images/tree-characteristics-glossary/pinus-densiflora-tree-bonsai.jpg"
            alt="Pinus densiflora"
            title="Pinus densiflora"
          />
          <img
            src="/images/tree-characteristics-glossary/buxus-sempervirens-hedge.jpg"
            alt="Buxus sempervirens"
            title="Buxus sempervirens hedge"
          />
        </p>
      </div>
    ),
  },
  {
    name: "Litter Type",
    text: (
      <div>
        <p>
          Fruits, flowers, leaves, twigs and bark can be considered litter if
          they tend to fall with frequency, long duration and abundance. These
          plant droppings create maintenance hassles when the trees are located
          over drives, walkways, patios or planting areas which are meant to be
          kept relatively clean. Problems can include hazardous slippery or
          bumpy surfaces, staining of surfaces, and smothering of small plants
          to the point of preventing their growth. However, except for fruits
          that are sizable and/or wet, most litter is tolerable. Some litter may
          be left as mulch and contribute to the improvement of the soil. If the
          tree drops excessive amounts of any of the mentioned plant parts, it
          is noted here. The fruit type, wet or dry, is also identified.
        </p>
        <p>
          <img
            src="/images/tree-characteristics-glossary/brachychiton-acerifolius-base-litter.jpg"
            alt="Brachychiton acerifolius"
            title="Brachychiton acerifolius"
          />
          <img
            src="/images/tree-characteristics-glossary/olmediella-betschleriana-fruit-litter.jpg"
            alt="Olmediella betschleriana"
            title="Olmediella betschleriana"
          />
          <img
            src="/images/tree-characteristics-glossary/fraxinus-uhdei-majestic-beauty-litter.jpg"
            alt="Fraxinus uhdei majestic beauty"
            title="Fraxinus uhdei majestic beauty"
          />
        </p>
      </div>
    ),
  },
  {
    name: "Longevity",
    text: (
      <div>
        <p>
          Longevity is an important consideration for long-term shading,
          screening, beauty and value of a property. Short-lived trees may also
          be wonderful shade trees, and can be useful where permanence is not
          the ultimate goal. Longevity may vary depending on proper selection of
          adapted species, care the tree receives, risk of mechanical damage,
          and the presence or lack of diseases and pests. Longevity is reported
          as follows:
        </p>

        <dl class="dl-horizontal">
          <dt>Very Short</dt>
          <dd>less than 50 years</dd>
          <dt>Moderately Short</dt>
          <dd>40 to 60 years</dd>
          <dt>Average</dt>
          <dd>50 to 150 years</dd>
          <dt>Moderately Long</dt>
          <dd>100 to 175 years</dd>
          <dt>Very Long</dt>
          <dd>greater than 150 years</dd>
        </dl>
      </div>
    ),
  },
  // {
  //    REMOVED AT CLIENT REQUEST 2021-07-30 - MW
  //     name: "Names",
  //     text: <div>
  //         <p>Botanical names (Family, Genus and Species) are the Latin nomenclature for a plant by which it is identified. Using botanical names ensures the acquisition of the exact plant desired, because common names of plants are not consistent from place to place. The botanical name consists of three parts, family, genus and the species. Family and genus are always first (respectively), with the first letter capitalized. The species name comes next, and is usually in lower case. Helpful references are listed here.</p>
  //         <ul>
  //             <li><a href="http://www.theplantlist.org/" target="_blank" rel="noopener noreferrer">The Plant List <span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></a></li>
  //             <li><a href="http://ucjeps.berkeley.edu/interchange/" target="_blank" rel="noopener noreferrer">Jepson Interchange Parallel Treamtment to TJM2 <span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></a> (Use for all California natives)</li>
  //             <li><a href="http://www.efloras.org/" target="_blank" rel="noopener noreferrer">eFloras.org <span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></a></li>
  //             <li><a href="http://www.tropicos.org/" target="_blank" rel="noopener noreferrer">Tropicos <span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></a></li>
  //         </ul>
  //         <p>Cultivars are horticulturally or agriculturally derived varieties of a plant, and they are usually cultivated for specific characteristics such as color, lack of or production of fruit, or unique foliage characteristics.</p>
  //         <p>Common names of plants vary tremendously from place to place, and are not a reliable identifying feature. Common names are usually of local value, because they derive from laymen gardners who create names based on some visible characteristic, or reference to a local individual.</p>
  //     </div>
  // },
  {
    name: "Pest Resistance",
    text: (
      <div>
        <p>
          Pest Resistance is a benefit to those who desire a tree for an
          environment known to host a particular pest. Because not all trees
          have been tested for all pests, much data is not known or documented.
          This field makes no claim of listing all pests to which tree show a
          degree of resistance. Also examine the 'Pest Susceptibility' field for
          more information on pests.
        </p>
      </div>
    ),
  },
  {
    name: "Pest Susceptibility",
    text: (
      <div>
        <p>
          Different plants attract different pests, and some pests will require
          special and regular treatments to prevent damage to the tree or its
          fruit. Because not all trees have been tested for all pests, much data
          is not known or documented. This field makes no claim of listing all
          pests of any particular tree. Also examine the 'Pest Resistant' field
          for more information on pests.
        </p>
      </div>
    ),
  },
  {
    name: "Root Damage Potential",
    text: (
      <div>
        <p>
          Root Damage Potential attempts to qualify the tendency trees have of
          causing damage with their roots. Root damage is usually caused when
          tree roots remain close to the surface of the soil. Tree roots can
          cause costly damage to paving, structures and even underground
          utilities. Because roots nearer the tree trunk will enlarge earlier
          and grow more rapidly, care should be taken to space trees
          appropriately from structures. Local environmental and tree care
          conditions, such as soil type or watering habits, can affect a tree's
          root development. Long, deep waterings can encourage downward root
          growth. Shallow soils will force roots to grow horizontally rather
          than vertically.
        </p>
        <p>
          The terms 'Low', 'Moderate' and 'High' are used in this category.
          Avoid planting trees with high root damage potential near structures.
        </p>
      </div>
    ),
  },
  {
    name: "Salt Spray Tolerance",
    text: (
      <div>
        <p>
          {" "}
          Salt Spray Tolerance ratings, designated as 'Low' 'Moderate' and
          'High', are available only for some trees and are reported as follows:
        </p>
        <dl class="dl-horizontal">
          <dt>High</dt>
          <dd>Tolerant of direct exposure to the ocean front.</dd>
          <dt>Moderate</dt>
          <dd>Tolerant of moderate exposure to salt spray.</dd>
          <dt>Low</dt>
          <dd>
            Tolerant to some exposure to salt spray well back from the dunes.
          </dd>
        </dl>
      </div>
    ),
  },
  // {
  //    REMOVED AT CLIENT REQUEST 2021-07-30 - MW
  //     name: "Seaside Tolerance",
  //     text: <div>
  //         <p>Seaside Tolerance ratings reflect the different degrees of protection from the coastal elements. All trees mentioned in this category are within Sunset Zones 17 or 24 in California. Those not in either of these zones are not suited for seaside growth.</p>
  //         <p>The terms 'Good' and 'Medium' are used to qualify each of the ratings in this category. Trees that do well in a zone qualify as 'Good', while trees that don't do as well qualify as 'Medium'.</p>

  //         <dl>
  //             <dt>Harsh Zone</dt>
  //             <dd>The area directly adjacent to the coast which receives high salt spray, harsh winds, and blasting sands is an extreme seaside condition and we do not recommend any trees for this area. If you desire to plant trees in this area experiment with trees rated 'Good in Moderate Zone'. An example of a harsh zone is Pacifica.</dd>

  //             <dt>Moderate Zone</dt>
  //             <dd>Within this harsh area there are sheltered locations which receive less seaside impacts. These are termed 'Moderate Zones'. An example of a moderate zone is the greater part of San Francisco.</dd>

  //             <dt>Mild Zone</dt>
  //             <dd>Areas further inland are considered 'Mild Zones'. An example of a mild zone is Berkeley.</dd>

  //             <dt>The south coast</dt>
  //             <dd>is generally warmer and milder than the north coast. This is referred to as the 'South Coast'. SelecTree categorizes trees suited for these milder areas.</dd>
  //         </dl>
  //     </div>
  // },
  {
    name: "Soil Moisture",
    text: (
      <div>
        <p>Soil Moisture is reported as follows:</p>

        <dl>
          <dt>Wet</dt>
          <dd>Naturally wet areas (high rainfall)</dd>
          <dt>Moist</dt>
          <dd>Damp soil most of the year (moderate rainfall)</dd>
          <dt>Dry</dt>
          <dd>Crumbly or compacted soil (little or no rainfall)</dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Soil Texture",
    text: (
      <div>
        <p>Soil Texture is reported as follows:</p>
        <dl>
          <dt>Clay</dt>
          <dd>
            hard, fine to small flattened particles, slow to drain. If you rub
            clay soil when wet, it will be sticky and smear easily. When dry, it
            forms clods.
          </dd>
          <dt>Loam</dt>
          <dd>
            good mixture of clay/silt/sand particles, organic matter and
            moisture. Generally the best kind of soil in which to grow garden
            plants.
          </dd>
          <dt>Sand</dt>
          <dd>
            large rounded particles, fast draining, few nutrients, quick to dry.
            Its gritty texture is easy to feel on your fingers. Many drought
            tolerant plants and California natives are suited to this soil type.
          </dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Soil pH",
    text: (
      <div>
        <p>
          Soil pH is how acid or basic a soil is. Another term for basic (in
          this context) is alkaline. The terms used in SelecTree are defined as
          follows:
        </p>
        <dl class="dl-horizontal">
          <dt>Very Acidic</dt>
          <dd>5.3 to 6.0</dd>
          <dt>Slightly Acidic</dt>
          <dd>6.1 to 6.9</dd>
          <dt>Neutral</dt>
          <dd>7.0</dd>
          <dt>Slightly Alkaline</dt>
          <dd>7.1 to 7.5</dd>
          <dt>Very Alkaline</dt>
          <dd>7.6 to 8.2</dd>
        </dl>
      </div>
    ),
  },
  {
    name: "Wildlife",
    text: (
      <div>
        <p>
          Attracts Wildlife indicates the tree has qualities which encourage the
          noted animal to feed (birds, bees, squirrels).
        </p>
      </div>
    ),
  },
];
