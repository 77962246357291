import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ListForm from "./ListForm";
import ExistingLists from "./ExistingLists";
import { getUserListsThunk } from "../../actions/list";
import api from "../../apis/selectree_api";
import { getAuthHeader } from "../../utilities/header";
import "./TreeDetailListWidget.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function TreeDetailListWidget(props) {
  const { defaultModal } = props;

  const treeId = parseInt(props.treeId);

  const [widgetView, setWidgetView] = useState("existing");
  const [modal, updateModal] = useState(defaultModal);

  const user = useSelector(state => state.user);

  const dispatch = useDispatch();

  return (
    <div
      style={{
        display: "inline-flex",
        width: "fit-content",
        blockSize: "fit-content",
      }}
    >
      {user ? <div onClick={() => updateModal(true)}>{props.icon}</div> : ""}
      <Modal
        show={modal && widgetView === "new"}
        centered
        onHide={() => updateModal(false)}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Add To New List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListForm
            treeId={treeId}
            formAction={async formData => {
              const response = await api.post(
                `/list/create`,
                formData,
                getAuthHeader()
              );
              if (response.status < 300) {
                toast.success("New list successfully created!");
                dispatch(getUserListsThunk());
                setWidgetView("existing");
              } else {
                toast.error("Failed to create list!");
              }
            }}
            nav={
              <Button
                className="btn btn-light m-3 border-dark"
                onClick={() => setWidgetView("existing")}
              >
                Back to my lists
              </Button>
            }
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={modal && widgetView === "existing"}
        centered
        onHide={() => updateModal(false)}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>My Lists</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExistingLists treeId={treeId} setWidgetView={setWidgetView} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TreeDetailListWidget;
