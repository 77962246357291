import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import htmlParser from "react-html-parser";
import "./ResultCard.css";

import TreeDetailListWidget from "../TreeDetailListWidget/TreeDetailListWidget";
import { FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { PAGE_TOP_DESKTOP } from "../../utilities/constants";

const DEFAULT_IMAGE = "/images/default-tree.png";
const image_prefix = process.env.REACT_APP_BASE_IMAGE_URL;

function ResultCard(props) {
  const [image, setImage] = useState(false);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    let isMounted = true;

    try {
      function loadImage(link) {
        let img = new Image();

        if (link !== null) img.src = `${image_prefix}${link}`;
        else img.src = DEFAULT_IMAGE;

        img.onload = () => {
          if (isMounted) setImage(`${image_prefix}${link}`);
        };
        img.onerror = () => {
          if (isMounted) setImage(DEFAULT_IMAGE);
        };
      }

      loadImage(props.imgLink);
    } catch (err) {
      console.log("ResultCard image failed to load");
    } finally {
      return () => {
        isMounted = false;
      };
    }
  }, []);

  function renderCard() {
    try {
      return (
        <Card
          className="result-card"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <Link
            className="result-gallery-card-link"
            onClick={() => window.scrollTo(PAGE_TOP_DESKTOP)}
            to={`/tree-detail/${props.treeId}`}
          >
            <div className="result-card-img-container">
              {image ? (
                <Card.Img
                  variant="top"
                  className="result-card-img"
                  src={image}
                  onError={evt => {
                    evt.target.onerror = null;
                    evt.target.src = "/images/default-tree.png";
                  }}
                />
              ) : (
                <div className="loading-card-img">
                  <Spinner
                    style={{
                      opacity: "25%",
                      alignSelf: "center",
                      margin: "auto",
                    }}
                    animation="border"
                    role="status"
                  />
                </div>
              )}

              <div className="result-card-icons">
                {props.height <= 25 ? (
                  <img src="/images/icons/util_square_ico.png" alt="" />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="p-1" style={{ position: "relative" }}>
              <Card.Title className="label-demibold">
                {props.commonName}
              </Card.Title>
              <Card.Subtitle className="text-muted font-italic result-card-scientific-name">
                {htmlParser(props.scientificName)}
              </Card.Subtitle>
            </div>
          </Link>
          <div style={{ position: "absolute", bottom: "0.5rem", right: 0 }}>
            <TreeDetailListWidget
              treeId={props.treeId}
              icon={<FaPlusCircle className="Icon" size={32} />}
            />
          </div>
        </Card>
      );
    } catch (err) {
      console.log(`components.ResultCard.renderCard: ${err}`);
      return null;
    }
  }

  return <div data-tree-id={props.treeId}>{renderCard()}</div>;
}

export default ResultCard;
