import React, { useState } from "react";

import { ListGroup } from "react-bootstrap";

import TreeListItem from "../ListItems/TreeListItem";

import { SAVED_SEARCH } from "../../utilities/constants";

import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";
import uuid from "react-uuid";
import "./ListViewer.css";
import SearchByName from "../SearchByName/SearchByName";
import DeleteModal from "../ListModals/DeleteModal";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import MenuInput from "../FormField/MenuInput";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function ListViewer({ list, addTree, removeTree }) {
  const [treeSort, updateTreeSort] = useState(undefined);

  const [deleteParams, updateDeleteParams] = useState({});
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  const history = useHistory();

  const SORT_OPTIONS = [
    { label: "Scientific Name A-Z", value: 0 },
    { label: "Scientific Name Z-A", value: 1 },
    { label: "Common Name A-Z", value: 2 },
    { label: "Common Name Z-A", value: 3 },
  ];
  function sortTrees(trees) {
    if (!Array.isArray(trees)) return [];
    switch (treeSort) {
      case 0:
        return trees.sort((a, b) => {
          if (a.scientificName)
            return a.scientificName > b.scientificName ? 1 : -1;
          return a.name_unformatted > b.name_unformatted ? 1 : -1;
        });
      case 1:
        return trees.sort((a, b) => {
          if (a.scientificName)
            return a.scientificName < b.scientificName ? 1 : -1;
          return a.name_unformatted < b.name_unformatted ? 1 : -1;
        });
      case 2:
        return trees.sort((a, b) => {
          if (a.commonName) return a.commonName > b.commonName ? 1 : -1;
          return a.common > b.common ? 1 : -1;
        });
      case 3:
        return trees.sort((a, b) => {
          if (a.commonName) return a.commonName < b.commonName ? 1 : -1;
          return a.common < b.common ? 1 : -1;
        });
      default:
        return trees.sort((a, b) => {
          if (a.scientificName)
            return a.scientificName > b.scientificName ? 1 : -1;
          return a.name_unformatted > b.name_unformatted ? 1 : -1;
        });
    }
  }

  function isTreeInList(list, tree_id) {
    return list.trees.find(t => t.tree_id === tree_id);
  }

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  return (
    <div>
      <span
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {addTree ? (
          <div style={{ margin: "0 0.5rem", minWidth: "20rem", flexGrow: 1 }}>
            <SearchByName
              placeholder="Search to add trees..."
              onSubmit={(e, ctx) => {
                e.preventDefault();
                if (!ctx) {
                  toast.error(`A single tree must be selected!`);
                } else if (isTreeInList(list, ctx.tree_id)) {
                  toast.error(
                    `${ctx.name_unformatted} is already in this list!`
                  );
                } else {
                  addTree({ list, tree_option: ctx });
                }
              }}
            />
          </div>
        ) : (
          ""
        )}
        <div style={{ margin: "0 0.5rem", minWidth: "20rem", flexGrow: 1 }}>
          <MenuInput
            options={SORT_OPTIONS}
            placeholder="Sort Trees..."
            value={treeSort || treeSort === 0 ? SORT_OPTIONS[treeSort] : ""}
            onChange={e => {
              updateTreeSort(e.value);
            }}
          />
        </div>
      </span>
      <ListGroup>
        <div>
          {list && list.trees.length > 0
            ? sortTrees(list.trees).map((tree, index) => (
                <TreeListItem
                  href={`/tree-detail/${tree.tree_id}`}
                  key={uuid()}
                  src={
                    tree.link
                      ? `${process.env.REACT_APP_BASE_IMAGE_URL}${tree.link}`
                      : "/images/default-tree.png"
                  }
                  heading={
                    list.type === SAVED_SEARCH ? tree.common : tree.commonName
                  }
                  subHeading={
                    list.type === SAVED_SEARCH
                      ? tree.name_unformatted
                      : tree.scientificName
                  }
                  actionItem={
                    removeTree ? (
                      <FaTrashAlt
                        className="Icon"
                        style={{ alignSelf: "center" }}
                        size={30}
                        onClick={e => {
                          e.stopPropagation();
                          updateDeleteParams({
                            list,
                            tree,
                          });
                          setDeleteMessage(
                            `Are you sure you want to delete tree: '${tree.commonName}'?`
                          );
                          setDisplayConfirmationModal(true);
                        }}
                      />
                    ) : (
                      false
                    )
                  }
                  type={list.type}
                  treeId={tree.tree_id}
                  treeIndex={index}
                />
              ))
            : "No trees yet."}
        </div>
        <DeleteModal
          showModal={displayConfirmationModal}
          confirmModal={removeTree}
          hideModal={hideConfirmationModal}
          params={deleteParams}
          message={deleteMessage}
        />
      </ListGroup>
    </div>
  );
}
