import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";

import { withRouter } from "react-router";

import TaxonForm from "./TaxonForm";

import api from "../../../apis/selectree_api";
import { getAuthHeader } from "../../../utilities/header";

class Taxons extends React.Component {
  constructor(props) {
    super();
    this.state = {
      taxons: [],
    };
  }

  componentDidMount = async () => {
    this.getTaxons();
  };

  componentDidUpdate = async () => {
    if (this.state.forceData) {
      this.getTaxons();
    }
  };

  getTaxons = async () => {
    let query = this.props.match.params.treeName;

    if (Number.parseInt(query)) {
      let treeId = Number.parseInt(query);

      try {
        if (treeId) {
          let response = await api.get(`/tree/taxons/${treeId}`);
          this.setState({ taxons: response.data, forceData: false });
        } else {
          throw new Error("no id provided");
        }
      } catch (err) {
        console.log(
          `Error in component.TreeFormCard.EditTaxon.Taxons.getTaxons: ${err}`
        );
      }
    }
  };

  addTaxon = async () => {
    let treeId = this.props.parentComponent.state.tree_id;
    try {
      if (treeId) {
        await api.post(`/tree/taxon/add/${treeId}`, {}, getAuthHeader());
        this.setState({ forceData: true });
      } else {
        throw new Error("no id provided");
      }
    } catch (err) {
      console.log(
        `Error in component.TreeFormCard.EditTaxon.Taxons.addTaxon: ${err}`
      );
    }
  };

  render() {
    let numberOfScientificNames = this.state.taxons?.length || 1;

    let sequenceOptions = [];

    for (var i = 1; i <= numberOfScientificNames; i++) {
      sequenceOptions.push({ label: i, value: i });
    }

    return (
      <>
        <Form.Label>Scientific Names</Form.Label>
        <hr />
        {this.state.taxons?.map(taxon => (
          <TaxonForm
            key={JSON.stringify(taxon)}
            taxon={taxon}
            taxonComponent={this}
            sequenceOptions={sequenceOptions}
          />
        ))}
        <span onClick={this.addTaxon} className="form-button-primary">
          + Add New Taxon
        </span>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {};
};
export default connect(mapStateToProps, {})(withRouter(Taxons));
