import React from "react";
import { Form } from "react-bootstrap";
import { ImCheckboxChecked } from "react-icons/im";
import { getFilterOptions } from "../../utilities/sessionStorage";
import "./FormField.css";

class FormFieldCheckbox extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  //Shorthand to access the values on the parent component's state.
  selectedValue = () => {
    let ret = this.props.parentComponent.state[this.props.field];
    if (ret === undefined) {
      console.log(
        `Components.FormField.FormFieldCheckbox- Cannot find field ${this.props.field} on the parent component.`
      );
      ret = [];
    } else if (!Array.isArray(ret)) {
      console.log(
        `Components.FormField.FormFieldCheckbox- Please make sure ${this.props.field} on the parent component defaults to an array.`,
        ret
      );
      ret = [];
    }

    return ret;
  };

  change = e => {
    try {
      //Grab the new value from the element itself
      let newValue = e.target.getAttribute("data-value");
      let values = this.selectedValue();
      let field = this.props.field;

      //reset other field logic

      if (this.props.reset) {
        this.props.reset.map(resetField => {
          this.props.parentComponent.setState({
            [field]: newValue,
            [resetField]: [],
            changed: true,
          });
        });
      }
      //If the array of values includes it, filter it out.
      if (values.includes(newValue)) {
        values = values.filter(v => v !== newValue);
      }
      //Or add it back in.
      else {
        values.push(newValue);
      }
      // dynamic way for component to do custom logic on new values
      if (this.props.passinFunc) {
        this.props.passinFunc(this.props.passinCond(values));
      }

      this.props.parentComponent.setState({ [field]: values, changed: true });
    } catch (err) {
      console.log(`Components.FormField.FormFieldCheckbox.change()-  ${err}`);
      return null;
    }
  };

  clearOption = () => {
    this.props.parentComponent.setState({
      [this.props.field]: [],
      changed: true,
    });
  };

  renderOptions = () => {
    try {
      return this.props.options.map(option => this.renderOption(option));
    } catch (err) {
      console.log(
        `Components.FormField.FormFieldCheckbox.renderOptions()-  ${err}`
      );
      return null;
    }
  };

  //Renders the HTML for individual checkbox item.
  renderOption = option => {
    return (
      <li key={this.props.field + option.value + option.label}>
        <div className="form-check form-check-inline">
          <div style={{ display: "flex", position: "relative" }}>
            <input
              className="form-check-input"
              id={this.props.field + option.value}
              type="checkbox"
              checked={this.selectedValue().includes(`${option.value}`)}
              onChange={this.change}
              data-value={option.value}
            />

            <span className="overlay">
              <ImCheckboxChecked className="icon" />
            </span>
          </div>
          <label
            className="form-check-label"
            htmlFor={this.props.field + option.value}
          >
            {option.label}
          </label>
        </div>
      </li>
    );
  };

  render() {
    let hideClearButton = this.selectedValue().length === 0;

    return (
      <Form.Group className="form-field-checkbox">
        <Form.Label className="d-block">
          {this.props.title}
          {hideClearButton ? (
            ""
          ) : (
            <span
              onClick={this.clearOption}
              className="form-field-clear-field-button"
            >
              X
            </span>
          )}
        </Form.Label>

        <ul>{this.renderOptions()}</ul>
      </Form.Group>
    );
  }
}

export default FormFieldCheckbox;
