import React from "react";
import { Container } from "react-bootstrap";
import "./SearchByCharCard.css";
import FormFieldPlainText from "../FormField/FormFieldPlainText";

class SearchTerm extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    let parentComponent = this.props.parentComponent;
    return (
      <Container className="search-by-char-card--search-term">
        <FormFieldPlainText
          title="Type in a term to search"
          field="searchTerm"
          parentComponent={parentComponent}
        />
      </Container>
    );
  }
}

export default SearchTerm;
