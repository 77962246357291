import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import "./ResultSort.css";
import reactSelectStyles from "../../customUI/react-select-options";
import { useDispatch, useSelector } from "react-redux";
import { getSearchResultsThunk } from "../../actions/search";

const DEFAULT_SORT_OPTIONS = [
  { label: "Scientific Name A-Z", value: 1 },
  { label: "Scientific Name Z-A", value: 2 },
  { label: "Common Name A-Z", value: 3 },
  { label: "Common Name Z-A", value: 4 },
];

function ResultSort() {
  const searchOptions = useSelector(state => state.searchOptions);
  const dispatch = useDispatch();

  function sortResults(sort) {
    try {
      const getSearchResults = getSearchResultsThunk({
        ...searchOptions,
        scroll_y: window.pageYOffset,
        sort: sort,
      });

      dispatch(getSearchResults);
    } catch (err) {
      console.log(`components.ResultSort.sortResults: ${err}`);
    }
  }

  return (
    <Form.Group controlId="result-sort">
      <Select
        options={DEFAULT_SORT_OPTIONS}
        styles={reactSelectStyles}
        placeholder="Sort Results By..."
        onChange={e => {
          sortResults(e.value);
        }}
        value={
          DEFAULT_SORT_OPTIONS[
            searchOptions.sort - 1 > 0 ? searchOptions.sort - 1 : 0
          ]
        }
      />
    </Form.Group>
  );
}

export default ResultSort;
