import React, { useState } from "react";
import { Form } from "react-bootstrap";

function TextInput(props) {
  const [edited, setEditStatus] = useState("false");
  const { label, onChange, id, errorMessage, ...inputProps } = props;
  return (
    <Form.Group className="inline-flex formInput" id={id}>
      {label ? (
        <Form.Label>
          {label}
          {props.required ? <a style={{ color: "red" }}> *</a> : ""}
        </Form.Label>
      ) : (
        ""
      )}
      <Form.Control
        {...inputProps}
        onChange={e => {
          props.onChange(props.name, e.target.value);
        }}
        onBlur={e => {
          setEditStatus("true");
          if (props.onBlur) props.onBlur(e);
        }}
        edited={edited}
      />
      {errorMessage !== undefined ? (
        <span className="error-message">{errorMessage}</span>
      ) : (
        ""
      )}
    </Form.Group>
  );
}

export default TextInput;
