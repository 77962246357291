import React, { useState } from "react";
import { Button, Spinner, Form, Nav } from "react-bootstrap";
import TextInput from "../FormField/TextInput.js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginThunk } from "../../actions/user.js";

function LoginForm(props) {
  const { handleForm, updateModal } = props;

  const [email, updateEmail] = useState("");
  const [password, updatePassword] = useState("");
  const [error, updateError] = useState("");
  const [loading, updateLoading] = useState(false);
  const dispatch = useDispatch();

  async function submitForm(e) {
    e.preventDefault();
    updateLoading(true);
    updateError("");

    dispatch(loginThunk({ email, password })).then(res => {
      if (res.status === 200) {
        updateModal(false);
        toast.success("Successfully Logged In!");
      } else {
        const err_message =
          res.status !== 500
            ? res?.data?.message
            : "Can't connect to the server, please contact us if issue persists";

        toast.error(
          `Error: ${
            err_message
              ? err_message
              : "Unknown issue, please contact us to resolve"
          }`
        );
        updateLoading(false);
      }
    });
  }

  let renderLoading = () => {
    return (
      <Spinner animation="border" role="status">
        <div className="sr-only">Loading...</div>
      </Spinner>
    );
  };

  return (
    <div>
      <Form onSubmit={submitForm}>
        <h2>Log In</h2>
        <p>Please enter your email & password.</p>

        <TextInput
          label={"Email"}
          name="email"
          value={email}
          onChange={(name, v) => updateEmail(v)}
          id="login-email-form"
        />
        <TextInput
          label={"Password"}
          name="password"
          type="password"
          value={password}
          onChange={(name, v) => updatePassword(v)}
          id="login-password-form"
        />
        {error ? <div>{error}</div> : ""}
        <Button
          type="submit"
          disabled={loading}
          style={{ marginBottom: "0.5rem" }}
        >
          {loading && renderLoading()}
          {!loading && <span> Login</span>}
        </Button>

        <Nav className="nav-margin">
          <Nav.Link
            className="nav-length"
            style={{ padding: "0.0rem" }}
            onClick={() => handleForm("toggleSide", "password_recovery")}
          >
            Forgot your password?
          </Nav.Link>
          <Nav.Link
            style={{ padding: "0.0rem" }}
            className="nav-length"
            onClick={() => handleForm("toggleSide", "signup")}
          >
            Need to create an account?
          </Nav.Link>
          <Nav.Link
            style={{ padding: "0.0rem" }}
            className="nav-length"
            onClick={() => handleForm("toggleSide", "verify")}
          >
            Need to verify an account?
          </Nav.Link>
        </Nav>
      </Form>
    </div>
  );
}

export default LoginForm;
