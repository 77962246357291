import React from "react";
//LIB
import { useDispatch, useSelector } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  IoMdHeart,
  IoMdHeartEmpty,
  IoMdClose,
  IoMdCheckmark,
} from "react-icons/io";
import { toast } from "react-toastify";
import { BiUserCheck, BiUserX } from "react-icons/bi";
import { MdContentCopy } from "react-icons/md";
import { BsDownload } from "react-icons/bs";
//API
import api from "../../apis/selectree_api";
import { followThunk } from "../../actions/list";
import { getAuthHeader } from "../../utilities/header";

import "./ActionDropdown.css";

import { NavDropdown } from "react-bootstrap";
import uuid from "react-uuid";
import { copyToClipboard } from "../CopyButton/CopyButton";
import { downloadCSV } from "../../utilities/csv";
import { getSearchListThunk } from "../../actions/search";

export function BanUser({ list }) {
  const searchOptions = useSelector(state => state.listSearchOptions);
  const dispatch = useDispatch();

  async function banUser(user_id, value) {
    try {
      await api.patch(`users/ban/`, { user_id, value }, getAuthHeader());
      dispatch(getSearchListThunk(searchOptions));
      toast.success(`User ${user_id} ${value ? "Banned" : "Unbanned"}`);
    } catch (err) {
      console.log(err);
      toast.error(`${value ? "Ban" : "Unban"} Failed to Process`);
    }
  }

  return (
    <NavDropdown.Item
      className="action-item"
      onClick={e => {
        e.stopPropagation();
        banUser(list.user_id, !list.user_banned);
      }}
    >
      {list.user_banned ? (
        <BiUserCheck className="action-icon" size={20} />
      ) : (
        <BiUserX className="action-icon" size={20} />
      )}
      {list.user_banned ? "Unban User" : "Ban User"}
    </NavDropdown.Item>
  );
}

export function BanList({ list }) {
  const searchOptions = useSelector(state => state.listSearchOptions);
  const dispatch = useDispatch();

  async function banList(list, value) {
    try {
      await api.patch(
        `list/ban/`,
        { list_id: list.list_id, value },
        getAuthHeader()
      );
      dispatch(getSearchListThunk(searchOptions));
      toast.success(`${list.name} ${value ? "Banned" : "Unbanned"}`);
    } catch (err) {
      console.log(err);
      toast.error(`${value ? "Ban" : "Unban"} Failed to Process`);
    }
  }

  return (
    <NavDropdown.Item
      id={`list-action-${list.list_id}`}
      className="action-item"
      onClick={e => {
        e.stopPropagation();
        banList(list, true);
      }}
    >
      {list.banned ? (
        <IoMdCheckmark className="action-icon" size={25} />
      ) : (
        <IoMdClose className="action-icon" size={25} />
      )}
      {list.banned ? " Unban List" : " Ban List"}
    </NavDropdown.Item>
  );
}

export function CopyAction({ copy_text }) {
  return (
    <NavDropdown.Item
      onClick={() => copyToClipboard(copy_text)}
      className="action-item"
    >
      <MdContentCopy size={20} className="action-icon" />
      {"Copy Link"}
    </NavDropdown.Item>
  );
}

export function DownloadAction({ obj_array, filename }) {
  return (
    <NavDropdown.Item
      onClick={() => downloadCSV(obj_array, filename)}
      className="action-item"
    >
      <BsDownload className="action-icon" size={20} />
      {" Download"}
    </NavDropdown.Item>
  );
}

export function FollowAction({ list_id, followed }) {
  const dispatch = useDispatch();

  async function follow() {
    dispatch(followThunk(true, list_id));
  }
  async function unfollow() {
    dispatch(followThunk(false, list_id));
  }

  return (
    <NavDropdown.Item
      className="action-item"
      onClick={e => {
        e.stopPropagation();
        followed ? unfollow() : follow();
      }}
    >
      {followed ? (
        <IoMdHeart className="action-icon" size={25} />
      ) : (
        <IoMdHeartEmpty className="action-icon" size={25} />
      )}
      {followed ? " Unfollow" : " Follow"}
    </NavDropdown.Item>
  );
}

export default function ActionDropdown(props) {
  const title = (
    <BsThreeDotsVertical
      style={{ alignSelf: "center" }}
      size={25}
      className="Icon"
    />
  );
  return (
    <span className="action-dropdown-container">
      <NavDropdown id={uuid()} className="action-dropdown" title={title}>
        {props.children}
      </NavDropdown>
    </span>
  );
}
