import api from "../apis/selectree_api";

export function loginThunk({ email, password }) {
  return async dispatch => {
    return api.post("/auth/login", { email, password }).then(
      response => {
        dispatch({
          type: "LOGIN",
          payload: { ...response.data.response, token: response.data.token },
        });
        return response;
      },
      error => {
        return error.response;
      }
    );
  };
}
