import React, { useState } from "react";
import { button, Form, Spinner, Nav, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import api from "../../apis/selectree_api";
import TextInput from "../FormField/TextInput.js";
import SingleCheckBox from "../FormField/SingleCheckBox";
import { useHistory } from "react-router";
import { PAGE_TOP_DESKTOP } from "../../utilities/constants.js";

function VerifyAccount(props) {
  const [loading, updateLoading] = useState(false);
  const [agreementStatus, setAgreementStatus] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  const history = useHistory();

  const dispatch = useDispatch();

  const auth = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const login = data => {
    localStorage.setItem("user", JSON.stringify(data.response));
    localStorage.setItem("token", data.token);
    localStorage.setItem("loggedIn", true);
    window.location.href = window.location.origin;
  };

  async function submitForm(e) {
    //Block Verify if user hasn't checked agreement box
    if (!agreementStatus) return;

    e.preventDefault();
    updateLoading(true);

    try {
      let res = await api.put(`/users/verify-account`, {}, auth);

      if (res.data.message === "Changed") {
        delete res.data.message;

        let data = { ...res.data };

        dispatch({ type: "LOGIN", payload: data });
        window.scrollTo(PAGE_TOP_DESKTOP);
        history.push("/");
      }
    } catch (err) {
      updateLoading(false);
      console.log(
        `Error in client.components.VerifyAccount.submitForm: ${err}`
      );
    }
  }

  let renderLoading = () => {
    return (
      <Spinner animation="border" role="status">
        <div className="sr-only">Loading...</div>
      </Spinner>
    );
  };

  return (
    <Form onSubmit={submitForm}>
      <Container>
        <h2 className="search-by-char-header text-center category-label p-3 m-0">
          User Agreement
        </h2>
        <hr className="hr-bold-short-black mb-2 mt-0" />
        <p>
          All content on UFEI is licensed under CC BY-NC. Use of information,
          documents, papers and websites that utilize information from SelecTree
          must acknowledge the use of the database by including an appropriate
          citation. Citations are available at the bottom of each species page.
        </p>
        <p>
          Users agree to not use the account in any way that is threatening,
          abusive, harassing, defamatory, libelous, deceptive, fraudulent,
          invasive of another's privacy, or any similar behavior. We reserve the
          right to immediately terminate your account if, in our sole and
          absolute discretion, we determine that you have used the Service,
          Platform, or the Device in any of the aforementioned ways.
        </p>
        <p>
          No warranties or guarantees as to the accuracy of the data and
          information derived from this web site are expressed or implied. The
          California Polytechnic State University and the Cal Poly Corporation
          shall not be responsible for any loss of profit, indirect, incidental,
          special, or consequential damages arising out of the use of the data
          and information derived from this web site.
        </p>

        <div className="d-flex justify-content-center">
          <SingleCheckBox
            label="I agree to the terms and conditions of the SelectTree User Agreement"
            onChange={() => setAgreementStatus(!agreementStatus)}
          />
        </div>
      </Container>
      <div className="text-center mb-3 mt-3">
        <button
          type="submit"
          class="btn btn-primary btn-lg"
          disabled={loading || !agreementStatus}
        >
          {loading && renderLoading()}
          {!loading && <span> Verify Account</span>}
        </button>
      </div>
    </Form>
  );
}

export default VerifyAccount;
