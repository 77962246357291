import React from "react";
import { Form } from "react-bootstrap";
import "rc-slider/assets/index.css";
import Slider from "rc-slider";
import "./FormField.css";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const RangeComponent = createSliderWithTooltip(Slider.Range);

class FormFieldRange extends React.Component {
  constructor(props) {
    super(props);

    if (props.parentComponent.state) {
      let value = props.parentComponent.state[props.minField];
      if (value === undefined || value === null) {
        console.log(
          `Components.FormField.FormFieldRange- Cannot find minField ${this.props.field} on the parent component.`
        );
      }
      value = props.parentComponent.state[props.maxField];
      if (value === undefined || value === null) {
        console.log(
          `Components.FormField.FormFieldRange- Cannot find maxField ${this.props.field} on the parent component.`
        );
      }
    } else {
      console.log(
        `Components.FormField.FormFieldRange- Please pass in the parent component that has the state.`
      );
    }

    this.state = {};
  }

  rangeChange = e => {
    try {
      let min = e[0];
      let max = e[1];

      this.props.parentComponent.setState({
        [this.props.minField]: min,
        [this.props.maxField]: max,
      });
    } catch (err) {
      console.log(`Components.FormField.FormFieldRange.rangeChange()-  ${err}`);
      return null;
    }
  };

  min = () => {
    try {
      let optionKeys = Object.keys(this.props.options);
      let val = Number.parseInt(optionKeys[0]);
      return val;
    } catch (err) {
      console.log(`Components.FormField.FormFieldRange.min()-  ${err}`);
      return null;
    }
  };

  max = () => {
    try {
      let optionKeys = Object.keys(this.props.options);
      let val = Number.parseInt(optionKeys[optionKeys.length - 1]);
      return val;
    } catch (err) {
      console.log(`Components.FormField.FormFieldRange.max()-  ${err}`);
      return null;
    }
  };

  clearOption = () => {
    this.rangeChange([this.min(), this.max()]);
  };

  render() {
    let min = this.props.parentComponent.state[this.props.minField];
    let max = this.props.parentComponent.state[this.props.maxField];

    return (
      <Form.Group className="form-field-range">
        <Form.Label>
          {this.props.title}

          {this.min() === min && this.max() === max ? (
            ""
          ) : (
            <span
              onClick={this.clearOption}
              className="form-field-clear-field-button"
            >
              X
            </span>
          )}
        </Form.Label>
        <RangeComponent
          id={`form-field-range-${this.props.field}`}
          allowCross={false}
          defaultValue={[min, max]}
          value={[min, max]}
          min={this.min()}
          max={this.max()}
          onChange={this.rangeChange}
          marks={this.props.options}
          tipFormatter={value =>
            (this.props.showLabel
              ? Object.values(this.props.options)[value - 1]
              : value) + (this.props.suffix || "")
          }
          tipProps={{
            placement: "top",
            visible: true,
          }}
        />
      </Form.Group>
    );
  }
}

export default FormFieldRange;
