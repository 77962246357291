import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import FormFieldText from "../FormField/FormFieldText";
import FormFieldSingleCheckbox from "../FormField/FormFieldSingleCheckbox";
import FormFieldCheckbox from "../FormField/FormFieldCheckbox";
import FormFieldMultiText from "../FormField/FormFieldMultiText";
import FormFieldPlainText from "../FormField/FormFieldPlainText";
import "./TreeFormCard.css";

import { optionsReturnForForm } from "../../utilities/searchChar";

import { setRegion } from "../../actions/setFilters";

class TreeChar extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  optionsFor = field => {
    try {
      if (
        this.props.searchCharacteristics &&
        this.props.searchCharacteristics[field]
      ) {
        return optionsReturnForForm(this.props.searchCharacteristics[field]);
      } else {
        return [];
      }
    } catch (err) {
      console.log(`components.TreeFormCard.TreeChar.optionsFor: ${err}`);
    }
  };

  render() {
    let parentComponent = this.props.parentComponent;
    return (
      <Container className="tree-form--tree-char">
        <Row>
          <Col lg={12}>
            <h3>General</h3>
          </Col>
          <Col lg={3}>
            <FormFieldPlainText
              title="Height (Min)"
              field="height_low"
              parentComponent={parentComponent}
              validate={{ kind: "number" }}
            />

            <FormFieldPlainText
              title="Height (Max)"
              field="height_high"
              parentComponent={parentComponent}
              validate={{ kind: "number" }}
            />
          </Col>

          <Col lg={3}>
            <FormFieldPlainText
              title="Width (Min)"
              field="width_low"
              parentComponent={parentComponent}
              validate={{ kind: "number" }}
            />

            <FormFieldPlainText
              title="Width (Max)"
              field="width_high"
              parentComponent={parentComponent}
              validate={{ kind: "number" }}
            />
          </Col>

          <Col lg={3}>
            <FormFieldPlainText
              title="Growth (Min)"
              field="growth_rate_low"
              parentComponent={parentComponent}
              validate={{ kind: "number", max: 36 }}
            />

            <FormFieldPlainText
              title="Growth (Max)"
              field="growth_rate_high"
              parentComponent={parentComponent}
              validate={{ kind: "number", max: 127 }}
            />
          </Col>

          <Col lg={{ span: 3 }}>
            <FormFieldText
              title="Foliage Type"
              field="foliage_type"
              options={[
                { label: "Deciduous", value: "Deciduous" },
                { label: "Partly Deciduous", value: "Partly Deciduous" },
                { label: "Evergreen", value: "Evergreen" },
              ]}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldMultiText
              title="Tree Shape"
              field="tree_shape"
              options={this.optionsFor("treeShapes")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldMultiText
              title="Litter Type"
              field="litter_type"
              options={this.optionsFor("litterTypes")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={12}>
            <h3>Bark</h3>
          </Col>

          <Col lg={{ span: 3 }}>
            <FormFieldPlainText
              title="Bark Color"
              field="bark_color"
              options={[]}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 3 }}>
            <FormFieldMultiText
              title="Bark Texture"
              field="bark_texture"
              options={this.optionsFor("barkTextures")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 3 }}>
            <FormFieldText
              title="Branch Strength (Min)"
              field="branch_strength_low"
              options={[
                { label: "Weak", value: "Weak" },
                { label: "Medium Weak", value: "Medium Weak" },
                { label: "Medium", value: "Medium" },
                { label: "Medium Strong", value: "Medium Strong" },
                { label: "Strong", value: "Strong" },
              ]}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 3 }}>
            <FormFieldText
              title="Branch Strength (Max)"
              field="branch_strength_high"
              options={[
                { label: "Weak", value: "Weak" },
                { label: "Medium Weak", value: "Medium Weak" },
                { label: "Medium", value: "Medium" },
                { label: "Medium Strong", value: "Medium Strong" },
                { label: "Strong", value: "Strong" },
              ]}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={12}>
            <h3>Leaves</h3>
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldText
              title="Leaf Form"
              field="leaf_form"
              options={[
                { label: "Simple", value: 1 },
                { label: "Trifoliate", value: 2 },
                { label: "Palmately Compound", value: 3 },
                { label: "Pinnately Compound", value: 4 },
                { label: "Bipinnately Compound", value: 5 },
              ]}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldText
              title="Leaf Arrangement"
              field="leaf_arrangement"
              options={[
                { label: "Alternate", value: 1 },
                { label: "Alternate/Whorled", value: 2 },
                { label: "Opposite", value: 3 },
                { label: "Opposite/Whorled", value: 4 },
                { label: "Whorled", value: 5 },
              ]}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldText
              title="Leaf Shape"
              field="leaflet_shape"
              options={this.optionsFor("leafletShapes")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldMultiText
              title="Growth Color"
              field="foliage_growth_color"
              options={this.optionsFor("foliageGrowthColors")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldSingleCheckbox
              title="Has Fall Color?"
              field="foliage_fall_color"
              options={[
                { label: "No", value: "No" },
                { label: "Yes", value: "Yes" },
              ]}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={12}>
            <h3>Fruit</h3>
          </Col>
          <Col lg={{ span: 4 }}>
            <FormFieldText
              title="Fruit Size"
              field="fruit_size"
              options={this.optionsFor("fruitSizes")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldPlainText
              title="Fruit Color"
              field="fruit_color"
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldText
              title="Fruit Type"
              field="fruit_type"
              options={this.optionsFor("fruitTypes")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldCheckbox
              title="Fruit Value"
              field="fruit_value"
              options={[
                { label: "Edible", value: "Edible" },
                { label: "Wildlife use it", value: "Wildlife use it" },
              ]}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldMultiText
              title="Fruiting Time"
              field="fruiting_time"
              options={this.optionsFor("fruitingTimes")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={12}>
            <h3>Flower</h3>
          </Col>
          <Col lg={{ span: 4 }}>
            <FormFieldText
              title="Flower Sex"
              field="sex"
              options={[
                { label: "Monoecious", value: 1 },
                { label: "Dioecious", value: 2 },
                { label: "Perfect Flowers", value: 3 },
              ]}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldText
              title="Flower Showiness"
              field="flower_showiness"
              options={this.optionsFor("flowerShowiness")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldSingleCheckbox
              title="Fragrance?"
              field="fragrance"
              options={[
                { label: "No", value: "No" },
                { label: "Yes", value: "Yes" },
              ]}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldMultiText
              title="Flower Color"
              field="flower_color"
              options={this.optionsFor("flowerColors")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 4 }}>
            <FormFieldMultiText
              title="Flower Time"
              field="flower_time"
              options={this.optionsFor("flowerTimes")}
              parentComponent={parentComponent}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchCharacteristics: state.searchCharacteristics.data,
    region: state.region,
  };
};
export default connect(mapStateToProps, { setRegion })(TreeChar);
