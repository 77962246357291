import React from "react";
import "./ErrorPage.css";
import { Link } from "react-router-dom";

class ErrorPage extends React.Component {
  render() {
    return (
      <div className="container error-page-container">
        <h1>We're Sorry!</h1>
        <h2>
          {this.props.message ||
            "It seems like we've encountered an unexpected error."}
        </h2>

        <br />
        <p>
          <button
            className="btn btn-default btn-lg"
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </button>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-primary btn-lg" to="/">
            SelecTree Home
          </Link>
        </p>
        <br />

        <h4>
          "I was never lost in the woods in my whole life, though once I was
          confused for three days."
        </h4>
        <p className="text-muted">-Daniel Boone</p>
      </div>
    );
  }
}

export default ErrorPage;
