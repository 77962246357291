import api from "../apis/selectree_api";

export const getFeaturedQuote = async () => {
  try {
    const response = await api.get(`/tree/featured-quote`);
    return response;
  } catch (err) {
    console.log(`Error in client.actions.featuredQuote: ${err}`);
  }
};
