import React from "react";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import "./SearchByChar.css";
import { connect, useSelector } from "react-redux";
import qs from "query-string";
import { getSearchChar } from "../../actions/searchChar";

import ResponsiveAccordion from "../ResponsiveAccordion/ResponsiveAccordion";
import {
  getFilterOptions,
  setFilterOptions,
  setSearchPageOptions,
} from "../../utilities/sessionStorage";
import { filterDataObj } from "../../utilities/searchChar";
import {
  DEFAULT_FILTER_OPTIONS,
  NUMBER_OF_RESULTS,
  DEFAULT_SORT,
  DEFAULT_ACTIVE_PAGE,
  PAGE_TOP_DESKTOP,
} from "../../utilities/constants";

// sub-components
import TreeChar from "../SearchByCharCard/TreeChar";
import FlowersAndFruit from "../SearchByCharCard/FlowersAndFruit";
import SearchTerm from "../SearchByCharCard/SearchTerm";
import SiteConditions from "../SearchByCharCard/SiteConditions";
import SavedSearchPicker from "../SavedSearchPicker/SavedSearchPicker";
import { Link, withRouter } from "react-router-dom";
import { getUserListsThunk } from "../../actions/list";
import { getSearchCharThunk } from "../../actions/search";
import { toast } from "react-toastify";
class SearchByChar extends React.Component {
  constructor(props) {
    super();

    this.state = this.defaultState();
  }

  defaultState() {
    return DEFAULT_FILTER_OPTIONS();
  }

  componentDidMount() {
    try {
      const params = qs.parse(window.location.search);
      if (params.filterData) {
        const filterData = filterDataObj(params.filterData)
          ? filterDataObj(params.filterData)
          : null;
        if (Object.keys(filterData).length > 0 && filterData !== "all") {
          setFilterOptions(filterData);
          this.setState(filterData);
        }
      }
      if (sessionStorage.ufeiFilterOptions) {
        const filterOptions = getFilterOptions();
        this.setState(filterOptions);
      } else {
        setFilterOptions();
        const filterOptions = getFilterOptions();
        this.setState(filterOptions);
      }
    } catch (err) {
      console.log(`components.SearchByChar.componentDidMount: ${err}`);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      if (prevState !== this.state) {
        setFilterOptions(this.state);
      }
      if (prevProps !== this.props) {
        const region = !this.props.region.length ? "" : this.props.region;
        //This if/else makes sure a ca_native or hi_native doesn't get "stuck set" when switching over to the other
        if (region === "pacific islands") {
          this.setState({ region: region, ca_native: [], fall_color: [] });
        } else {
          this.setState({ region: region, hi_native: [] });
        }
      }
    } catch (err) {
      console.log(`components.SearchByChar.componentDidUpdate: ${err}`);
    }
  }

  clearForm = () => {
    if (this.props.searchOptions)
      this.props.setSearchOptions({
        ...this.props.searchOptions,
        savedSearch: "",
      });
    this.setState(this.defaultState());
  };
  validateInput = () => {
    //validate if zipcode entered on climate adapted
    let warning = "";
    if (this.state.climate_adapted.includes("1")) {
      if (this.state.adapted_zip_code.length === 0) {
        warning =
          "Climate Adapted selected. Please fill out the adapted zip code to search.";
      }
    }
    // give warning toast
    if (warning.length > 0) {
      toast.error(warning, {
        position: "bottom-center",
      });
      return false;
    }
    return true;
  };
  submitForm = e => {
    try {
      //check if inputs are valid
      e.preventDefault();
      if (!this.validateInput()) {
        return;
      }

      let filterData = this.convertSearchObjectToString();

      if (!sessionStorage.ufeiSearchOptions) {
        setSearchPageOptions();
      }
      let searchOptions = JSON.parse(sessionStorage.ufeiSearchOptions);
      if (this.props.history.location.pathname === "/search-results") {
        this.props.getSearchCharThunk(searchOptions, filterData);
      }

      window.scrollTo(PAGE_TOP_DESKTOP);

      this.props.history.push(
        `/search-results?filterData=${filterData}#activePage=${DEFAULT_ACTIVE_PAGE}&resultsPerPage=${NUMBER_OF_RESULTS[0]}&sort=${DEFAULT_SORT}`
      );
    } catch (err) {
      console.log(`component.searchByChar.submitForm: ${err}`);
    }
  };

  convertSearchObjectToString = () => {
    //This first line converts the state into a double nested array, [[fieldName, value], [fieldName, value]]
    let filterData = Object.entries(this.state);

    //This filter just looks at any values which aren't equivalent to blank.
    filterData = filterData.filter(([fieldName, value]) =>
      //If "value" is an array, see if it's length > 0, or else see if it's not falsy (blank string is falsy)
      {
        return (
          (Array.isArray(value) && value.length > 0) ||
          (!Array.isArray(value) && value)
        );
      }
    );

    //Now map & combine each pair.
    filterData = filterData.map(
      //Concatenate the field name with the value- if the value is an array, join with commas first.
      ([fieldName, value]) => {
        return (
          fieldName + "=" + (Array.isArray(value) ? value.join(",") : value)
        );
      }
    );

    //Join it all with a question mark
    filterData = filterData.join("?");

    return filterData;
  };

  render() {
    return (
      <>
        <Form id="search-by-char" onSubmit={this.submitForm}>
          <h2 className="search-by-char-header text-center category-label p-3 pt-lg-5 pb-lg-11 m-0">
            SEARCH BY CHARACTERISTICS
          </h2>
          <hr className="hr-bold-short-black mt-0" />
          <Container>
            <Row>
              <Col sm={12} className="text-center mb-4">
                <Button
                  variant="secondary"
                  size="lg"
                  type="button"
                  onClick={this.clearForm}
                >
                  Clear All Filters
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button variant="primary" type="submit" size="lg">
                  SEARCH
                </Button>
              </Col>
              <Col sm={12} className="mb-4">
                <ResponsiveAccordion title="TREE CHARACTERISTICS">
                  <TreeChar parentComponent={this} parentState={this.state} />
                </ResponsiveAccordion>
              </Col>

              <Col sm={12} className="mb-4">
                <ResponsiveAccordion title="SITE CONDITIONS">
                  <SiteConditions
                    parentComponent={this}
                    parentState={this.state}
                  />
                </ResponsiveAccordion>
              </Col>

              <Col sm={12} className="mb-4">
                <ResponsiveAccordion title="FLOWERS & FRUIT">
                  <FlowersAndFruit
                    parentComponent={this}
                    parentState={this.state}
                  />
                </ResponsiveAccordion>
              </Col>

              <Col sm={12} className="mb-4">
                <ResponsiveAccordion title="SEARCH TERM">
                  <SearchTerm parentComponent={this} parentState={this.state} />
                </ResponsiveAccordion>
              </Col>

              <Col sm={12} className="text-center mb-4">
                <Button
                  variant="secondary"
                  size="lg"
                  type="button"
                  onClick={() => this.clearForm()}
                >
                  Clear All Filters
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button variant="primary" type="submit" size="lg">
                  SEARCH
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>

        <Container></Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchResults: state.searchResults.data,
    region: state.region,
    searchCharacteristics: state.searchCharacteristics.data,
    searchOptions: state.searchOptions,
  };
};
export default connect(mapStateToProps, {
  getSearchChar,
  setSearchOptions: options => dispatch =>
    dispatch({ type: "SET_SEARCH_OPTIONS", payload: options }),
  getUserListsThunk,
  getSearchCharThunk,
})(withRouter(SearchByChar));
