export const treeInfoExists = (...infoList) => {
  try {
    let infoExists = false;

    infoList.forEach(info => {
      if (info) {
        infoExists = true;
      }
    });

    return infoExists;
  } catch (err) {
    console.log(`utilities.treeInfoCard.treeInfoExists: ${err}`);
    return false;
  }
};
