import React from "react";
import { connect } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import FormFieldPlainText from "../FormField/FormFieldPlainText";
import FormFieldMultiSelectText from "../FormField/FormFieldMultiSelectText";
import "./SearchByCharCard.css";

import FormFieldCheckbox from "../FormField/FormFieldCheckbox";
import FormFieldText from "../FormField/FormFieldText";
import FormFieldMultiText from "../FormField/FormFieldMultiText";
import { optionsReturn } from "../../utilities/searchChar";
import { optionsReturnForForm } from "../../utilities/searchChar";
import { toast } from "react-toastify";
class SiteConditions extends React.Component {
  constructor(props) {
    super();
    this.state = {
      plantingArea: [],
    };
  }
  optionsFor = field => {
    try {
      if (
        this.props.searchCharacteristics &&
        this.props.searchCharacteristics[field]
      ) {
        return optionsReturnForForm(this.props.searchCharacteristics[field]);
      } else {
        return [];
      }
    } catch (err) {
      console.log(`components.TreeFormCard.SiteConditions.optionsFor: ${err}`);
    }
  };
  componentDidUpdate = prevProps => {
    try {
      if (this.props.searchCharacteristics) {
        // Being defensive on props
        if (prevProps !== this.props) {
          const { plantingArea } = this.props.searchCharacteristics;
          this.setState({
            plantingArea: plantingArea,
          });
        }
      }
    } catch (err) {
      console.log(`components.SearchByCharCard.TreeChar: ${err}`);
    }
  };

  optionsPlantingArea = () => {
    try {
      // Will this be a constant?
      return optionsReturn(this.state.plantingArea);
    } catch (err) {
      console.log(
        `components.SearchByCharCard.TreeChar.optionsPlantingArea: ${err}`
      );
    }
  };
  validateZipCode = zipCode => {
    if (zipCode.length === 5 && zipCode[0] === "9") {
      return true;
    }
    toast.error("Error please enter a valid Californian zip code!");
    return false;
  };
  render() {
    let parentComponent = this.props.parentComponent;
    return (
      <Container className="search-by-char-card--site-conditions">
        <Row>
          <Col lg={6}>
            <h5>
              <u>Find Trees For Your Area</u>
            </h5>
            {this.props.parentState["climate_adapted"] == "1" ? (
              <FormFieldMultiSelectText
                title="Zip Code"
                field="adapted_zip_code"
                options={[]}
                parentComponent={parentComponent}
                lockDependentVariables={[
                  "usda_zones",
                  "sunset_climate_zone",
                  "climate_region",
                ]}
                placeholder="Type..."
                inputValidationFunc={this.validateZipCode}
                required={true}
                description="Climate adapted trees are specific to location, please enter your zip code above."
              />
            ) : (
              <React.Fragment>
                <FormFieldMultiSelectText
                  title="Zip Code"
                  field="zip_code"
                  options={[]}
                  reset="usda_zones"
                  parentComponent={parentComponent}
                  lockDependentVariables={[
                    "usda_zones",
                    "sunset_climate_zone",
                    "climate_region",
                  ]}
                  placeholder="Type..."
                  inputValidationFunc={this.validateZipCode}
                />

                <FormFieldMultiText
                  title="USDA Hardiness Zone"
                  field="usda_zones"
                  options={[
                    { label: "Zone 2", value: "2" },
                    { label: "Zone 3", value: "3" },
                    { label: "Zone 4", value: "4" },
                    { label: "Zone 5", value: "5" },
                    { label: "Zone 6", value: "6" },
                    { label: "Zone 7", value: "7" },
                    { label: "Zone 8", value: "8" },
                    { label: "Zone 9", value: "9" },
                    { label: "Zone 10", value: "10" },
                  ]}
                  reset="sunset_climate_zone"
                  parentComponent={parentComponent}
                  lockDependentVariables={[
                    "zip_code",
                    "sunset_climate_zone",
                    "climate_region",
                    "adapted_zip_code",
                  ]}
                />

                <FormFieldMultiText
                  title="Sunset Climate Zone"
                  field="sunset_climate_zone"
                  reset="usda_zones"
                  options={[
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                    { label: "8", value: "8" },
                    { label: "9", value: "9" },
                    { label: "10", value: "10" },
                    { label: "11", value: "11" },
                    { label: "12", value: "12" },
                    { label: "13", value: "13" },
                    { label: "14", value: "14" },
                    { label: "15", value: "15" },
                    { label: "16", value: "16" },
                    { label: "17", value: "17" },
                    { label: "18", value: "18" },
                    { label: "19", value: "19" },
                    { label: "20", value: "20" },
                    { label: "21", value: "21" },
                    { label: "22", value: "22" },
                    { label: "23", value: "23" },
                    { label: "24", value: "24" },
                    { label: "H1", value: "H1" },
                    { label: "H2", value: "H2" },
                  ]}
                  parentComponent={parentComponent}
                  lockDependentVariables={[
                    "zip_code",
                    "usda_zones",
                    "climate_region",
                    "adapted_zip_code",
                  ]}
                />
                <FormFieldMultiText
                  title="Climate Region"
                  field="climate_region"
                  options={this.optionsFor("climateZones")}
                  reset="sunset_climate_zone"
                  parentComponent={parentComponent}
                  lockDependentVariables={[
                    "zip_code",
                    "sunset_climate_zone",
                    "usda_zones",
                    "adapted_zip_code",
                  ]}
                />
              </React.Fragment>
            )}
          </Col>

          <Col lg={6}>
            <Row>
              <Col lg={6}>
                <FormFieldCheckbox
                  title="Deer Resistant"
                  field="deer_tolerance"
                  options={[{ label: "Yes", value: 1 }]}
                  parentComponent={parentComponent}
                />
              </Col>
              <Col lg={6}>
                <FormFieldCheckbox
                  title="Salt Tolerance"
                  field="salt_tolerance"
                  options={[
                    { label: "Low", value: "Low" },
                    { label: "Moderate", value: "Moderate" },
                    { label: "High", value: "High" },
                  ]}
                  parentComponent={parentComponent}
                />
              </Col>
            </Row>

            <FormFieldCheckbox
              title="SelecTree Water Use Rating"
              field="water_use_rating"
              options={[
                { label: "Very Low", value: "VL" },
                { label: "Low", value: "L" },
                { label: "Medium", value: "M" },
                { label: "High", value: "H" },
              ]}
              parentComponent={parentComponent}
            />

            <FormFieldCheckbox
              title="Shade Tolerant"
              field="shade_tolerant"
              options={[{ label: "Yes", value: 1 }]}
              parentComponent={parentComponent}
            />

            <FormFieldText
              title="Recommended Planting Area"
              field="planting_area"
              options={this.optionsPlantingArea()}
              parentComponent={parentComponent}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { searchCharacteristics: state.searchCharacteristics.data };
};
export default connect(mapStateToProps, {})(SiteConditions);
