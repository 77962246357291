import React, { memo, useState } from "react";
import { Col, Container, Pagination, Spinner } from "react-bootstrap";
import "./ResultGallery.css";
import uuid from "react-uuid";
import { useDispatch, useSelector } from "react-redux";
import { getSearchResultsThunk } from "../../actions/search";
// components
import ResultCard from "../ResultCard/ResultCard";
import ResultPagination from "../ResultPagination/ResultPagination";
import { objectComparator } from "../../utilities/reduxUtil";
import { PAGES_TO_DISPLAY, PAGE_TOP_DESKTOP } from "../../utilities/constants";
import { Link } from "react-router-dom";

const ERROR_PREFIX = "components.ResultGallery.";

function ResultGallery() {
  const searchResults = useSelector(
    state => state.searchResults,
    objectComparator
  );
  const searchOptions = useSelector(
    state => state.searchOptions,
    objectComparator
  );

  const pageResults = searchResults.pageResults;
  const totalResults = searchResults.totalResults;

  const activePage = searchOptions.activePage;
  const resultsPerPage = searchOptions.resultsPerPage;

  const dispatch = useDispatch();

  if (totalResults === 1) {
    window.location.href = `/tree-detail/${pageResults[0].tree_id}`;
  }

  async function setActivePage(new_page) {
    try {
      const getSearchResults = getSearchResultsThunk({
        ...searchOptions,
        activePage: new_page,
        scroll_y: window.location.pageYOffset,
      });
      dispatch(getSearchResults);
    } catch (err) {
      console.log(`${ERROR_PREFIX}.setActivePage: ${err}`);
    }
  }

  const renderResultGallery = () => {
    try {
      return pageResults.map(card => {
        return (
          <Col
            lg={4}
            md={6}
            xs={12}
            key={uuid()}
            className="result-gallery-card-container pt-4 pb-0 pl-4 pr-4"
          >
            <ResultCard
              scientificName={
                card.accepted_scientific
                  ? card.accepted_scientific
                  : card.name_concat
              }
              commonName={
                card.accepted_common ? card.accepted_common : card.common
              }
              imgLink={card.link}
              treeId={card.tree_id}
              lowZone={card.low_zone}
              height={card.height_high}
              key={uuid()}
            />
          </Col>
        );
      });
    } catch (err) {
      console.log(`components.ResultGallery.renderResultGallery: ${err}`);
      return null;
    }
  };

  const renderNoResults = () => {
    return (
      <div className="result-gallery-no-results">
        <h2>No Results Found</h2>
        <p>
          The search may be too restrictive. Try searching again with fewer
          options (below)- that will widen the search results.
        </p>
        <h6>
          <Link to="/" onClick={() => window.scrollTo(PAGE_TOP_DESKTOP)}>
            Return to the SelecTree Home
          </Link>
        </h6>
      </div>
    );
  };

  if (pageResults)
    return (
      <Container className="result-gallery mb-5">
        {pageResults && Array.isArray(pageResults) && pageResults.length > 0 ? (
          <ResultPagination
            defaultPage={activePage}
            visiblePages={PAGES_TO_DISPLAY}
            numberOfResults={totalResults}
            resultsPerPage={resultsPerPage}
            onPageChange={page => {
              window.scrollTo(PAGE_TOP_DESKTOP);
              setActivePage(page);
            }}
          >
            {renderResultGallery()}
            <br />
            <br />
          </ResultPagination>
        ) : (
          renderNoResults()
        )}
      </Container>
    );

  return (
    <Container>
      <p>Loading...</p>
    </Container>
  );
}
//Do you need to use memo here or is it artifact
export default memo(ResultGallery);
