import React from "react";
import { Container } from "react-bootstrap";
import "./SearchByNameSection.css";
// subcomponents
import SearchByName from "../SearchByName/SearchByName";

export default function SelectreeBanner() {
  return (
    <Container fluid className="search-by-name-section p-5 text-center">
      <Container>
        <h1 className="search-by-name-section-h1 mt-5">
          <span className="selectree-banner-title">Search Trees By Name</span>
        </h1>
        <h3 className="search-by-name-section-h3">
          Trees can be searched by their common or scientific name.
        </h3>
        <hr className="hr-bold-short-black" />
        <div className="mb-5">
          <SearchByName />
        </div>
      </Container>
    </Container>
  );
}
