import React from "react";
import { connect } from "react-redux";
import { Container, Col } from "react-bootstrap";
import uuid from "react-uuid";
import "../TreeInfoCard/TreeInfoCard.css";
import ResponsiveAccordion from "../ResponsiveAccordion/ResponsiveAccordion";
import { treeInfoExists } from "../../utilities/treeInfoCard";
import { Cone_Trees } from "../../utilities/constants";
class TreeCharacteristics extends React.Component {
  renderShape = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.treeCharacteristics &&
        Array.isArray(this.props.treeDetail.treeCharacteristics.treeShape) &&
        this.props.treeDetail.treeCharacteristics.treeShape.length > 0
      ) {
        return (
          <span className="tree-info-card-group">
            Tree shape:{" "}
            {this.props.treeDetail.treeCharacteristics.treeShape.map(
              (shape, idx) => {
                return (
                  <span key={uuid()}>
                    {shape}
                    {idx <
                    this.props.treeDetail.treeCharacteristics.treeShape.length -
                      1
                      ? " or "
                      : null}
                  </span>
                );
              }
            )}
          </span>
        );
      } else {
        throw new Error("tree_shape empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.TreeCharacteristics.renderShape: ${err}`
      );
      return null;
    }
  };

  renderFoliageType = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.treeCharacteristics &&
        this.props.treeDetail.treeCharacteristics.foliage_type
      ) {
        const foliage_types = ["Deciduous", "Partly Deciduous", "Evergreen"];
        // error checking for foliage_type
        if (
          !foliage_types.includes(
            this.props.treeDetail.treeCharacteristics.foliage_type
          )
        ) {
          return null;
        }

        return (
          <span className="tree-info-card-text">
            Foliage type:{" "}
            {this.props.treeDetail.treeCharacteristics.foliage_type}
          </span>
        );
      } else {
        throw new Error("foliageType empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.TreeCharacteristics.renderFoliageType: ${err}`
      );
      return null;
    }
  };

  renderHeight = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.treeCharacteristics &&
        this.props.treeDetail.treeCharacteristics.height &&
        this.props.treeDetail.treeCharacteristics.height.height_high
      ) {
        // Currently only showing max height, height_low not currently utilized
        const { height_high } =
          this.props.treeDetail.treeCharacteristics.height;
        return (
          <span className="tree-info-card-group">
            Maximum tree height: {height_high} feet
          </span>
        );
      } else {
        throw new Error("height empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.TreeCharacteristics.renderHeight: ${err}`
      );
      return null;
    }
  };

  renderWidth = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.treeCharacteristics &&
        this.props.treeDetail.treeCharacteristics.width &&
        (this.props.treeDetail.treeCharacteristics.width.width_low ||
          this.props.treeDetail.treeCharacteristics.width.width_high)
      ) {
        const { width_low, width_high } =
          this.props.treeDetail.treeCharacteristics.width;

        let widthString = "";

        if (width_low || width_high) {
          if (!width_low) {
            widthString = `${width_high}`;
          } else if (!width_high) {
            widthString = `${width_low}`;
          } else {
            if (width_low === width_high) {
              widthString = `${width_low}`;
            } else {
              widthString = `${width_low}-${width_high}`;
            }
          }
        }
        return (
          <span className="tree-info-card-group">
            Canopy width:
            {" " + widthString + " "}
            feet
          </span>
        );
      } else {
        throw new Error("width empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.TreeCharacteristics.renderWidth: ${err}`
      );
      return null;
    }
  };

  renderGrowthRate = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.treeCharacteristics &&
        this.props.treeDetail.treeCharacteristics.growthRate &&
        (this.props.treeDetail.treeCharacteristics.growthRate.growth_rate_low ||
          this.props.treeDetail.treeCharacteristics.growthRate.growth_rate_high)
      ) {
        const { growth_rate_low, growth_rate_high } =
          this.props.treeDetail.treeCharacteristics.growthRate;
        return (
          <span className="tree-info-card-text">
            Growth rate: ~
            {growth_rate_low === growth_rate_high
              ? `${growth_rate_low}`
              : `${growth_rate_low}-${growth_rate_high}`}{" "}
            in/year
          </span>
        );
      } else {
        throw new Error("growth_rate empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.TreeCharacteristics.renderGrowthRate: ${err}`
      );
      return null;
    }
  };

  renderLeafForms = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.treeCharacteristics &&
        this.props.treeDetail.treeCharacteristics.leafForms &&
        (this.props.treeDetail.treeCharacteristics.leafForms.leaf_arrangement ||
          this.props.treeDetail.treeCharacteristics.leafForms.leaf_form ||
          this.props.treeDetail.treeCharacteristics.leafForms.leaflet_shape ||
          this.props.treeDetail.treeCharacteristics.leafForms
            .foliage_fall_color ||
          (Array.isArray(
            this.props.treeDetail.treeCharacteristics.leafForms
              .foliage_growth_color
          ) &&
            this.props.treeDetail.treeCharacteristics.leafForms
              .foliage_growth_color.length > 0))
      ) {
        // foliageType: { foliage_type_low, foliage_type_high } not being used currently from props
        const {
          leaf_arrangement,
          leaf_form,
          leaflet_shape,
          foliage_fall_color,
          foliage_growth_color,
        } = this.props.treeDetail.treeCharacteristics.leafForms;

        // There is no list for fall colors currently
        // Verify what armament should display
        // Will need to manually add armament
        const lastClass = foliage_fall_color
          ? "tree-info-card-group"
          : "tree-info-card-text";
        return (
          <>
            {leaf_arrangement && leaf_form ? (
              <span className="tree-info-card-group">
                Leaf arrangement and form: {leaf_arrangement}, {leaf_form}
              </span>
            ) : (
              ""
            )}
            {leaflet_shape ? (
              <span className="tree-info-card-group">
                Leaf/leaflet shape: {leaflet_shape}
              </span>
            ) : (
              ""
            )}
            <span className={lastClass}>
              {foliage_growth_color.map((color, idx) => {
                return (
                  <span key={uuid()}>
                    {idx === 0 ? "Leaf color: " : null}
                    {color}
                    {idx < foliage_growth_color.length - 1 ? " or " : null}
                  </span>
                );
              })}
            </span>
            {foliage_fall_color ? (
              <span className="tree-info-card-text">Fall color: Yes</span>
            ) : null}
            {/* <span className="tree-info-card-group">Leaf arrangement and form:{foliage_fall_color ? ', Changes' : ', No Change'}, {foliage_type_low === foliage_type_high ? `${foliage_type_low}` : `${foliage_type_low} - ${foliage_type_high}`}.
                    </span> */}
          </>
        );
      } else {
        throw new Error("leafForms empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.TreeCharacteristics.renderLeafForms: ${err}`
      );
      return null;
    }
  };

  renderFlowerSpecs = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.treeCharacteristics &&
        this.props.treeDetail.treeCharacteristics.flowerSpecs &&
        (this.props.treeDetail.treeCharacteristics.flowerSpecs.sex ||
          this.props.treeDetail.treeCharacteristics.flowerSpecs
            .flower_showiness ||
          this.props.treeDetail.treeCharacteristics.flowerSpecs.flower_color ||
          this.props.treeDetail.treeCharacteristics.flowerSpecs.flower_time ||
          this.props.treeDetail.treeCharacteristics.flowerSpecs.fragrance)
      ) {
        const { sex, flower_showiness, flower_color, flower_time, fragrance } =
          this.props.treeDetail.treeCharacteristics.flowerSpecs;

        let renderRepStructure = "";
        let cone_flag = Cone_Trees.includes(this.props.treeDetail.familyName);

        if (cone_flag) {
          renderRepStructure = "Cones:";
        } else {
          renderRepStructure = "Flowers:";
        }

        if (flower_showiness && fragrance) {
          renderRepStructure = `${renderRepStructure} ${flower_showiness}, Fragrant`;
        } else if (flower_showiness) {
          renderRepStructure = `${renderRepStructure} ${flower_showiness}`;
        } else if (fragrance) {
          renderRepStructure = `${renderRepStructure} Fragrant`;
        } else {
          renderRepStructure = null;
        }

        return (
          <>
            {renderRepStructure ? (
              <span className="tree-info-card-group">{renderRepStructure}</span>
            ) : null}
            {flower_color.length ? (
              <span className="tree-info-card-group">
                Flower color:{" "}
                {flower_color.map((color, idx) => {
                  return (
                    <span key={uuid()}>
                      {color}
                      {idx < flower_color.length - 1 ? " or " : ""}
                    </span>
                  );
                })}
              </span>
            ) : null}
            {sex ? (
              <span
                className={`tree-info-card-${
                  flower_time.length ? "group" : "text"
                }`}
              >
                {cone_flag ? "Reproduction:" : "Flower type:"} {sex}
              </span>
            ) : null}
            {flower_time.length && !cone_flag ? (
              <span className="tree-info-card-text">
                Flowering Time:{" "}
                {flower_time.map((time, idx) => {
                  time =
                    idx === flower_time.length - 1 && flower_time.length > 1
                      ? ` or ${time}`
                      : time;
                  return (
                    <span key={uuid()}>
                      {time}
                      {idx < flower_time.length - 2 ? ", " : null}
                    </span>
                  );
                })}
              </span>
            ) : null}
          </>
        );
      } else {
        throw new Error("flowerSpecs empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.TreeCharacteristics.renderRepStructurepecs: ${err}`
      );
      return null;
    }
  };

  renderFruit = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.treeCharacteristics &&
        this.props.treeDetail.treeCharacteristics.fruits &&
        (this.props.treeDetail.treeCharacteristics.fruits.fruit_color ||
          this.props.treeDetail.treeCharacteristics.fruits.fruit_type ||
          this.props.treeDetail.treeCharacteristics.fruits.fruit_value ||
          this.props.treeDetail.treeCharacteristics.fruits.fruiting_time)
      ) {
        // fruit_size currently not being utilized
        const {
          fruit_color,
          fruit_type,
          fruit_value,
          fruiting_time,
          fruit_size,
        } = this.props.treeDetail.treeCharacteristics.fruits;
        const lastClass = fruit_value
          ? "tree-info-card-group"
          : "tree-info-card-text";
        // unsure if fruit_value and fruit_size will combine
        let renderRepStructure = "";
        let renderTimeChar = "";
        if (Cone_Trees.includes(this.props.treeDetail.familyName)) {
          renderRepStructure = "Cones:";
          renderTimeChar = "Pollen time:";
        } else {
          renderRepStructure = "Fruit:";
          renderTimeChar = "Fruiting time:";
        }

        return (
          <>
            <span
              className={
                fruiting_time ? "tree-info-card-group" : "tree-info-card-text"
              }
            >
              {renderRepStructure} {fruit_size ? fruit_size + " " : ""}
              {fruit_color} {fruit_type}
            </span>
            {fruiting_time ? (
              <span className={lastClass}>
                {renderTimeChar}{" "}
                {fruiting_time &&
                  fruiting_time.map((fruit, idx) => {
                    fruit =
                      idx === fruiting_time.length - 1 &&
                      fruiting_time.length > 1
                        ? ` or ${fruit}`
                        : fruit;
                    return (
                      <span key={uuid()}>
                        {fruit}
                        {idx < fruiting_time.length - 2 ? ", " : null}
                      </span>
                    );
                  })}
              </span>
            ) : (
              ""
            )}
            {fruit_value ? (
              <span className="tree-info-card-text">
                Fruit value: {fruit_value.join(", ")}
              </span>
            ) : null}
          </>
        );
      } else {
        throw new Error("fruit empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.TreeCharacteristics.renderFruit: ${err}`
      );
      return null;
    }
  };

  renderBark = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.treeCharacteristics &&
        this.props.treeDetail.treeCharacteristics.bark
      ) {
        const { bark_color, bark_texture } =
          this.props.treeDetail.treeCharacteristics.bark;
        if (bark_color || bark_texture) {
          return (
            <span className="tree-info-card-text">
              Bark: {bark_color ? bark_color + ", " : null}
              {bark_texture.join(", ")}
            </span>
          );
        } else {
          throw new Error("bark empty");
        }
      }
    } catch (err) {
      console.log(`components.TreeInfoCard.TreeCharacteristics.bark: ${err}`);
      return null;
    }
  };

  renderLitterType = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.treeCharacteristics &&
        this.props.treeDetail.treeCharacteristics.litterType
      ) {
        return (
          <span className="tree-info-card-text">
            Litter:{" "}
            {this.props.treeDetail.treeCharacteristics.litterType.join(", ")}
          </span>
        );
      } else {
        throw new Error("litter_type empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.TreeCharacteristics.renderLitterType: ${err}`
      );
      return null;
    }
  };

  checkRenderInfo = () => {
    try {
      return treeInfoExists(
        this.renderShape(),
        this.renderFoliageType(),
        this.renderHeight(),
        this.renderWidth(),
        this.renderGrowthRate(),
        this.renderLeafForms(),
        this.renderFlowerSpecs(),
        this.renderFruit(),
        this.renderBark(),
        this.renderLitterType()
      );
    } catch (err) {
      console.log(
        `components.TreeInfoCard.TreeCharacteristics.checkRenderInfo: ${err}`
      );
      return false;
    }
  };

  renderContainer = () => {
    try {
      return (
        <Container className="tree-info-card--tree-characteristics">
          {this.renderShape()}
          {this.renderFoliageType()}
          {this.renderHeight()}
          {this.renderWidth()}
          {this.renderGrowthRate()}
          {this.renderLeafForms()}
          {this.renderFlowerSpecs()}
          {this.renderFruit()}
          {this.renderBark()}
          {this.renderLitterType()}
        </Container>
      );
    } catch (err) {
      console.log(
        `components.TreeInfoCard.TreeCharacteristics.renderContainer: ${err}`
      );
    }
  };

  render() {
    return this.checkRenderInfo() ? (
      <Col lg={6} xs={12} className="mb-4">
        <ResponsiveAccordion title="TREE CHARACTERISTICS">
          {this.renderContainer()}
        </ResponsiveAccordion>
      </Col>
    ) : null;
  }
}

const mapStateToProps = state => {
  return { treeDetail: state.treeDetail.data };
};

export default connect(mapStateToProps, {})(TreeCharacteristics);
