import { combineReducers } from "redux";
import searchByName from "./searchByName";
import getTreeDetail from "./getTreeDetail";
import getSearchResultsPageData from "./getSearchResultsPageData";
import getSearchCharacteristicsData from "./getSearchCharacteristicsData";
import setSearchOptions from "./setSearchOptions";
import listSearchOptions from "./listSearchOptions";
import list_detail from "./list_detail";
import user_lists from "./user_lists";
import user from "./user.js";
import display_lists from "./display_lists.js";
import { setRegion } from "./setFilters";
import { setSearchPageOptions } from "../utilities/sessionStorage";

const listSearchResults = (state = [], action) => {
  switch (action.type) {
    case "LIST_SEARCH_RESULTS":
      return action.payload;
    default:
      return state;
  }
};

const combinedReducer = combineReducers({
  suggestions: searchByName,
  treeDetail: getTreeDetail,
  searchResults: getSearchResultsPageData,
  region: setRegion,
  user,
  user_lists,
  list_detail,
  display_lists,
  searchCharacteristics: getSearchCharacteristicsData,
  searchOptions: setSearchOptions,
  listSearchOptions,
  listSearchResults,
});

function crossSliceReducer(state, action) {
  switch (action.type) {
    case "SET_SEARCH_RESULTS_AND_OPTIONS":
      setSearchPageOptions(action.payload.searchOptions);

      return {
        ...state,
        searchOptions: action.payload.searchOptions,
        searchResults: action.payload.searchResults,
      };

    case "SET_LIST_SEARCH_RESULTS_AND_OPTIONS":
      //setSearchPageOptions(action.payload.searchOptions)
      action.payload.activePage = parseInt(action.payload.activePage);
      return {
        ...state,
        listSearchOptions: action.payload.searchOptions,
        listSearchResults: action.payload.searchResults,
      };
    default:
      return state;
  }
}

export default function rootReducer(state, action) {
  const intermediateState = combinedReducer(state, action);
  const finalState = crossSliceReducer(intermediateState, action);
  return finalState;
}
