import React, { useEffect } from "react";
import { getAuthHeader } from "../../utilities/header";
import { useDispatch, useSelector } from "react-redux";
import api from "../../apis/selectree_api";
import { useHistory } from "react-router";
import { PAGE_TOP_DESKTOP } from "../../utilities/constants";

// Validates token and logs user out if token is bad
export default function UserChecker() {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  function logout() {
    dispatch({ type: "LOGOUT" });
    window.scrollTo(PAGE_TOP_DESKTOP);
    history.push("/");
  }

  function user_storage_handler(e) {
    if (e.key === "token" && user && user.token !== e.newValue) {
      console.log(e.newValue);
      logout();
    } else if (e.key === "user" && !user && e.newValue) {
      dispatch({ type: "LOGIN", payload: JSON.parse(e.newValue) });
    }
  }

  useEffect(() => {
    const checkUser = async () => {
      if (!user || !user.token) return;

      try {
        let res = await api.get(`/users/check-user`, getAuthHeader());
        if (res.data.message == "Failed") logout();
      } catch (err) {
        console.log(`components.UserChecker: ${err}`);
        logout();
      }
    };
    window.addEventListener("storage", user_storage_handler);

    checkUser();
    return () => {
      window.removeEventListener("storage", user_storage_handler);
    };
  }, [user]);

  return "";
}
