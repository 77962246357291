import React, { useState } from "react";
import "./TreeDetailListWidget.css";
import { Button, Form } from "react-bootstrap";
import TextInput from "../FormField/TextInput.js";
import MenuInput from "../FormField/MenuInput.js";
import CreatableSelect from "react-select/creatable";
import { categories, regions, keywords } from "./ListWidgetOptions.js";
import selectStyle from "../../customUI/react-select-options";
import {
  TREE_LIST,
  SAVED_SEARCH,
  INVENTORY,
  INVENTORY_CATEGORY_LABEL,
} from "../../utilities/constants";
import ListDescription from "../ListForm/ListDescription";
import qs from "query-string";
import RegionSelector from "../Selectors/region";
import ListTypeToggles from "../ListForm/ListTypeToggles";

function ListForm(props) {
  const [error, setError] = useState("");
  const initialFormState = {
    description: "",
    name: "",
    type: TREE_LIST,
    categories: [],
    regions: [],
    keywords: [],
    isPublic: false,
    viewable_from_link: false,
    tree_ids: props.treeId ? [props.treeId] : [],
  };
  //console.log(props.defaultValue)
  const [state, setState] = useState(
    props.defaultValue ? props.defaultValue : initialFormState
  );
  //const [state, setState] = useState(initialFormState);

  function handleForm(name, v) {
    setState({ ...state, [name]: v });
  }

  async function submitForm(e) {
    e.preventDefault();
    if (!props.formAction) {
      setError("No Form Action!");
      return;
    }

    try {
      let formattedData = {
        ...state,
        categories: state.categories.map(c => c.value),
        keywords: state.keywords.map(k => k.value),
        regions: state.regions.map(r => r.value),
      };

      // inventory type checking
      formattedData.type = formattedData.categories.includes(
        INVENTORY_CATEGORY_LABEL
      )
        ? INVENTORY
        : state.type;

      if (formattedData.type === SAVED_SEARCH) {
        formattedData.query_data = "searchTerm=";
      }

      let params = qs.parse(window.location.search);
      let searchOptions = qs.parse(window.location.hash);

      if (!props.treeId) {
        if (params.filterData) {
          formattedData.query_data = params.filterData;
        } else if (searchOptions.searchTerm) {
          formattedData.query_data = `searchTerm=${searchOptions.searchTerm}`;
        }
      }

      await props.formAction(formattedData);
    } catch (err) {
      if (err?.response?.data?.message) {
        setError(err.response.data.message);
      } else {
        setError("Failed to send to server");
        console.log(
          `Error in client.components.ListCreationForm.submitForm: ${err}`
        );
      }
    }
  }

  return (
    <div>
      <Form onSubmit={submitForm} autoComplete="off">
        <ListTypeToggles
          id={"list-creation-form"}
          publicProps={{
            checked: state.isPublic,
            inline: true,
            onChange: e => handleForm("isPublic", !state.isPublic),
          }}
          viewableFromLinkProps={{
            checked: state.viewable_from_link,
            inline: true,
            onChange: e =>
              handleForm("viewable_from_link", !state.viewable_from_link),
          }}
          savedSearchProps={
            props.savedSearch
              ? {
                  inline: true,
                  checked: state.type === SAVED_SEARCH,
                  onChange: e => {
                    setState({
                      ...state,
                      categories: state.categories.filter(
                        c => c.label !== INVENTORY_CATEGORY_LABEL
                      ),
                      type: e.target.checked ? SAVED_SEARCH : TREE_LIST,
                    });
                  },
                }
              : false
          }
        />
        <TextInput
          required
          label={"Name"}
          name="name"
          value={state.name}
          onChange={(name, v) => handleForm("name", v)}
        />

        <ListDescription
          value={state.description}
          required
          onChange={(name, v) => handleForm("description", v)}
        />

        <MenuInput
          label="What kind of list is this?"
          required
          isMulti
          placeholder="Select list type(s)..."
          value={state.categories}
          onChange={e => handleForm("categories", e)}
          options={
            state.type === SAVED_SEARCH
              ? categories.filter(c => c.label !== INVENTORY_CATEGORY_LABEL)
              : categories
          }
        />

        <RegionSelector
          label="What region does your list apply to?"
          placeholder="Select region..."
          required
          value={state.regions}
          isMulti
          onChange={e => handleForm("regions", e)}
        />

        <div className="keyword-section">
          <h6> KEYWORD TAGS</h6>
          <CreatableSelect
            isMulti
            onChange={e => handleForm("keywords", e)}
            placeholder="Enter helpful keywords..."
            value={state.keywords}
            styles={selectStyle}
          />
        </div>

        <div className="widget-actions">
          {error ? <div>{error}</div> : ""}
          {props.nav}
          <Button type="submit">
            {props.actionLabel ? props.actionLabel : "Submit"}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default ListForm;
