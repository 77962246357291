import {
  NUMBER_OF_RESULTS,
  DEFAULT_FILTER_OPTIONS,
  NON_ARRAY_KEYS,
  INT_DEFAULT_KEYS,
  STRING_DEFAULT_KEYS,
  DEFAULT_SEARCH_OPTIONS,
} from "./constants";

// Session Storage
export const getSearchOptions = () => {
  try {
    if (sessionStorage.ufeiSearchOptions) {
      return JSON.parse(sessionStorage.ufeiSearchOptions);
    } else {
      return null;
    }
  } catch (err) {
    console.log(`utilities.sessionStorage.getSearchOptions: ${err}`);
  }
};

export const setSearchPageOptions = (searchOptions = null) => {
  // set session storage options as JSON
  try {
    if (!sessionStorage.ufeiSearchOptions) {
      if (!searchOptions) {
        searchOptions = DEFAULT_SEARCH_OPTIONS;
      }
      sessionStorage.ufeiSearchOptions = JSON.stringify(searchOptions);
    } else {
      if (searchOptions) {
        sessionStorage.ufeiSearchOptions = JSON.stringify(searchOptions);
      }
    }
  } catch (err) {
    console.log(`utilities.sessionStorage.setSearchPageOptions: ${err}`);
  }
};

export const setListSearchOptions = (searchOptions = null) => {
  // set session storage options as JSON
  try {
    if (!sessionStorage.ufeiListSearchOptions) {
      if (!searchOptions) {
        searchOptions = {
          activePage: 1,
          resultsPerPage: NUMBER_OF_RESULTS[0],
          scroll_y: window.pageYOffset,
          listSearchTerm: "",
          sort: 1,
        };
      }
      sessionStorage.ufeiListSearchOptions = JSON.stringify(searchOptions);
    } else {
      if (searchOptions) {
        sessionStorage.ufeiListSearchOptions = JSON.stringify(searchOptions);
      }
    }
  } catch (err) {
    console.log(`utilities.sessionStorage.setSearchPageOptions: ${err}`);
  }
};

// Filter Session
export const getFilterOptions = () => {
  try {
    if (sessionStorage.ufeiFilterOptions) {
      // ensure type consistency
      let filters = JSON.parse(sessionStorage.ufeiFilterOptions);
      for (let i = 0; i < INT_DEFAULT_KEYS.length; i++) {
        const key = INT_DEFAULT_KEYS[i];
        filters[key] = parseInt(filters[key]);
      }
      return filters;
    }
  } catch (err) {
    console.log(`utilities.sessionStorage.getFilterOptions: ${err}`);
  }
};

export const setFilterOptions = (filterOptions = null) => {
  // set session storage options as JSON
  try {
    if (!sessionStorage.ufeiFilterOptions) {
      if (!filterOptions) {
        filterOptions = DEFAULT_FILTER_OPTIONS();
      }
      sessionStorage.ufeiFilterOptions = JSON.stringify(filterOptions);
    } else {
      if (filterOptions) {
        sessionStorage.ufeiFilterOptions = JSON.stringify(filterOptions);
      }
    }
  } catch (err) {
    console.log(`utilities.sessionStorage.setFilterOptions: ${err}`);
  }
};

export const resetFilterOptions = () => {
  let resetOptions = DEFAULT_FILTER_OPTIONS();
  delete resetOptions.region;
  sessionStorage.ufeiFilterOptions = JSON.stringify(resetOptions);
};

// May not need this
export const translateFilterOptions = filterOptions => {
  try {
    let url = Object.entries(filterOptions)
      .filter(
        pair =>
          (!Array.isArray(pair[1]) ||
            (Array.isArray(pair[1]) && pair[1].length > 0)) &&
          pair[1] !== ""
      )
      .map(
        pair =>
          pair[0] + "=" + (Array.isArray(pair[1]) ? pair[1].join(",") : pair[1])
      )
      .join("?");
    return url;
  } catch (err) {
    console.log(`utilities.sessionStorage.translateFilterOptions: ${err}`);
  }
};

export const transformQueryDataToJSON = url => {
  let filters = DEFAULT_FILTER_OPTIONS();

  const selectedOptions = url !== "" && url ? url.split("?") : [];
  selectedOptions.forEach(option => {
    const pair = option.split("=");
    const key = pair[0];
    // handle keys with non-array default values
    if (STRING_DEFAULT_KEYS.includes(key) || INT_DEFAULT_KEYS.includes(key)) {
      filters[key] = pair[1];
    } else {
      const values = pair[1].split(","); // put values into array
      if (filters.hasOwnProperty(key)) {
        filters[key] = values;
      }
    }
  });

  return filters;
};

export const transformFilterObjectToQueryData = filterObject => {
  const queryParams = [];

  for (const key in filterObject) {
    const value = filterObject[key];
    const isValidSingleValueParam =
      (STRING_DEFAULT_KEYS.includes(key) || INT_DEFAULT_KEYS.includes(key)) &&
      value !== "";
    const isValidMultiValueParam = Array.isArray(value) && value.length > 0;

    // handle keys with non-array default values
    if (isValidSingleValueParam) {
      queryParams.push(`${key}=${value}`);
    } else if (isValidMultiValueParam) {
      queryParams.push(`${key}=${value.join(",")}`);
    }
  }

  return queryParams.length > 0 ? queryParams.join("?") : "";
};
