import React from "react";
import { Container } from "react-bootstrap";
import "./SelectreeBanner.css";
// subcomponents
import SearchByName from "../SearchByName/SearchByName";
import { PAGE_TOP_DESKTOP } from "../../utilities/constants";
import { Link } from "react-router-dom";

class SelectreeBanner extends React.Component {
  render() {
    return (
      <Container className="selectree-banner-container" fluid>
        <Container>
          <h1 className="selectree-banner-container-h1">
            <Link
              style={{ textDecoration: "none" }}
              to={"/"}
              onClick={() => window.scrollTo(PAGE_TOP_DESKTOP)}
            >
              <span className="selectree-banner-title">SelecTree</span>
            </Link>
          </h1>
          <h3 className="selectree-banner-container-h3">
            A Tree Selection Guide
          </h3>

          <div className="d-none d-lg-block">
            <SearchByName />
          </div>
        </Container>
      </Container>
    );
  }
}

export default SelectreeBanner;
