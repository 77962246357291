import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import "./Result.css";
import qs from "query-string";
// actions
import { getSearchResultsThunk } from "../../actions/search";
// components
import ResultOps from "../ResultOps/ResultOps";
import ResultGallery from "../ResultGallery/ResultGallery";
import SearchByNameSection from "../SearchByNameSection/SearchByNameSection";
import SearchByChar from "../SearchByChar/SearchByChar";
import { getSearchChar } from "../../actions/searchChar";
import { getUserListsThunk } from "../../actions/list";

function Result() {
  const dispatch = useDispatch();
  let { sort, resultsPerPage, activePage, scroll_y, savedSearch } = useSelector(
    state => state.searchOptions
  );

  useEffect(() => {
    let urlOptions = qs.parse(window.location.hash);

    urlOptions.sort = parseInt(urlOptions.sort);
    urlOptions.activePage = parseInt(urlOptions.activePage);
    urlOptions.resultsPerPage = parseInt(urlOptions.resultsPerPage);
    urlOptions.searchTerm = urlOptions.searchTerm;

    urlOptions.sort = urlOptions.sort ? urlOptions.sort : sort;
    urlOptions.activePage = urlOptions.activePage
      ? urlOptions.activePage
      : activePage;
    urlOptions.resultsPerPage = urlOptions.resultsPerPage
      ? urlOptions.resultsPerPage
      : resultsPerPage;

    urlOptions.savedSearch = savedSearch;

    urlOptions.scroll_y = scroll_y;

    dispatch(getUserListsThunk());
    dispatch(getSearchResultsThunk(urlOptions));
    dispatch(getSearchChar());
  }, []);

  return (
    <Container fluid className="result">
      <ResultOps />
      <ResultGallery />
      <Container>
        <hr />
      </Container>
      <SearchByChar />
      <div className="d-none d-lg-block">
        <Container>
          <hr />
        </Container>
        <SearchByNameSection />
      </div>
    </Container>
  );
}

export default Result;
