import React from "react";
import { Form } from "react-bootstrap";
import { ImCheckboxChecked } from "react-icons/im";
import "./FormField.css";

//This component is very similar to the FormFieldCheckbox, but only allows one option.

class FormFieldSingleCheckbox extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  //Shorthand to access the values on the parent component's state.
  selectedValue = () => {
    let ret = this.props.parentComponent.state[this.props.field];

    if (ret === undefined) {
      console.log(
        `Components.FormField.FormFieldSingleCheckbox- Cannot find field ${this.props.field} on the parent component`
      );
      ret = null;
    }

    return ret;
  };
  //get new value from the element itself and set state on parent component
  change = e => {
    try {
      //Grab the new value from the element itself
      let newValue = e.target.getAttribute("data-value");
      if (this.props.type === "number") {
        newValue = parseFloat(newValue);
      }
      let field = this.props.field;

      this.props.parentComponent.setState({ [field]: newValue, changed: true });
    } catch (err) {
      console.log(
        `Components.FormField.FormFieldSingleCheckbox.change()-  ${err}`
      );
      return null;
    }
  };

  clearOption = () => {
    this.props.parentComponent.setState({
      [this.props.field]: null,
      changed: true,
    });
  };

  renderOptions = () => {
    try {
      return this.props.options.map(option => this.renderOption(option));
    } catch (err) {
      console.log(
        `Components.FormField.FormFieldSingleCheckbox.renderOptions()-  ${err}`
      );
      return null;
    }
  };

  //Renders the HTML for individual checkbox item.
  renderOption = option => {
    return (
      <li key={this.props.field + option.value + option.label}>
        <div className="form-check form-check-inline">
          <div style={{ display: "flex", position: "relative" }}>
            <input
              className="form-check-input"
              id={`${this.props.field}${option.value}`}
              type="checkbox"
              checked={this.selectedValue() === option.value}
              onChange={this.change}
              data-value={option.value}
            />
            <span className="overlay">
              <ImCheckboxChecked className="icon" />
            </span>
          </div>
          <label
            className="form-check-label"
            htmlFor={`${this.props.field}${option.value}`}
          >
            {option.label}
          </label>
        </div>
      </li>
    );
  };

  render() {
    let hideClearButton =
      this.selectedValue() === null || this.selectedValue() === undefined;
    if (this.props.required) {
      hideClearButton = true;
    }

    return (
      <Form.Group className="form-field-checkbox">
        <Form.Label className="d-block">
          {this.props.title}
          {hideClearButton ? (
            ""
          ) : (
            <span
              onClick={this.clearOption}
              className="form-field-clear-field-button"
            >
              X
            </span>
          )}
        </Form.Label>

        <ul>{this.renderOptions()}</ul>
      </Form.Group>
    );
  }
}

export default FormFieldSingleCheckbox;
